<template>
  <div class="mm-main-page">
    <div class="mm-default-wrapper">
      <Header
        :links="headerLinks"
        is-logo-with-description
        @click-go-to-basket="Basket.goToBasketPage()"
      />
      <slot />
      <Footer />
      <GTM v-if="!metaProps.isGTMDisabled" />
    </div>

    <!--  Старый менеджер модальных окон - выпилить после перехода на новый TODO    -->
    <ModalsContainer />
    <!--  Новый менеджер модальных окон  -->
    <MModalContainer />

    <CompareProductsDrawer />
  </div>
</template>

<script lang="ts" setup>
import GTM from 'shared/components/GTM.vue';
import Header from '~/components/Header/Header.vue';
import Footer from 'components/layouts/default/Footer.vue';
import { useUserStore } from '~/store/user.store';
import { useMainTitle } from 'composables/useMainTitle';
import { useServerSsoAuth } from 'composables/useAuthHelper';
import { useCheckInternalUser } from 'shared/composables/useCheckInternalUser';
import setAuthCookieHeaders from '../composables/setAuthCookieHeaders';
import { useCompareProducts } from '../composables/useCompareProducts';
import { useRefreshBasketData } from 'composables/useRefreshBasketData';
import CompareProductsDrawer from 'components/compare-products/CompareProductsDrawer.vue';
import { mainHeaderLinks, unauthorizedMainHeaderLinks } from 'constants/layouts/header.const';
import { Basket } from 'services/basket-services/basket/basket.service';

const router = useRouter();

const metaProps: {
  isErrorPage?: boolean;
  isGTMDisabled?: boolean;
} = router.currentRoute.value.meta.props || {};

const userStore = useUserStore();

const headerLinks = computed(() => (userStore.isUserLoggedIn ? mainHeaderLinks : unauthorizedMainHeaderLinks));

setAuthCookieHeaders();
await useDomainAuth();
await useServerSsoAuth();
useMainTitle();
await useSetServerUserInfo();
useSetClientUserInfo();
useRefreshBasketData();
useCheckInternalUser();
useCompareProducts();
</script>

<style lang="scss" scoped>
.mm-main-page {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  &.overflow {
    overflow: hidden;
  }
}

@media screen and (max-width: 1279px) {
  .mm-default-wrapper {
    padding-bottom: 56px;
  }
}
</style>

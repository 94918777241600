<template>
  <span ref="refCounter">{{ resultShow }}</span>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const props = withDefaults(
  defineProps<{
    end: number;
    start?: number;
    duration?: number;
    thousand?: string;
    decimal?: string;
    step?: number;
  }>(),
  {
    start: 0,
    duration: 2000,
    thousand: ' ',
    decimal: ',',
    step: 1,
  },
);

const result = ref(0);
const startData = ref(0);
const endData = ref(0);

watchEffect(() => {
  startData.value = props.start;
  endData.value = props.end;
});

const resultShow = computed(() => {
  return result.value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, props.thousand);
});

function startCounting() {
  if (startData.value === endData.value) {
    result.value = startData.value;
  } else {
    const frameDuration = 1000 / 60;
    const totalFrames = Math.round(props.duration / frameDuration);
    const easeOutQuad = (t) => t * (2 - t);
    let increasing = true;
    if (startData.value > endData.value) {
      endData.value = 0;
    } else {
      increasing = false;
      startData.value = 0;
    }
    let frame = 0;

    let countTo = endData.value - startData.value;
    if (countTo < 0) {
      countTo *= -1;
    }

    const counter = setInterval(() => {
      frame++;
      const progress = easeOutQuad(frame / totalFrames);
      const currentCount = Math.round(countTo * progress);

      if (result.value !== currentCount) {
        if (increasing) {
          if (startData.value - currentCount < props.end) {
            result.value = props.end;
          } else {
            result.value = startData.value - currentCount;
          }
        } else {
          if (startData.value + currentCount > props.end) {
            result.value = props.end;
          } else if (startData.value + currentCount + props.step > result.value) {
            result.value = startData.value + currentCount;
          }
        }
      }

      if (frame === totalFrames) {
        clearInterval(counter);
      }
    }, frameDuration);
  }
}

const refCounter = ref();

function isElementInViewport(el) {
  var rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

const isInit = ref(false);

function onScrollCheckCounter() {
  if (isElementInViewport(refCounter.value) && !isInit.value) {
    startCounting();
    isInit.value = true;

    window.removeEventListener('scroll', onScrollCheckCounter);
  }
}

onMounted(() => {
  onScrollCheckCounter();

  window.addEventListener('scroll', onScrollCheckCounter);
});

onUnmounted(() => {
  window.removeEventListener('scroll', onScrollCheckCounter);
});
</script>

<template>
  <header
    ref="refHeader"
    class="Header"
    :class="{
      'Header--isLogoLeft': props.isLogoLeft || metaProps.headerLogoLeft,
      'Header--isNavHighTheme': props.isNavHighTheme,
      'Header--transparent': metaProps.headerTransparent,
      'Header--inverse-mode': headerStore.isInverseMode,
      'Header--dark-mode': metaProps.headerDarkMode && headerStore.isInverseMode,
    }"
  >
    <LazyHeaderGodMode v-if="userStore.mpAdminInfoEmail" />

    <div class="Header__container">
      <div class="Header__row Header__row--main">
        <!-- NAV -->
        <div
          v-if="props.links && !metaProps.headerNavHidden"
          class="Header__nav"
        >
          <HeaderNavigation
            :links="props.links"
            :is-nav-high-theme="props.isNavHighTheme"
          />
        </div>

        <!--  LOGO -->
        <div class="Header__logo">
          <HeaderLogo :with-description="isLogoWithDescription && !metaProps.headerLogoDescriptionHidden" />
        </div>

        <!-- ACTIONS -->
        <div
          v-if="!metaProps.headerActionsHidden"
          class="Header__right"
        >
          <div
            v-if="userStore.isUserLoggedIn && !props.isBasisHidden"
            class="Header__location"
          >
            <MButton
              :type="MM.EButtonTypes.Text"
              :theme="btnTheme"
              :icon-left="MM.EIconPath.NavigationLocationSvg"
              :size="MM.EButtonSizes.Medium"
              @click="onOpenBasisSelectModal"
            >
              {{ addressToShow }}
            </MButton>
          </div>
          <div class="Header__user">
            <LoginButton :is-error-page="metaProps.isErrorPage" />
          </div>

          <div
            class="Header__mobile-menu-toggler"
            @click="isMobileMenuOpened = !isMobileMenuOpened"
          >
            <MIcon :src="MM.EIconPath.NavigationMenuSvg" />
          </div>
        </div>
      </div>

      <!--   USER BAR -->
      <div
        v-if="showUserBar"
        class="Header__row"
      >
        <div class="Header__user-bar">
          <HeaderUserBar @click-go-to-basket="$emit('clickGoToBasket')" />
        </div>
      </div>
    </div>
  </header>

  <ClientOnly>
    <HeaderMobileMenu
      :links="props.links"
      :is-opened="isMobileMenuOpened"
      @close="isMobileMenuOpened = false"
    />

    <MobileMenu />
  </ClientOnly>
</template>

<script setup lang="ts">
import LoginButton from 'components/LoginButton.vue';
import { MM } from '~/types';

import HeaderLogo from './HeaderLogo.vue';
import HeaderNavigation from 'components/Header/HeaderNav/HeaderNav.vue';
import { useUserStore } from '~/store/user.store';

import { UserHelper } from 'utils/userHelper.util';

import BasesService from '~/services/basesManager.service';
import { IModalManager } from 'shared/models/modalManager.model';
import ModalManager from 'shared/services/modalManager.service';

import { useHeaderStore } from '~/store/Header.store';
import { IGroupMenuLinkItem } from 'shared/models/groupMenuLinkItem.model';
import IMenuLinkItem from 'shared/models/linkItem.model';

import HeaderUserBar from './HeaderUserBar.vue';
import { useSearchGroupAccess } from 'composables/useGroup';
import MobileMenu from 'components/MobileMenu.vue';
import HeaderMobileMenu from './HeaderMobileMenu.vue';
import WindowWidthManager from 'shared/services/windowWidth.service';
import { useAccountBannersStore } from '~/store/accountBanners.store';
import { EUserType } from '~/enums/user.enum';

const props = defineProps<{
  /** Ссылки навигации */
  links?: Array<IGroupMenuLinkItem> | Array<IMenuLinkItem>;
  /** Ставит лого в левый край, а меню в центр */
  isLogoLeft?: boolean;
  isNavHighTheme?: boolean;
  isUserBarHidden?: boolean;
  isLogoWithDescription?: boolean;
  isBasisHidden?: boolean;
}>();

defineEmits<{
  (e: 'clickGoToBasket'): void;
}>();

interface IMetaProps {
  headerLogoDescriptionHidden?: boolean;
  headerLogoLeft?: boolean;
  headerInverse?: boolean;
  headerUserBarHidden?: boolean;
  headerNavHidden?: boolean;
  headerActionsHidden?: boolean;
  headerTransparent?: boolean;
  headerDarkMode?: boolean;
  isErrorPage?: boolean;
}

const headerStore = useHeaderStore();
const userStore = useUserStore();

const isMobileMenuOpened = ref(false);
const metaProps = computed((): IMetaProps => useRouter().currentRoute.value.meta.props || {});

watchEffect(() => {
  if (metaProps.value) {
    headerStore.state.isInverseModeDefault = !!metaProps.value.headerInverse;
  }
});

const modalManager = process.client ? inject<IModalManager>(ModalManager.getServiceName()) : undefined;

// for Location btn
const btnTheme = computed(() => {
  return headerStore.isInverseMode ? MM.EButtonThemes.White : MM.EButtonThemes.TextMain;
});

// =============================================
// BASES
// =============================================

const basesManager = inject<BasesService>(BasesService.getServiceName());
const addressToShow = computed(() => {
  if (!userStore.isUserLoggedIn || UserHelper.isSupplier) {
    return basesManager?.city ? basesManager?.city?.replaceAll?.('"', '') : 'Укажите ваш город';
  }
  return basesManager?.base ? basesManager.base?.replaceAll?.('"', '') : 'Базис доставки';
});

function onOpenBasisSelectModal(): void {
  basesManager?.openSelectModal(modalManager, userStore.isUserLoggedIn);
}
const showUserBar = computed(
  () => useSearchGroupAccess().value && !props.isUserBarHidden && userStore.isUserLoggedIn && !metaProps.value.headerUserBarHidden,
);

watch(
  showUserBar,
  (value) => {
    headerStore.state.isUserBarActive = value;
  },
  {
    immediate: true,
  },
);

const refHeader = ref();
const { isTablet, isMobile, isSmallMobile, isDesktop, isLargeDesktop } = WindowWidthManager.getAdaptivaRefs();
/* Для подписки на события ресайза от баннера сообщения в ЛК*/
const accountBannersStore = useAccountBannersStore();
const hasBanner = computed(
  () => !!(accountBannersStore.accountBanners[EUserType.Client] || accountBannersStore.accountBanners[EUserType.Supplier]),
);

watch(
  [isTablet, isMobile, isSmallMobile, isDesktop, isLargeDesktop, hasBanner, userStore.isUserLoggedIn],
  async () => {
    // задержка, чтобы изменения успели внестись в DOM
    await nextTick();
    calcSizes();
  },
  {
    deep: true,
  },
);

onMounted(() => {
  calcSizes();
});

function calcSizes() {
  if (import.meta.client && refHeader.value) {
    const headerHeight = refHeader?.value?.getBoundingClientRect().height;
    const headerOffsetTop = refHeader?.value?.getBoundingClientRect().top;
    if (headerHeight) {
      document?.querySelector('body')?.style.setProperty('--header-height', `${headerHeight}px`);
      document?.querySelector('body')?.style.setProperty('--header-offset-top', `${headerOffsetTop}px`);

      headerStore.state.height = headerHeight;
      headerStore.state.offsetTop = headerOffsetTop;
    }
  }
}
</script>

<style lang="scss" scoped>
.Header {
  $s: &;
  position: relative;
  z-index: 25;
  top: 0;
  background-color: $color-white;

  // удалить после обновления библиотеки
  :deep(.MButton) {
    vertical-align: middle;
    margin-top: -4px;

    svg {
      transition: 0s;
    }
  }

  &__container {
    width: calc(var(--container-width) - var(--container-margin-X) * 2);
    max-width: calc(100% - var(--container-margin-X) * 2);
    padding: 20px 0;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 1px;
      bottom: -1px;
      left: calc(-1 * var(--container-offset-X));
      right: calc(-1 * var(--container-offset-X));
      background-color: $color-gray-light;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 24px;
  }

  &__user-bar {
    width: 100%;
  }

  &__logo {
    flex: 0 0 auto;
    height: 56px;
    display: flex;
    align-items: center;
  }

  &__right {
    flex: 1 1 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: opacity 0.5s;
    align-self: center;
  }

  &__nav {
    flex: 1 1 50%;
    transition: opacity 0.5s;
  }

  &__location {
    :deep(.MButton) {
      font-weight: 400;
    }

    & + .Header__user {
      margin-left: 40px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        height: 24px;
        left: -20px;
        background-color: $color-green-muted;
        width: 1px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &__mobile-menu-toggler {
    display: none;
  }

  @include maxw(1279) {
    &__logo {
      height: 28px;
      margin-left: 0;
    }

    &__location,
    &__user,
    &__nav {
      display: none;
    }

    &__mobile-menu-toggler {
      display: block;
    }
  }

  &--isNavHighTheme {
    #{$s}__nav {
      height: 100%;
    }
  }

  &--isLogoLeft {
    padding: 0;

    #{$s}__logo {
      order: -1;
    }

    #{$s}__right,
    #{$s}__nav {
      flex: 0 0 auto;
    }
  }

  &--transparent {
    background-color: transparent;
    border-bottom-color: transparent;

    #{$s}__container {
      &:before {
        opacity: 0;
      }
    }
  }

  &--inverse-mode {
    #{$s}__mobile-menu-toggler {
      :deep(path) {
        fill: white;
      }
    }
  }

  &--dark-mode {
    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      left: 50%;
      transform: translateX(-50%);

      height: calc(100% - 8px);
      top: 8px;
      background-color: $color-green-extra-dark;
      border-radius: 12px;

      width: calc(var(--container-width) - var(--container-margin-X) * 2 + var(--container-offset-X) * 2);
      max-width: calc(100% - var(--container-margin-X) * 2 + var(--container-offset-X) * 2);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

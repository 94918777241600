<template>
  <div class="m-container-offset">
    <footer
      class="mm-footer"
      :class="{ client: UserHelper.isClient }"
    >
      <div class="footer">
        <div
          class="footer__col"
          :class="{ 'footer__col_without-border': !isContactsVisible }"
        >
          <div class="footer__logo">
            <div class="logo">
              <div class="footer__text-logo logo--desc-on">
                <div class="logo__main-wrap">
                  <div class="logo-wrap">
                    <BaseImg
                      src="images/logo-icon-inverted.svg"
                      class="logo__icon"
                    />
                    <span class="logo__main">максмарт</span>
                  </div>
                  <span class="logo__desc">маркетплейс быстрых закупок</span>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="isContactsVisible"
            class="footer__btn"
          >
            <NuxtLink
              to="mailto:support@maksmart.ru"
              class="btn__link"
            >
              Свяжитесь с нами
            </NuxtLink>
          </div>
        </div>
        <div class="footer__col">
          <div
            v-if="isContactsVisible"
            class="footer__contacts"
            itemscope
            itemtype="http://schema.org/Organization"
          >
            <div
              hidden
              itemprop="name"
            >
              Максмарт
            </div>
            <div
              hidden
              itemprop="address"
              itemscope
              itemtype="http://schema.org/PostalAddress"
            >
              <span itemprop="addressLocality">Москва,</span> <span itemprop="streetAddress">ул. Земляной вал, д. 50А, стр. 6</span>
            </div>
            <NuxtLink
              to="tel:+78005008106"
              class="contact__link"
            >
              <span itemprop="telephone">8 800 500-81-06</span>
            </NuxtLink>
            <NuxtLink
              to="mailto:support@maksmart.ru"
              class="contact__link"
              itemprop="email"
            >
              support@maksmart.ru
            </NuxtLink>
          </div>
        </div>
        <div class="footer__col footer__col--bn">
          <div class="footer__breadcrumbs">
            <div
              v-for="(section, index) in sections"
              :key="index"
              class="breadcrumbs__col"
            >
              <div
                class="breadcrumbs__title"
                :class="{ open: openBreadcrumb === index }"
                @click="openBreadcrumb = openBreadcrumb === index ? -1 : index"
              >
                {{ section.title }}
                <span class="breadcrumbs__title-icon">
                  <SvgIcon src="navigation/arrow-down-20px" />
                </span>
              </div>
              <ul class="breadcrumbs__list">
                <li
                  v-for="(link, linkIndex) in section.links"
                  :key="linkIndex"
                  class="breadcrumbs__item"
                  :class="{ disabled: link.disabled }"
                >
                  <NuxtLink
                    no-prefetch
                    :to="!link.file && link.to"
                    class="breadcrumbs__link"
                    @click="link.file && onOpenFile(link.to)"
                  >
                    {{ link.text }}
                  </NuxtLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="footer__bottom">
          <span class="bottom__link">© {{ currentYear }} АО «B2B Трэйд»</span>
        </div>
        <div class="footer__bottom" />
        <div class="footer__bottom">
          <span
            class="bottom__link"
            @click="onOpenFile(ECommonFile.PrivacyPolicy)"
          > Условия обработки персональных данных </span>
          <a class="bottom__link"> Дизайн от AIC </a>
        </div>
      </div>
    </footer>
  </div>
  <div
    v-if="userStore.isUserLoggedIn"
    class="footer-bottom__gap"
  />
</template>

<script lang="ts" setup>
import { footerSections } from 'constants/layouts/footer.const';
import SvgIcon from 'shared/components/SvgIcon.vue';
import BaseImg from 'shared/components/BaseImg.vue';
import { UserHelper } from 'utils/userHelper.util';
import { useUserStore } from 'store/user.store';
import { cloneDeep } from 'lodash-es';
import { IFooterSection } from 'models/layouts/footer.model';
import { EFooterSection } from 'enums/footerSection.enum';
import { EFooterSupplierLink } from 'enums/footerSupplierLink.enum';
import { ECommonFile } from 'shared/enums/commonFile.enum';
import { WatchSubscription } from 'shared/utils/watchSubscription';
import useSSRUnsubscribeWatch from 'shared/composables/useSSRUnsubscribeWatch';

const userStore = useUserStore();
const sections = reactive<Array<IFooterSection>>(cloneDeep(footerSections));
const openBreadcrumb = ref(-1);
const currentYear = new Date().getFullYear();
const watchSubscription = new WatchSubscription();

const isContactsVisible = computed(() => !UserHelper.isPolus && !UserHelper.isNLMK);

function updateKnowledgeBaseUrl(isUserLoggedIn: boolean): void {
  const supplierKnowledgeBase = sections
    ?.find((section) => section.id === EFooterSection.ForSuppliers)
    ?.links?.find((link) => link.id === EFooterSupplierLink.SupplierKnowledgeBase);

  if (supplierKnowledgeBase) {
    supplierKnowledgeBase.to = useRuntimeConfig()?.public?.supplierKnowledgeBaseUrl;
    supplierKnowledgeBase.disabled = !supplierKnowledgeBase.to || !isUserLoggedIn;
  }
}

function onOpenFile(fileName: string): void {
  window.open(fileName);
}

watchSubscription.add(watch(() => userStore.isUserLoggedIn, updateKnowledgeBaseUrl, { immediate: true }));

useSSRUnsubscribeWatch(watchSubscription);
</script>

<style scoped lang="scss">
@import 'styles/base/common/variables';

.mm-footer {
  background: $footer-bg;
  border-radius: 8px;
  margin: 0 auto 8px;
  padding: 60px var(--container-offset-X);

  .footer__col .footer__btn .btn__link {
    max-width: 247px;
    width: calc(100% - 32px);
  }

  & .footer__col_without-border {
    border-right: 0;
  }
}

.footer-bottom__gap {
  width: 100%;
  height: 8px;
  flex: 0 0 8px;
}

@media only screen and (max-width: 1279px) {
  .mm-footer {
    // width: calc(100% - 8px);
    margin-bottom: 56px;

    .footer__col .footer__btn .btn__link {
      width: 222px;
      padding: 10px 32px;
      height: 48px;
      align-items: center;
      display: flex;
      justify-content: center;
    }

    & .footer__col_without-border {
      border-bottom: 0;
    }
  }

  .footer-bottom__gap {
    height: 4px;
    flex: 0 0 4px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-bottom__gap {
    height: 20px;
    flex: 0 0 20px;
  }

  .mm-footer {
    margin-bottom: 44px;

    .footer__col .footer__btn .btn__link {
      width: 100%;
      max-width: unset;
      margin-top: 4px;
    }
  }
}
</style>

<template>
  <nav
    v-if="links"
    class="HeaderNav"
    :class="[
      isNavHighTheme ? 'HeaderNav--theme-high' : 'HeaderNav--theme-default',
      { 'HeaderNav--theme-inverse': headerStore.isInverseMode },
    ]"
  >
    <NuxtLink
      v-for="(item, ndx) in links"
      :key="ndx"
      :to="item.path"
      :target="item.target"
      class="HeaderNav__link"
      @click="item.onClickFunction"
    >
      {{ item.label }}

      <MIcon
        v-if="item.hasAlert"
        class="HeaderNav__link-alert"
        :src="MM.EIconPath.IndicatorsWarningSvg"
      />
    </NuxtLink>
  </nav>
</template>

<script setup lang="ts">
import IMenuLinkItem from 'shared/models/linkItem.model';
import { useHeaderStore } from '~/store/Header.store';
import { MM } from 'types';

defineProps<{
  links?: IMenuLinkItem[];
  isNavHighTheme?: boolean;
}>();

const headerStore = useHeaderStore();
</script>

<style lang="scss" scoped>
.HeaderNav {
  $s: &;

  display: flex;
  flex-wrap: wrap;
  grid-gap: 8px 41px;
  align-self: stretch;

  &__link-alert {
    margin-left: 6px;
  }

  &--theme-default {
    #{$s}__link {
      @include text-r14;

      color: $color-text-main;
      text-decoration: none !important;
      position: relative;

      &:hover {
        color: $color-accent-hover;
      }

      &.router-link-active {
        color: $color-accent;
      }

      & + #{$s}__link {
        &:before {
          pointer-events: none;
          content: '';
          position: absolute;
          left: -20px;
          top: 0;
          bottom: 0;
          width: 1px;
          background-color: $color-green-muted;
        }
      }
    }
  }

  &--theme-high {
    height: 96px;
    margin-top: -20px;
    margin-bottom: -20px;

    #{$s}__link {
      @include text-r14;

      color: $color-secondary;
      text-decoration: none;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      align-items: center;
      height: 100%;
      transition: 0.2s;

      &:before {
        content: '';
        border-radius: 3px 3px 0 0;
        height: 4px;
        left: 0;
        right: 0;
        bottom: -1px;
        position: absolute;
        background: $color-green-gradient;
        transform: scaleY(0);
        transform-origin: 50% 100%;
        transition: 0.2s;
      }

      @include hover {
        color: $color-text-main;

        &:before {
          transform: scaleY(1);
        }
      }

      &.router-link-active {
        font-weight: 500;
        color: $color-text-main;

        &:before {
          transform: scaleY(1);
        }
      }
    }
  }

  &--theme-inverse {
    #{$s}__link {
      color: white;
    }
  }
}
</style>
<template>
  <div :class="['mm-add-cart__btn', wrapperClass]">
    <LoaderButton
      v-if="!isButtonDisabled && !isAddedToCart"
      id="order_button"
      class="btn btn-primary"
      :custom-disabler="disabledAddButton"
      :boolean-loader="loading"
      @click="onAddToCart"
    >
      <span v-if="btnText">{{ btnText }}</span>
      <span v-else>
        Заказать <span class="btn__quantity-info">{{ formatQuantity(props.quantity ?? 1, precision, unitName) }}</span>
      </span>
    </LoaderButton>

    <div
      v-else-if="isUserLoggedIn && isAddedToCart"
      id="product_added_label"
      class="mm-offers-expanded__added-cart"
    >
      <SvgIcon src="indicators/checkmark-round" />
      <span class="mm-offers-expanded__added-cart-text">
        {{
          t(ERuLocales.AddToCart, props.quantity ?? 1).replace(
            (props.quantity ?? 1).toString(),
            formatQuantity(props.quantity ?? 1, precision, unitName),
          )
        }}
      </span>
    </div>

    <template v-else>
      <BaseTooltip
        arrow
        offset-distance="4"
        close-delay="100"
        hover
        interactive
        :disabled="disabledTooltip"
        class="mm-tooltip mm-tooltip--base mm-add-cart__tooltip"
      >
        <button
          class="btn btn-primary"
          disabled
          @click="onAddToCart"
        >
          <span v-if="btnText">{{ btnText }}</span>
          <span v-else>
            Заказать <span class="btn__quantity-info">{{ formatQuantity(props.quantity ?? 1, precision, unitName) }}</span>
          </span>
        </button>

        <template #content>
          <div class="mm-offer-terms">
            <div class="mm-offer-term">
              <template v-if="isUserLoggedIn">
                {{ btnDisabledText }}
              </template>
              <template v-else>
                Заказ товаров доступен только после
                <a
                  class="mm-text-undecorated-link"
                  @click="onAuthLinkClick"
                > авторизации </a>
              </template>
            </div>
          </div>
        </template>
      </BaseTooltip>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { Ref } from 'vue';
import { storeToRefs } from 'pinia';
import BaseTooltip from 'shared/components/BaseTooltip.vue';
import { ERuLocales } from 'shared/enums/ruLocales.enum';
import { useUserStore } from 'store/user.store';
import { ICartOfferInfo } from 'shared/models/product.model';
import { EProductProvideKeys } from 'enums/productProvideKeys.enum';
import { useAuthFnHelper } from 'composables/useAuthHelper';
import { isPermittedAddToCart, isPermittedAddToERPCart } from 'constants/pages/product.const';
import BasesService from 'services/basesManager.service';
import { UserHelper } from 'utils/userHelper.util';
import LoaderButton from 'shared/components/LoaderButton.vue';
import { formatQuantity } from 'shared/utils/formatQuantity.util';
import { BasketManager } from 'services/basket-services/basket/basketManager.service';
import { EERpBasketDistributionStatus } from 'enums/basket/erpBasketDistributionStatus.enum';

const props = defineProps<{
  quantity: number;
  offerId: number;
  wrapperClass?: string;
  btnText?: string;
  disabledAddButton?: boolean;
  loading?: boolean;
  isErp?: boolean;
  hasMapping?: boolean;
  isConflictBasesCategory?: boolean;
  isDisabledByClientCategoryCode?: boolean;
  unitName: string;
  disabledByOfferRules?: boolean;
  precision?: number;
  disabledTooltip?: boolean;
}>();

const emits = defineEmits<{
  (e: 'onAddToCart');
  (e: 'onAuthLinkClick');
}>();

const { t } = useI18n();
const userStore = useUserStore();
const { isUserLoggedIn } = storeToRefs(userStore);
const cartOfferState = inject<Ref<Array<ICartOfferInfo>>>(EProductProvideKeys.CartOfferState);
const basesManager = inject<BasesService>(BasesService.getServiceName());
const basketManager = new BasketManager();
const isDistributionInProgress = computed(() => basketManager.currentBasketDistributionStatus.value === EERpBasketDistributionStatus.InProgress);

const isProhibitedToAddProduct = computed(
  () => !userStore.isOciAuth && !userStore.isUserHasGroup(props.isErp ? isPermittedAddToERPCart : isPermittedAddToCart),
);

const isProhibitedByErp = computed(() => !props.isErp && !userStore.isOciAuth && !props.hasMapping);
const isProhibitedByBases = computed(() => props.isConflictBasesCategory);
const isProhibitedByClientCategoryCode = computed(() => props.isDisabledByClientCategoryCode);
const isButtonDisabled = computed(
  () =>
    !isUserLoggedIn?.value ||
    isProhibitedToAddProduct?.value ||
    isProhibitedByErp?.value ||
    isProhibitedByBases.value ||
    isProhibitedBySubdivision.value ||
    isProhibitedByClientCategoryCode.value ||
    props.disabledByOfferRules ||
    isDistributionInProgress.value,
);

const isAddedToCart = computed(() => !!cartOfferState?.value?.find((cartOffer) => cartOffer.offerId === props.offerId));
const isProhibitedBySubdivision = computed(() => !props.isErp && UserHelper.isAIM && !basesManager?.currentUserBase?.subdivisionName);

const btnDisabledText = computed(() => {
  if (isProhibitedByClientCategoryCode.value) {
    return 'Товар недоступен для заказа';
  }

  if (isProhibitedByBases.value) {
    return 'Товары из данной категории не доступны для заказа на выбранный адрес доставки';
  }

  if (isProhibitedBySubdivision.value) {
    return 'Товары доступны к заказу только при выборе склада доставки';
  }

  if (isProhibitedToAddProduct.value) {
    return 'Для пользователей с вашей ролью заказ товаров недоступен';
  }

  if (isProhibitedByErp.value) {
    return 'Товар доступен к заказу только при передаче потребности из ERP';
  }

  if (props.disabledByOfferRules) {
    return 'Чтобы добавить товар в корзину необходимо выполнить условия по минимальной партии и кванту поставки';
  }

  if (isDistributionInProgress.value) {
    return 'Товар не может быть добавлен в корзину пока не завершится автоматический подбор предложений';
  }
});

const { showLoginModal } = useAuthFnHelper();

function onAuthLinkClick() {
  emits('onAuthLinkClick');
  showLoginModal();
}

function onAddToCart() {
  emits('onAddToCart');
}
</script>

<style lang="scss" scoped>
.mm-add-cart {
  &__btn {
    display: flex;
    position: relative;
    z-index: 4;

    .btn.btn-primary {
      padding: 10px 28px;
    }
  }

  .btn__quantity-info {
    margin-left: 4px;
    opacity: 0.6;
  }

  &__tooltip {
    :deep(.popper) {
      max-width: max-content;
    }
  }
}

:deep(.mm-add-cart__tooltip) {
  .popper {
    max-width: max-content;
  }
}

.mm-offers-expanded {
  &__added-cart {
    height: 40px;
  }
}
</style>

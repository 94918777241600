<template>
  <div>
    <div
      v-if="!sortedOffersItems?.length"
      class="no-offers-block"
    >
      <p class="mm-headline-4">
        <template v-if="filteredOffers">
          Предложения товаров по вашему запросу не найдены
        </template>
        <template v-else>
          Поставщики с необходимым количеством <br>
          товара в наличии не найдены
        </template>
      </p>
      <p class="mm-caption-regular-m">
        <template v-if="filteredOffers">
          Попробуйте изменить выбранные параметры или<br>
          найдите похожие товары на странице категорий
        </template>
        <template v-else>
          Попробуйте заказать нужное вам количество <br>
          товара по частям от нескольких поставщиков
        </template>
      </p>
      <button
        class="btn btn-primary"
        @click="$emit('resetCount')"
      >
        Показать все предложения
      </button>
    </div>
    <div
      v-else
      class="mm-product-offers"
      :class="{ 'hidden-price': !isShowPrice, safari: isSafari }"
    >
      <AppTable
        class="mm-product-table"
        :data="sortedOffersItems ?? []"
        :show-rows-count="5"
        :show-more-count="5"
        :show-more-btn-params="{ buttonText: 'Показать еще' }"
        :columns="isCatalogAvailable ? headerColumns : unLoggedHeaderColumns"
        :theme="ETableThemes.Separated"
        :expanded-row-indexes="isShowPrice ? [currentExpandedRowIndex] : []"
        :sort="defaultSort"
        is-non-pageable
        :disabled-head-sort="isLoading"
        :expand-on-row-click="isShowPrice"
        :get-additional-row-class="(row) => getAdditionalRowClass(row)"
        table-inner-id="offers_table"
        table-body-id="offer_form"
        show-more-row-id="show_more_button"
        :get-row-shadow-style="getTableRowShadowStyle"
        :is-first-row-opened="isShowPrice"
        is-accordion
        :is-need-accordion-action="isShowPrice"
        :use-mobile-content="isShowPrice"
        :get-row-key="(offer) => offer?.id"
        :is-show-more-btn-available="isCatalogAvailable"
        init-data-on-server
        :highlighted-index="isFirstOfferHighlighted ? 0 : undefined"
        @on-sort-changed="onSortOffersChanged"
        @on-row-click="onRowClick"
      >
        <template #accordionColumnHeader>
          <div class="mm-table-settings">
            <PopupActions
              v-if="!ociMode"
              :actions="priceOptions"
              class="mm-table-settings__popup"
              selectable
              open-mode="click"
              :disabled="isLoading"
              @select="onSelectPriceOption"
            >
              <SvgIcon :src="EIconPath.ActionSettings2Svg" />
            </PopupActions>
          </div>
        </template>
        <template #headerSortSection>
          <div
            v-if="isMobileAdaptive && isShowPrice"
            class="mm-table-sort--mobile"
          >
            <div
              class="mm-table-sort-inner--mobile"
              @click="onChangeMobileSort"
            >
              <div class="sort-filter">
                {{ mobileSortFilterTextMap.get(currentMobileSortPrice ?? ESortState.Asc) }}
              </div>

              <SvgIcon
                :src="EIconPath.NavigationFilterNewSvg"
                :class="{
                  asc: currentMobileSortPrice === ESortState.Asc,
                  'mm-table-sort--mobile-active': currentMobileSortPrice,
                  'mm-table-sort--mobile-default': !currentMobileSortPrice,
                }"
              />
            </div>
            <div class="mm-table-settings">
              <PopupActions
                v-if="!ociMode"
                :actions="priceOptions"
                class="mm-table-settings__popup"
                selectable
                open-mode="click"
                @select="onSelectPriceOption"
              >
                <SvgIcon :src="EIconPath.ActionSettings2Svg" />
              </PopupActions>
            </div>
          </div>
        </template>

        <template
          v-if="isShowPrice"
          #mobileContent="{ row }"
        >
          <div v-if="isMobileAdaptive">
            <OfferLabel
              v-if="declinedItemsMap.get(row.id)"
              text="Ранее отклонено"
              label-class="offers-table__label mm-block-light-green"
            />
            <OfferLabel
              v-else-if="!ociMode && row.bestPrice"
              text="Лучшая цена"
              label-class="offers-table__label mm-block-orange"
            />
            <OfferLabel
              v-else-if="row.bestDelivery && isUserLoggedIn"
              text="Быстрая доставка"
              label-class="offers-table__label mm-block-blue"
            />
          </div>

          <div
            v-if="isMobileAdaptive"
            class="offer__mobile"
            :class="{ 'top-p': row.bestPrice || (row.bestDelivery && isUserLoggedIn) || declinedItemsMap.get(row.id) }"
          >
            <div class="mm-body-medium-s d-flex offer__mobile-price">
              <div class="mm-price__value">
                {{ formatPrice(OfferMapper.getCurrentOverallPrice(row, selectedCalcType?.value)?.price, undefined, undefined, '₽') }}
                <span class="mm-price__unit">за {{ formatQuantity(+productsCount || 1, UnitOkeiHelper.getModelUnitPrecision(productDetails.unit), unitName) }}</span>
              </div>
              <div class="offers-table-popup">
                <BaseTooltip
                  hover
                  offset-distance="4"
                  arrow
                >
                  <SvgIcon src="navigation/info-outline" />
                  <template #content>
                    <div class="mm-offer-terms">
                      <div class="mm-offer-term">
                        <p class="mm-headline-5 mm-font-400">
                          Условия заказа
                        </p>
                        <ProductCharacteristic
                          class="product-characteristic"
                          low-opacity
                          :characteristics="
                            OfferMapper.mapShipping(row, 0, unitName, UnitOkeiHelper.getModelUnitPrecision(productDetails.unit))
                          "
                        />
                      </div>
                      <div class="mm-offer-term">
                        <p class="mm-headline-5 mm-font-400">
                          Цена в зависимости от объема
                        </p>
                        <ProductCharacteristic
                          class="product-characteristic"
                          low-opacity
                          :characteristics="OfferMapper.mapTermsPriceToChars(row.termsPrice, unitName)"
                        />
                      </div>
                    </div>
                  </template>
                </BaseTooltip>
              </div>
            </div>
            <span class="offer__mobile-price-sm">
              {{ priceTypeMapping[priceTitle || 'Цена'] }}
            </span>

            <ul class="offer__mobile-list">
              <li class="offer__mobile-item">
                <span>Поставщик</span> #{{ row.catalogId }}
              </li>

              <li class="offer__mobile-item">
                {{ row.available ? `В наличии ${row.stock} ${unitName}` : 'Под заказ' }}
              </li>

              <li class="offer__mobile-item delivery">
                <template v-if="isUserLoggedIn">
                  Доставка
                  <div v-if="row.available">
                    <strong>
                      {{ OfferMapper.mapDeliveryDaysBeforeOnly(row.maxDeliveryDays) }}
                    </strong>
                    <span class="item-price"> — {{ OfferMapper.mapDeliveryPrice(row.deliveryCost) }}</span>
                  </div>

                  <div
                    v-else
                    class="unavailable"
                  >
                    <strong>под заказ</strong>
                  </div>
                </template>

                <template v-else>
                  -
                </template>
              </li>
            </ul>
          </div>
        </template>

        <template #supplierId="{ row }">
          <div
            v-if="row.isCatalogInCart"
            class="offers-table__badge-wrapper"
          >
            <MBadge>Поставщик есть в корзине</MBadge>
          </div>

          <div
            v-if="isShowPrice"
            style="position: relative"
          >
            <OfferLabel
              v-if="declinedItemsMap.get(row.id)"
              text="Ранее отклонено"
              label-class="offers-table__label mm-block-light-green"
            />
            <OfferLabel
              v-else-if="!ociMode && row.bestPrice"
              text="Лучшая цена"
              label-class="offers-table__label mm-block-orange"
            />
            <OfferLabel
              v-else-if="row.bestDelivery && isUserLoggedIn && row.available"
              text="Быстрая доставка"
              label-class="offers-table__label mm-block-blue"
            />
          </div>
          <MSkeletonLoader
            :is-loading="isLoading && !row.catalogId"
            :type="MM.ESkeletonLoaderTypes.Lines"
          >
            <span v-if="!isShowPrice && isMobileAdaptive">Поставщик</span> #{{ row.catalogId }}
          </MSkeletonLoader>
        </template>

        <template #available="{ row }">
          <MSkeletonLoader
            :is-loading="isLoading && !offersLoaded"
            :type="MM.ESkeletonLoaderTypes.Lines"
            :width="80"
          >
            <div
              v-if="row.available"
              :class="{ 'no-price': !isShowPrice }"
            >
              <div class="offers-table-suffix__label">
                В наличии
              </div>
              <div
                v-if="row.stock"
                class="offers-table-suffix"
              >
                {{ row.stock }} {{ unitName }}
              </div>
            </div>
            <div
              v-else
              class="offers-table-suffix__label"
            >
              Под заказ
            </div>
          </MSkeletonLoader>
        </template>

        <template #deliveryPrice="{ row }">
          <div class="mm-body-medium-s mm-text-nowrap mm-font-400">
            <MSkeletonLoader
              :is-loading="isLoading"
              :type="MM.ESkeletonLoaderTypes.Lines"
              :width="70"
            >
              {{ isUserLoggedIn ? OfferMapper.mapDeliveryDaysBeforeOnly(row.maxDeliveryDays) : '-' }}
            </MSkeletonLoader>
          </div>
          <div
            class="offers-table-suffix"
            :style="{ marginTop: isLoading ? '6px' : undefined }"
          >
            <MSkeletonLoader
              :is-loading="isLoading"
              :type="MM.ESkeletonLoaderTypes.Lines"
              :width="70"
            >
              {{ isUserLoggedIn ? OfferMapper.mapDeliveryPrice(row.deliveryCost) : '-' }}
            </MSkeletonLoader>
          </div>
        </template>

        <template #priceSum="{ row }">
          <div class="mm-body-medium-s mm-font-500 d-flex align-items-center price--sm">
            <MSkeletonLoader
              :is-loading="isLoading && (!offersLoaded || sortFieldPriceWithDelivery || sortFieldPriceWithVatAndDelivery)"
              :type="MM.ESkeletonLoaderTypes.Lines"
              :width="120"
            >
              <div class="mm-price__value">
                {{ formatPrice(OfferMapper.getCurrentOverallPrice(row, selectedCalcType?.value)?.price, undefined, undefined, '₽') }}
                <span class="mm-price__unit">за {{ formatQuantity(+row.quantity || 1, UnitOkeiHelper.getModelUnitPrecision(productDetails.unit), unitName) }}</span>
              </div>
              <div class="offers-table-popup">
                <BaseTooltip
                  hover
                  offset-distance="4"
                  arrow
                >
                  <SvgIcon :src="EIconPath.NavigationInfoOutlineSvg" />
                  <template #content>
                    <div class="mm-offer-terms">
                      <div class="mm-offer-term">
                        <p class="mm-headline-5 mm-font-400">
                          Условия заказа
                        </p>
                        <ProductCharacteristic
                          class="product-characteristic"
                          low-opacity
                          :characteristics="
                            OfferMapper.mapShipping(row, 0, unitName, UnitOkeiHelper.getModelUnitPrecision(productDetails.unit))
                          "
                        />
                      </div>
                      <div class="mm-offer-term">
                        <p class="mm-headline-5 mm-font-400">
                          Цена в зависимости от объема
                        </p>
                        <ProductCharacteristic
                          class="product-characteristic"
                          low-opacity
                          :characteristics="OfferMapper.mapTermsPriceToChars(row.termsPrice, unitName)"
                        />
                      </div>
                    </div>
                  </template>
                </BaseTooltip>
              </div>
            </MSkeletonLoader>
          </div>
          <div class="offers-table-suffix">
            {{ priceTypeMapping[priceTitle || 'Цена'] }}
          </div>
        </template>

        <template
          v-if="isShowPrice"
          #expandedRow="{ row }"
        >
          <tr
            v-if="declinedItemsMap.get(row.id) && !isMobileAdaptive"
            class="offers-table--declined-item"
          >
            <td
              colspan="6"
              class="offers-table--declined-item__inner"
            >
              <div class="offers-table--declined-item__inner--warning">
                <BasketWarningRow
                  hide-select-button
                  :warning-type="EWarningMessageType.Simple"
                  :text="`Поставщик не подтвердил заказ данного товара в количестве <span>${declinedItemsMap.get(
                    row.id,
                  )} ${unitName}</span>`"
                />
              </div>
            </td>
          </tr>
          <OffersExpandedSection
            :is-full-loading="isLoading"
            :product-id="productId?.toString()"
            :current-calc-type="selectedCalcType?.value"
            :product-data="row"
            :image="productDetails.images?.[0]?.url"
            :item-chars="offerCharacteristics"
            :offer-id="row.id"
            :is-erp="isErp"
            :offer="row"
            :oci-mode="ociMode"
            :declined-items-count="declinedItemsMap.get(row.id)"
            is-configured-terms
            :quantity="showOfferQuantity ? +row.quantity : +productsCount || 1"
            :show-offer-quantity="showOfferQuantity"
            :has-mapping="productDetails.hasPimMapping"
            :mtr-code="productDetails?.mtrCode"
            :loading-add-to-cart="loadingAddToCart"
            :focused-quantity-input="focusedQuantityInput"
            :product-details="productDetails"
            :category-data="categoryData"
            :unit-name="unitName"
            :is-disabled-by-client-category-code="productDetails.isDisabledByClientCategoryCode"
            :has-delivery-discount="!props.ociMode && row.isSupplierInCart"
            @on-add-count="$emit('addCount', $event)"
            @on-add-to-cart="$emit('addToCart', $event)"
          />
        </template>

        <template #unLoggedSupplierId>
          <div class="unLogged" />
        </template>
        <template #unLoggedDeliveryPrice>
          <div class="unLogged" />
          <div class="unLogged" />
        </template>
        <template #unLoggedPriceSum>
          <div class="unLogged" />
          <div class="offers-table-suffix">
            {{ priceTypeMapping[priceTitle || 'Цена'] }}
          </div>
        </template>
      </AppTable>
    </div>
  </div>
</template>

<script lang="ts" setup>
import AppTable from 'shared/components/AppTable.vue';
import OfferLabel from '../OfferLabel.vue';
import BaseTooltip from 'shared/components/BaseTooltip.vue';
import ProductCharacteristic from 'shared/components/ProductCharacteristic.vue';
import SvgIcon from 'shared/components/SvgIcon.vue';
import { OfferMapper } from 'shared/utils/offerMapper.util';
import OffersExpandedSection from './OffersExpandedSection.vue';
import { ICartOfferInfo, IMockProductResultedOffer, IProductDetails, IProductResultedOffer, IProductSpecification } from 'shared/models/product.model';
import { ISelectOption } from 'shared/models/select.model';
import { IDeclinedErpItem } from 'models/basket.model';
import { StyleValue } from 'vue';
import { ETableThemes } from 'shared/enums/tableThemes.enum';
import WindowWidthManager from 'shared/services/windowWidth.service';
import IProductCharacteristic from 'shared/models/productCharacteristic.model';
import { IColumnHeader, ITableRowClickEvent, ITableSort } from 'shared/models/table.model';
import { ESortState } from 'shared/enums/sortState.enum';
import { EIconPath } from 'shared/enums/iconPath.enum';
import { formatPrice } from 'shared/utils/priceValueFormat.util';
import { EProductSortField } from 'enums/EProductSortField.enum';
import { EOfferPriceType } from 'shared/enums/offersPriceType.enum';
import { BrowserChecker } from 'shared/utils/browserChecker.util';
import { ICategoryPageResponse } from 'server/models/categoryPage';
import { TableUtils } from 'shared/utils/table.util';
import { EWarningMessageType } from 'shared/enums/warningMessageType.enum';
import BasketWarningRow from 'components/basket/BasketWarningRow.vue';
import { WatchSubscription } from 'shared/utils/watchSubscription';
import useSSRUnsubscribeWatch from 'shared/composables/useSSRUnsubscribeWatch';
import { formatQuantity } from 'shared/utils/formatQuantity.util';
import { UnitOkeiHelper } from 'utils/unitOkeiHelper.util';
import PopupActions from 'shared/components/PopupActions.vue';
import { IAction } from 'shared/models/action.model';
import { EOfferCalcTypes } from 'shared/enums/offerCalcTypes.enum';
import { useCatalog } from 'composables/useCatalog';
import { MBadge } from '@maksmart/maksify';
import { MSkeletonLoader } from '@maksmart/maksify';
import { MM } from 'types';

const props = withDefaults(
  defineProps<{
    productDetails: IProductDetails;
    productId: number;
    selectedCalcType: ISelectOption;
    selectCalcOptions: Array<ISelectOption>;
    productsCount: string;
    loadingAddToCart?: boolean;
    focusedQuantityInput?: boolean;
    isLoading?: boolean;
    isErp?: boolean;
    categoryData: ICategoryPageResponse;
    declinedItems?: Array<IDeclinedErpItem>;
    isShowPrice?: boolean;
    showOfferQuantity?: boolean;
    ociMode?: boolean;
    filteredOffers?: boolean;
    offersSortState: ITableSort | null;
    sortedOffersItems: Array<IProductResultedOffer | IMockProductResultedOffer>;
    isFirstOfferHighlighted?: boolean;
    offersLoaded?: boolean;
  }>(),
  {
    isShowPrice: true,
  },
);

const emits = defineEmits<{
  (e: 'addCount', count: number): void;
  (e: 'addToCart', cardOfferInfo: ICartOfferInfo): void;
  (e: 'resetCount'): void;
  (e: 'update:selectedCalcType', option: ISelectOption): void;
  (e: 'update:offersSortState', sortState: ITableSort | null): void;
}>();

const headerCalcTypeMap = new Map([
  ['цена товара', 'Цена'],
  ['цена товара + доставка', 'Цена + доставка'],
  ['цена товара + НДС', 'Цена + НДС'],
  ['цена товара + доставка + НДС', 'Цена + доставка + НДС'],
]);

const priceTypeMapping: Record<string, string> = {
  Цена: 'без учета доставки',
  'Цена + НДС': 'без учета доставки, с НДС',
  'Цена + доставка': 'с учетом доставки',
  'Цена + доставка + НДС': 'с учетом доставки и НДС',
};

const mobileSortFilterTextMap = new Map([
  [ESortState.Desc, 'Сначала дорогие'],
  [ESortState.Asc, 'Сначала дешевые'],
]);
const defaultSort = {
  field: EOfferPriceType.PriceSum,
  order: ESortState.Asc,
};
const isSafari = BrowserChecker.isSafari();
const { isMobile, isSmallMobile } = WindowWidthManager.getAdaptivaRefs();
const currentMobileSortPrice = ref<ESortState | null>(null);
const { isUserLoggedIn } = useUser();
const { isCatalogAvailable } = useCatalog();

const isMobileAdaptive = computed(() => isMobile.value || isSmallMobile.value);
const currentOverallPrice = computed(() =>
  OfferMapper.getCurrentOverallPrice(undefined, props.selectedCalcType?.value as Array<EOfferCalcTypes>),
);
const priceOptions = computed<Array<IAction<number>>>(() =>
  (props.selectCalcOptions || []).map(
    (option: ISelectOption) => ({ id: option.id, name: headerCalcTypeMap.get(option.label as string) } as IAction<number>),
  ),
);

const declinedItemsMap = new Map<number, number>();

const offersSortState = computed<ITableSort | null>({
  get() {
    return props.offersSortState;
  },
  set(newSortState: ITableSort | null) {
    emits('update:offersSortState', newSortState);
  },
});

const watchSubscription = new WatchSubscription();
const priceTitle = computed(() => headerCalcTypeMap.get(props.selectedCalcType?.label as string));
const headerColumns = computed<Array<IColumnHeader>>(() => {
  const priceTitle = headerCalcTypeMap.get(props.selectedCalcType?.label as string);
  return props.isShowPrice
    ? [
        { title: 'Поставщик', field: 'supplierId', minWidth: '150px', width: '150px', cellId: 'supplier_id_label' },
        { title: 'Наличие', field: 'available', minWidth: '150px', width: '150px', cellId: 'quantity_label' },
        {
          title: 'Доставка',
          field: EProductSortField.DeliveryPrice,
          minWidth: '150px',
          width: '150px',
          sortable: isUserLoggedIn.value,
          cellId: 'delivery_label',
        },
        {
          title: priceTitle ? priceTitle : 'Цена',
          field: 'priceSum',
          sortable: true,
          minWidth: '200px',
          width: '200px',
          cellId: 'price_label',
          cellCssClass: 'mm-offer-table__cell-price',
        },
      ]
    : [
        { title: 'Поставщик', field: 'supplierId', width: '50%', cellId: 'supplier_id_label' },
        {
          title: 'Наличие',
          field: 'available',
          width: '50%',
          cellId: 'quantity_label',
          cssClass: 'text-right',
          cellCssClass: 'text-right',
          align: 'right',
        },
      ];
});

const unLoggedHeaderColumns = computed(() => {
  const priceTitle = headerCalcTypeMap.get(props.selectedCalcType?.label as string);

  return [
    { title: 'Поставщик', field: 'unLoggedSupplierId', minWidth: '150px', width: '150px', cellId: 'supplier_id_label', cellCssClass: 'mm-offer-table__cell-unLogged' },
    { title: 'Наличие', field: 'available', minWidth: '150px', width: '150px', cellId: 'quantity_label', cellCssClass: 'mm-offer-table__cell-unLogged' },
    {
      title: 'Доставка',
      field: 'unLoggedDeliveryPrice',
      minWidth: '150px',
      width: '150px',
      cellId: 'delivery_label',
      cellCssClass: 'mm-offer-table__cell-unLogged',

    },
    {
      title: priceTitle ? priceTitle : 'Цена',
      field: 'unLoggedPriceSum',
      minWidth: '200px',
      width: '200px',
      cellId: 'price_label',
      cellCssClass: 'mm-offer-table__cell-unLogged',
    },
  ];
});

const offerCharacteristics = computed<Array<IProductCharacteristic>>(() =>
  makeCharacteristics(props.productDetails?.specifications || []),
);

const unitName = computed(() => props.productDetails?.unit?.unitOkeiName);

const currentExpandedRowId = ref<number | null>((props.sortedOffersItems || [])[0]?.id);
const currentExpandedRowIndex = ref<number>(0);

const sortFieldPriceWithDelivery = computed(
  () => props.offersSortState?.field === EOfferPriceType.CostWithDelivery,
);

const sortFieldPriceWithVatAndDelivery = computed(
  () => props.offersSortState?.field === EOfferPriceType.CostWithVatAndDelivery,
);

function getAdditionalRowClass(row: IProductResultedOffer) {
  if (declinedItemsMap.get(row?.id)) {
    return 'mm-table__display_label no-divider';
  }

  return isDisplayRowLabel(row) ? 'mm-table__display_label' : undefined;
}

function isDisplayRowLabel(row: IProductResultedOffer): boolean {
  return row.bestPrice || (row.bestDelivery && isUserLoggedIn.value && row.available) || !!declinedItemsMap.get(row.id);
}

function updateFieldName(sort?: ITableSort | null): string {
  if (sort?.field === EOfferPriceType.PriceSum) {
    return currentOverallPrice.value?.type || '';
  }

  return sort?.field === EProductSortField.DeliveryPrice || sort?.field === EProductSortField.MaxDeliveryDays
    ? EProductSortField.MaxDeliveryDays
    : currentOverallPrice.value.type;
}

function onSortOffersChanged(sort: ITableSort | null): void {
  if (!sort) {
    offersSortState.value = null;
    return;
  }
  offersSortState.value = { ...sort, field: updateFieldName(sort) };
}

function makeCharacteristics(characteristics: Array<IProductSpecification>): Array<IProductCharacteristic> {
  return characteristics.map<IProductCharacteristic>((characteristic) => ({
    label: characteristic.name,
    value: characteristic.value,
  }));
}

function onChangeMobileSort() {
  currentMobileSortPrice.value = currentMobileSortPrice.value === ESortState.Asc ? ESortState.Desc : ESortState.Asc;
  onSortOffersChanged({ field: updateFieldName(offersSortState.value), order: currentMobileSortPrice.value });
}

function onRowClick({ row }: ITableRowClickEvent) {
  if (currentExpandedRowId.value === row?.id) {
    currentExpandedRowId.value = null;
    return;
  }
  currentExpandedRowId.value = row?.id;
}

function getTableRowShadowStyle(rowIndex: number, elementsRefs: Array<HTMLElement>): StyleValue | undefined {
  const values = TableUtils.getRowShadowValues(rowIndex, elementsRefs);
  if (!values) {
    return;
  }

  values.height -= 8; // высота эффекта ::after для разрыва между оферами
  return TableUtils.mapRowShadowValuesToStyle(values);
}

function setDeclinedItemsMap(): void {
  if (!props.declinedItems) {
    declinedItemsMap.clear();
    return;
  }

  props.declinedItems?.forEach((item) => {
    item.offers?.forEach((offer) => {
      declinedItemsMap.set(offer.offerId, offer.message?.[0]?.meta);
    });
  });
}

function onSelectPriceOption(action: IAction<string | number>) {
  const foundOption = props.selectCalcOptions?.find((option) => option.id === action.id);
  if (foundOption) {
    emits('update:selectedCalcType', foundOption);
  }
}

watchSubscription.add(
  watch(
    () => props.selectedCalcType,
    () => onSortOffersChanged(offersSortState.value ?? null),
  ),

  watch([props.sortedOffersItems, currentExpandedRowId], () => {
    currentExpandedRowIndex.value = (props.sortedOffersItems || []).findIndex((offer) => offer.id === currentExpandedRowId.value) ?? -1;
    if (currentExpandedRowIndex.value === -1) {
      currentExpandedRowId.value = null;
    }
  }),

  watch(
    () => props.declinedItems,
    () => setDeclinedItemsMap(),
    {
      immediate: true,
      deep: true,
    },
  ),
);

useSSRUnsubscribeWatch(watchSubscription);

onMounted(() => {
  setDeclinedItemsMap();
});
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-product-table {
  &:deep(.mm-table__table) {
    overflow: initial;
  }
}

:deep(.text-right) {
  div {
    text-align: right;

    div {
      text-align: right;
    }
  }
}

.no-offers-block {
  width: 100%;
  height: 272px;
  background-color: $light-gray;
  border-radius: 8px;
  padding: 24px 32px 32px;
  position: relative;

  .mm-caption-regular-m {
    line-height: 20px;
    margin-bottom: 60px;
  }

  .mm-headline-4 {
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .btn {
    width: 247px;
  }
}

.mm-product-offers {
  :deep(.popup-actions) {
    z-index: 10;
  }

  .mm-table--not-pagination {
    padding-bottom: 0;
  }

  &.safari {
    :deep(.mm-table .mm-table__body:not(:root:root)) {
      &::before {
        content: none;
      }
    }
  }

  :deep(.mm-table) {
    padding-bottom: 0;
    box-shadow: none;
    margin-bottom: 15px;

    .mm-table-settings {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 16px;
    }

    .mm-offers-expanded > td {
      padding-left: 24px;

      &:first-child {
        padding-top: 20px;
        padding-right: 20px;
        vertical-align: top;
      }
    }

    .mm-table__display_label {
      & > tr.mm-table__row {
        & > td.mm-table__data-cell {
          padding-top: 32px;
        }
      }
    }

    .mm-table__body {
      position: relative;

      &:hover {
        :deep(.btn),
        .mm-product-action-buttons {
          z-index: 4;
        }

        .mm-product-action-buttons {
          position: relative;
        }
      }

      tr {
        background-color: transparent;

        td {
          background-color: $table-separated-row-bg;

          &[colspan='2'] {
            width: 100%;
          }
        }
      }

      tr.expanded.mm-table__row,
      .mm-offers-expanded {
        td {
          background-color: $white;
        }
      }

      &.mm-table__display_label.expanded {
        &:has(.mm-basket-warning-row.mm-basket-warning-row--simple) {
          .mm-table_expanded-divider__row {
            display: none;
          }
        }

        &.no-divider {
          .mm-table_expanded-divider__row {
            display: none;
          }

          td {
            border-bottom: none;
          }

          .mm-offers-expanded {
            td {
              border-bottom: 1px solid $gray-200;

              &:first-child {
                border-left: 1px solid $gray-200;
              }

              &:last-child {
                border-right: 1px solid $gray-200;
              }
            }
          }
        }

        .offers-table--declined-item {
          &__inner {
            padding-left: 24px;
            padding-right: 24px;
            border-left: 1px solid $gray-200;
            border-right: 1px solid $gray-200;
            background-color: $text-white;
            width: 100%;

            span > span > span {
              font-weight: 500;
            }
          }
        }
      }

      tr.expanded {
        td {
          border-top: 1px solid $gray-200;

          &:first-child {
            border-left: 1px solid $gray-200;
          }

          &:last-child {
            border-right: 1px solid $gray-200;
          }
        }

        & + .mm-table_expanded-divider__row {
          td {
            border-left: 1px solid $gray-200;
            border-right: 1px solid $gray-200;
          }

          & + .mm-offers-expanded {
            td {
              border-bottom: 1px solid $gray-200;

              &:first-child {
                border-left: 1px solid $gray-200;
              }

              &:last-child {
                border-right: 1px solid $gray-200;
              }
            }
          }
        }
      }

      &:hover {
        box-shadow: none;

        &::before {
          box-shadow: 0 2px 6px $shadow;
          z-index: 2;
        }

        tr.mm-table__row {
          border-radius: 8px;
        }
      }

      &.mm-table__body--show-more {
        &::before {
          opacity: 0;
        }

        &:hover {
          &::before {
            z-index: 0;
          }
        }
      }
    }

    .mm-table__row--show-more {
      td {
        padding: 0;
        border-radius: 8px !important;
      }
    }
  }

  .price--sm {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .offers-table-suffix {
    color: $light-green;
    font-size: 12px;
    line-height: 16px;

    &__label {
      text-transform: lowercase;
      min-width: 70px;
    }
  }

  .offers-table-popup {
    margin-left: 6px;

    .mm-headline-5 {
      color: $white;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      margin-bottom: 8px;
    }

    .mm-offer {
      &-terms {
        display: flex;
        flex-direction: column;
      }

      &-term {
        &:not(&:first-child) {
          margin-top: 16px;
        }
      }
    }

    :deep(.product-characteristic) {
      .mm-product-characteristic-label {
        color: $light-green;
        background-color: $text-black;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }

      .mm-product-characteristic-value {
        color: $white;
        background-color: $text-black;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
    }

    :deep(.mm-tooltip.mm-tooltip--base) {
      .popper {
        font-size: 14px;
        border-radius: 6px;
        padding: 12px;
        max-width: 500px;
      }
    }
  }

  .offers-table-title {
    padding: 5px 32px;
    text-align: center;
    width: 100%;
    font-size: 14px;
    color: $light-green;
    margin-bottom: 27px;
  }

  :deep(.offers-table__label) {
    position: absolute;
    left: -24px;
    top: -32px;
    width: max-content;
    white-space: nowrap;
    font-size: 12px;
  }

  :deep(.mm-table--theme-separated) {
    border-radius: 8px;

    .mm-table__head > tr > th {
      padding: 15px 19px;
    }

    .mm-table__body.highlighted .mm-table_expanded-divider__row td {
      border-left: 2px solid $color-accent !important;
      border-right: 2px solid $color-accent !important;
    }

    .mm-table__body.highlighted .mm-offers-expanded {
      td {
        border-bottom: 2px solid $color-accent !important;
      }

      td:first-child {
        border-left: 2px solid $color-accent !important;
      }

      td:last-child {
        border-right: 2px solid $color-accent !important;
      }
    }

    .mm-table__body:not(.expanded).highlighted > tr > td.mm-table__data-cell {
      border-bottom: 2px solid $color-accent !important;

      & + .mm-table__action-cell {
        border-bottom: 2px solid $color-accent !important;
      }
    }

    .mm-table__body.highlighted > tr > td.mm-table__data-cell {
      border-top: 2px solid $color-accent;

      &:first-child {
        border-left: 2px solid $color-accent;
      }

      & + .mm-table__action-cell {
        border-top: 2px solid $color-accent;
        border-right: 2px solid $color-accent;
      }
    }

    .mm-table__body > tr > td.mm-table__data-cell {
      padding: 20px;
      padding-left: 24px;
      font-weight: 400;
      vertical-align: baseline;
      background-color: $white;
      border-top: 1px solid $gray-200;
      width: 20%;

      &:first-child {
        border-left: 1px solid $gray-200;
      }

      & + .mm-table__action-cell {
        border-top: 1px solid $gray-200;
        border-right: 1px solid $gray-200;
        background-color: $white;
        width: 20%;

        .mm-table__expand-btn {
          background: $btn-primary-bg;
          border-color: transparent;

          svg {
            path {
              fill: $white;
            }
          }
        }
      }
    }

    .mm-table__body > tr > td.mm-table__data-cell.mm-offer-table__cell-unLogged {
      vertical-align: middle;
    }

    .mm-table__body.expanded > tr > td.mm-table__data-cell + .mm-table__action-cell .mm-table__expand-btn {
      background: $white;
      border-color: $gray-200;

      svg {
        path {
          fill: $text-dark-green;
        }
      }
    }
  }

  .mm-price {
    &__value {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__unit {
      color: $light-green;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  :deep(.mm-offer-table__cell-price) {
    white-space: nowrap;
  }
}

:deep(.unLogged) {
  background: $color-gray-dark;
  border-radius: 8px;
  height: 14px;
}

:deep(.unLogged:not(:last-child)) {
  margin-bottom: 6px;
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .mm-product-offers {
    :deep(.offers-table__label) {
      left: -9px;
      top: -28px;
    }

    :deep(.mm-table) {
      .mm-table__action-cell {
        text-align: right;

        .mm-table__expand-btn {
          display: inline-flex;
        }
      }

      .mm-table__body > tr > td.mm-table__data-cell {
        padding: 16px 20px 16px 12px;

        &:nth-child(4) {
          padding-left: 0;
        }

        &:nth-child(4) {
          padding-left: 35px;
        }
      }

      .mm-table__display_label {
        &.expanded {
          &:has(.offers-table--declined-item) {
            .mm-table_expanded-divider__row {
              display: none;
            }
          }

          .offers-table--declined-item {
            &__inner {
              padding-left: 16px !important;
              padding-right: 16px !important;
              background-color: $text-white;
              width: 100%;
            }
          }
        }

        & > tr.mm-table__row {
          & > td.mm-table__data-cell {
            padding-top: 28px;
          }
        }
      }
    }

    :deep(.mm-table__table) {
      .mm-table__head > tr > th {
        padding: 7px 0 7px 7px;

        &:nth-child(4) {
          > div {
            padding-left: 22px !important;
          }
        }

        .mm-table__header-row-content--sortable {
          padding-left: 5px !important;
        }
      }

      .mm-offers-expanded > td {
        padding: 24px 16px 32px;

        &:first-child {
          padding-top: 24px;
        }

        .mm-offers-expanded__left {
          margin-right: -39px;
        }

        .mm-offers-expanded__right {
          padding-left: 21px;
        }
      }

      .mm-offers-expanded {
        &__link {
          margin-bottom: 43px;
        }

        &__action-breadcrumbs {
          margin-top: 6px;
        }

        .price__main-info {
          font-size: 28px;
        }
      }

      .offers-table__label {
        padding: 1px 12px 3px;
        white-space: nowrap;
      }
    }

    :deep(.mm-table) {
      .mm-offers-expanded > td:first-child {
        padding-right: 80px;
      }
    }

    :deep(.offers-table__label) {
      max-height: 20px;
    }
  }
}

@media only screen and (max-width: 767px) {
  :deep(.mm-product-offers .offers-table__label) {
    left: 3px;
    top: 3px;
  }

  :deep(.mm-table) {
    display: flex;
    flex-direction: column;
    background-color: $light-gray;

    .mm-table__table {
      display: flex;
      flex-direction: column;
      padding: 0 4px;
      padding-bottom: 4px;
    }

    .mm-table__header {
      display: block;
    }

    .mm-table-sort--mobile {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      & .mm-table-sort-inner--mobile {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;
        padding: 8px;
      }

      &-active {
        rect {
          fill: $link;
        }
      }

      &-default {
        transform: rotate(0deg) !important;
      }

      .sort-filter {
        font-size: 12px;
        line-height: 16px;
        padding-left: 8px;
        height: 28px;
        display: flex;
        align-items: center;
        color: #214237;
      }

      svg {
        width: 20px;
        height: 20px;
        transform: rotate(180deg);
      }
    }

    .mm-table__header-row {
      display: none;
    }

    .mm-table__body {
      display: block;
      margin-bottom: 4px;

      &::before {
        height: 100%;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &::after {
        display: none;
      }

      &.mm-table__display_label.expanded {
        &:has(.offers-table--declined-item) {
          .mm-table_expanded-divider__row {
            display: none;
          }

          .mm-offers-expanded {
            td {
              &:first-child {
                border-bottom: none;
              }

              &:last-child {
                border-right: none !important;
                border-bottom-right-radius: 8px;
              }
            }
          }
        }

        .offers-table--declined-item {
          &__inner {
            padding-left: 12px !important;
            padding-right: 12px !important;
            background-color: $text-white;
            width: 100%;
          }
        }

        &.no-divider {
          .mm-offers-expanded {
            .mm-offers-expanded__container {
              border-bottom: none !important;
            }

            td {
              &:nth-child(3) {
                border-right: none !important;
                border-top-left-radius: 0 !important;
                border-top-right-radius: 0 !important;
              }
            }
          }
        }
      }

      .mm-table__row {
        display: flex;
        flex-direction: column;
        padding-top: 14px;
        padding-left: 12px;
        padding-bottom: 12px;
        background-color: $text-white-6 !important;
        border-radius: 8px;

        td {
          padding: 0;
        }

        &.expanded {
          background-color: $white !important;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;

          & + .mm-offers-expanded {
            padding-top: 0;

            & > td:first-child {
              padding: 16px 12px;

              .mm-offers-expanded__left {
                margin-bottom: 0;
              }

              & + td {
                padding: 0 12px 20px;
                border-bottom-left-radius: 8px;
              }
            }
          }
        }

        &.mm-offers-expanded {
          background-color: $white;
        }

        & + tr {
          display: flex;
          flex-direction: column;
        }

        td.mm-table__action-cell {
          background-color: transparent !important;
          padding-right: 0;
        }

        &.mm-table__row--show-more {
          height: 40px;
          padding: 0;
          background-color: transparent !important;
          border-radius: 6px;
          margin: 8px 0 12px;

          td {
            padding: 0;
            height: 40px;
            border: none;
            background-color: transparent;

            > div {
              background-color: transparent;
              box-shadow: none;
            }

            .btn {
              background-color: transparent !important;
              height: 40px;
            }
          }
        }
      }

      .mm-offers-expanded__left {
        flex-basis: auto;
      }

      .offer__mobile {
        &.top-p {
          padding-top: 15px;

          & + .mm-table__action-cell {
            top: 32px;
          }
        }

        &-price {
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          margin-bottom: 2px;
          align-items: center;

          .offers-table-popup {
            display: inline-flex;
            margin-left: 8px;
            z-index: 55;

            > div {
              height: 20px;

              svg {
                vertical-align: unset;
              }
            }
          }
        }

        &-price-sm {
          display: inline-block;
          font-size: 12px;
          color: $text-light-green;
          font-weight: 400;
          margin-bottom: 13px;
        }

        &-list {
          list-style: none;
          margin: 0;
          padding: 0;
        }

        &-item {
          font-size: 12px;
          line-height: 16px;
          color: $text-dark-green;
          margin-bottom: 4px;

          &:last-child {
            margin-bottom: 0;
          }

          &.delivery {
            display: flex;
            align-items: center;

            div {
              margin-left: 4px;
            }

            .unavailable {
              display: flex;
              align-items: center;

              svg {
                margin-left: 4px;
              }
            }
          }

          .item-price {
            color: $text-light-green;
          }
        }
      }

      .mm-table__data-cell {
        padding: 0;
      }

      .mm-table__action-cell {
        position: absolute;
        top: 16px;
        right: 12px;
        padding-top: 0 !important;
      }
    }

    .mm-table__row--show-more {
      margin-top: 12px;
      padding-bottom: 12px;

      & > td {
        & > div {
          height: 40px;
          border-radius: 8px;
        }
      }
    }
  }

  :deep(.mm-table__expanded-container) {
    .mm-product-characteristic-wrapper {
      flex-basis: 100%;
    }

    .mm-product-characteristic-filler {
      background-image: none;
    }

    .mm-product-characteristic-value {
      text-align: right;
    }
  }

  .mm-product-offers {
    :deep(.mm-table) {
      .mm-table__body {
        border: 1px solid $gray-200;

        tr.expanded + .mm-table_expanded-divider__row + .mm-offers-expanded td:first-child {
          border-left-width: 0;
          border-bottom-width: 0;
        }

        tr.expanded + .mm-table_expanded-divider__row + .mm-offers-expanded td:last-child {
          border-right-width: 0;
          border-bottom-width: 0;
          border-bottom-right-radius: 8px;
        }

        tr.expanded {
          td {
            &:last-child {
              border: none;
            }
          }
        }
      }
    }

    :deep(.calculate-inputs) {
      .models-page__left,
      .models-page__right {
        .mm-body-regular-s {
          white-space: nowrap;
        }
      }
    }

    :deep(.mm-product-characteristic-wrapper) {
      &::before {
        opacity: 0;
      }
    }

    &.hidden-price {
      :deep(.mm-table) {
        .mm-table__body {
          .mm-table__row {
            display: block;
            padding: 0;

            .mm-table__data-cell {
              width: 100%;
            }
          }
        }

        .no-price {
          display: flex;
          min-width: 150px;
          align-items: center;
          justify-content: flex-end;
          gap: 4px;

          .offers-table-suffix {
            color: $text-dark-green;
            height: 20px;
            display: flex;
            align-items: center;
          }
        }

        .mm-table__table.mm-table--theme-separated {
          padding: 4px;

          .mm-table__body {
            &::after {
              content: unset;
            }

            .mm-table__row {
              .mm-table__data-cell {
                padding: 12px;
                font-size: 12px;
                line-height: 16px;
                border: unset;

                &:last-child {
                  border-bottom-right-radius: 8px !important;
                }
              }
            }
          }

          .mm-table__body.mm-table__body--show-more {
            margin-top: 8px;

            .mm-table__row.mm-table__row--show-more {
              margin: 0;
              display: flex;

              td {
                height: fit-content;

                > div {
                  padding: 10px 28px;
                  height: fit-content;
                  width: 100%;

                  > button {
                    height: fit-content;
                  }
                }
              }
            }
          }
        }
      }
    }

    .mm-price__value {
      max-width: 34%;
    }
  }
}

.offers-table__badge-wrapper {
  position: absolute;
  left: 4px;
  top: 4px;

  /* TODO вытащить в либу MBadge overrides */
  :deep(.MBadge) {
    border-radius: 8px 0 8px 0;
    color: $white;

    &:is([class$="--theme-blue"]) {
      background: linear-gradient(107.81deg, #2BB1FF 0%, #0085FF 95.12%);
    }
  }
}

:deep(td):has(.offers-table__badge-wrapper) {
  padding-top: 35px !important;
}

.asc {
  transform: rotate(0deg) !important;
}
</style>

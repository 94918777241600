import { Ref } from 'nuxt/dist/app/compat/capi';
import { IColumnHeader } from 'shared/models/table.model';
import { IBasketCategory, IBasketEntity } from 'models/basket.model';
import { BaseBasketCategory } from 'services/basket-services/base-basket/baseBasketCategory.service';
import { orderBy } from 'lodash-es';

export class BasketCategory extends BaseBasketCategory<IBasketEntity> {
  public tableData = ref<Array<IBasketEntity>>([]);
  public headerList: Ref<IColumnHeader[]> = ref<IColumnHeader[]>([
    { title: 'Наименование', field: 'name', width: '51%', cellCssClass: 'name-cell' },
    { title: 'Поставщик', field: 'supplierId', width: '13%', cellCssClass: 'supplier-id-cell', sortable: true },
    { title: 'Доставка', field: 'maxDeliveryDays', width: '6%', cellCssClass: 'max-delivery-cell' },
    { title: 'Кол-во', field: 'count', width: '19%', align: 'center', cellCssClass: 'count-cell' },
    { title: 'Цена за ед.', field: 'price', width: '10%', cellCssClass: 'price-cell' },
    { title: 'Сумма', field: 'cost', width: '5%', align: 'right', cellCssClass: 'cost-cell' },
    { title: '', field: 'action', width: '5%', cellCssClass: 'action-cell' },
  ]);

  private tableDataSortFunctionsMap = new Map<string, (tableData: Array<IBasketEntity>) => Array<IBasketEntity>>([
    ['supplierId', this.sortTableDataBySupplier],
  ]);

  public preparedTableData = computed(
    () => this.sortTableData(this.tableData.value),
  );

  constructor(basketCategoryData: IBasketCategory) {
    super();
    this.categoryName.value = basketCategoryData.name;
    this.tableData.value = basketCategoryData.items;
    this.limitInfo.limit.value = basketCategoryData.limit;
    this.limitInfo.delta.value = basketCategoryData.delta;
    this.limitInfo.spent.value = basketCategoryData.spent;
    this.limitInfo.limitInfoDetails.value = basketCategoryData.limitInfo;
    this.limitInfo.isOverPermissible.value = basketCategoryData.isOverPermissible;
  }

  public updateProductCount(index: number, count: number): void {
    if (!this.tableData.value[index]) {
      return;
    }
    this.tableData.value[index].count = count;
  }

  public getProduct(index: number): IBasketEntity | undefined {
    return this.tableData.value[index];
  }

  public deleteProduct(index: number): void {
    this.tableData.value.splice(index, 1);
  }

  private sortTableData(tableData: Array<IBasketEntity>): Array<IBasketEntity> {
    if (!this.tableSort.value) {
      return tableData;
    }

    return this.tableDataSortFunctionsMap
      .get(this.tableSort.value?.field)
      ?.call(this, tableData) || [];
  }

  private sortTableDataBySupplier(tableData: Array<IBasketEntity>): Array<IBasketEntity> {
    if (!this.tableSort.value) {
      return tableData;
    }

    return [
      ...(orderBy(
        tableData.filter((item) => !!item && item.catalogId > 0),
        ['catalogId'],
        [this.tableSort.value.order],
      )),
      ...tableData.filter((item) => !item || item.catalogId <= 0),
    ];
  }
}

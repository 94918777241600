import { AuthHelper } from 'utils/authHelper.util';
import { API, BFF } from '~/types';

export class PimOfferApiService { 
  public static async getOffers(params: BFF.Offers.GetOffersParams): Promise<API.PimOffer.ListOffersResponse> {
    return AuthHelper.fetch<API.PimOffer.ListOffersResponse>('/api/v1/offers/', {
      params,
    });
  }

  public static async getOffersFromAllPages(params: BFF.Offers.GetOffersParams): Promise<Array<API.PimOffer.OfferOut>> {
    return AuthHelper.fetch<Array<API.PimOffer.OfferOut>>('/api/v1/offers/get_offers_from_all_pages', {
      params,
    });
  }

  public static async getOffersFacets(params: BFF.Offers.GetOffersFacetsParams): Promise<API.PimOffer.OffersFacetsResponse> {
    return AuthHelper.fetch<API.PimOffer.OffersFacetsResponse>('/api/v1/offers/facets', {
      params,
    });
  }

  public static async batchUnhideOffers(body: API.PimOffer.BatchActionRequest): Promise<API.PimOffer.BatchActionResponse> {
    return AuthHelper.fetch<API.PimOffer.BatchActionResponse>('/api/v1/offers/batch_unhide', {
      method: 'POST',
      body,
    });
  }
}

<template>
  <div class="MainFirstScreen">
    <div class="MainFirstScreen__head">
      <h2 class="MainFirstScreen__title">
        Цифровая платформа <br>
        быстрых закупок
      </h2>
      <div class="MainFirstScreen__links">
        <!-- <MButton
          :theme="MM.EButtonThemes.White"
          :type="MM.EButtonTypes.Text"
          :icon-right="MM.EIcon.NotebookVideo"
          @click="onShowVideoAboutModal"
        >
          Онлайн-демонстрация
        </MButton> -->
        <MButton
          :theme="MM.EButtonThemes.White"
          :type="MM.EButtonTypes.Text"
          :icon-right="MM.EIconPath.ActionGalleryViewSvg"
          @click="navigateTo('/categories')"
        >
          Смотреть каталог
        </MButton>
      </div>
    </div>
    <div class="MainFirstScreen__MainAccordion">
      <MainAccordion />
    </div>
    <div class="MainFirstScreen__MainBrands">
      <MainBrands />
    </div>
  </div>
</template>

<script setup lang="ts">
import { MM } from 'types';
import MainAccordion from './MainAccordion.vue';
import MainBrands from './MainBrands.vue';
import { useHeaderStore } from '~/store/Header.store';
import WindowWidthManager from 'shared/services/windowWidth.service';
// TODO временно убрали видео
// import VideoAboutModal from 'components/modals/VideoAboutModal.vue';
// import ModalManager from 'shared/services/modalManager.service';

const { isDesktop, isLargeDesktop } = WindowWidthManager.getAdaptivaRefs();

const headerStore = useHeaderStore();
const headerHeight = ref('0px');
const headerOffsetTop = ref('0px');

watchEffect(() => {
  if (headerStore.state.height !== null && headerStore.state.height !== null) {
    headerHeight.value = headerStore.state.height + 'px';
    headerOffsetTop.value = headerStore.state.offsetTop + 'px';
  } else {
    if (isDesktop.value || isLargeDesktop.value) {
      headerHeight.value = useHeaderStore().state.isUserBarActive ? '165px' : '97px';
    } else {
      headerHeight.value = '68px';
    }
  }
});

// TODO временно убрали видео

// const videoAboutModalName = 'videoAboutModal';
// const modalManager = process.client ? inject<ModalManager>(ModalManager.getServiceName()) : undefined;

// function onShowVideoAboutModal(): void {
//   modalManager?.show({
//     component: VideoAboutModal,
//     bind: {
//       name: videoAboutModalName,
//     },
//     on: {
//       close() {
//         modalManager.hide(videoAboutModalName);
//       },
//       'update:modelValue'() {
//         modalManager.hide(videoAboutModalName);
//       },
//     },
//   });
// }
</script>

<style lang="scss" scoped>
.MainFirstScreen {
  background-color: $color-green-extra-dark;
  padding: 60px var(--container-offset-X) 60px;
  display: flex;
  flex-direction: column;
  grid-gap: 60px;
  position: relative;
  z-index: 1;
  border-radius: 12px;

  &::before {
    content: '';
    display: block;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -30%);
    position: absolute;
    pointer-events: none;
    z-index: -1;
    width: 760px;
    height: 840px;
    filter: blur(200px);
    background-image: $color-green-gradient;
    border-radius: 50%;
    opacity: 0.1;
  }

  &__head {
    color: $color-white;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    grid-gap: 20px;
  }

  &__title {
    @include text-m48;
    margin-bottom: 0;
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 12px 40px;

    :deep(.MButton__text) {
      font-weight: 400;
    }

    :deep(.MButton:hover) {
      color: $color-green;
    }
  }

  @include maxw(1599) {
    &__MainBrands {
      margin-top: -20px;
    }
  }
  @include maxw(1279) {
    padding-bottom: 32px;
    grid-gap: 0;

    &__head {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__MainAccordion {
      margin-top: 24px;
      margin-bottom: 60px;
    }
  }

  @include maxw(767) {
    grid-gap: 24px;

    &__MainAccordion {
      margin-top: 0;
      margin-bottom: 48px;
    }
  }
}

/* Отдельно управление позиции под шапкой  */
.MainFirstScreen {
  --header-height: v-bind(headerHeight);
  --height: calc(480px + var(--header-height));
  margin-top: calc(8px - var(--header-height));
  padding-top: calc(var(--header-height) + 60px - 8px);
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    z-index: 4;
    left: 0;
    right: 0;
    background-color: $color-green-dark-muted;
    height: 1px;
    top: calc(var(--header-height) - 8px);
  }

  @include maxw(1279) {
    margin-top: calc(4px - var(--header-height));
    padding-top: calc(var(--header-height) + 48px - 4px);
    &::after {
      top: calc(var(--header-height));
    }
  }

  @include maxw(767) {
    padding-top: calc(var(--header-height) + 30px - 4px);
  }
}
</style>

import { EUserGroup } from 'shared/enums/user/userGroup.enum';
import { IGroupMenuLinkItem } from 'shared/models/groupMenuLinkItem.model';
import { SUPPLIER_HEADER_LINKS, CLIENT_HEADER_LINKS } from 'constants/layouts/header.const';
import IMenuLinkItem from 'shared/models/linkItem.model';
import { SupplierHelper } from './supplierHelper.util';
import { UserHelper } from './userHelper.util';
import FeatureFlagsHelper from 'shared/services/featureFlags/featureFlagsHelper.service';
import { useUserStore } from 'store/user.store';

export class MenuHelper {
  public static isActive(link: IMenuLinkItem, currentRoute = useRoute()?.fullPath) {
    if (!link?.activeOn?.length) {
      return false;
    }
    return link?.activeOn?.some((route) => currentRoute?.includes(route));
  }

  private static isLinkDisabledForSupplier(
    link: IGroupMenuLinkItem,
    pathsToAllow: Array<string>,
    pathsToAllowIfQualificationSigned: Array<string>,
  ) {
    if (SupplierHelper.isQualificationCompleted) {
      return false;
    }

    pathsToAllow = [...pathsToAllow, ...(SupplierHelper.isQualificationSigned ? pathsToAllowIfQualificationSigned : [])];
    return !pathsToAllow.includes(link.path);
  }

  public static getMenuLinks(
    group: Array<EUserGroup>,
    pathsToExclude: Array<string> = [],
    filterAction = false,
  ): Array<IGroupMenuLinkItem> {
    return this.getHeaderLinks(group, pathsToExclude, filterAction);
  }

  public static getHeaderLinks(
    group: Array<EUserGroup>,
    pathsToExclude: Array<string> = [],
    filterAction = true,
    internalUser = false,
  ): Array<IGroupMenuLinkItem> {
    let currentUserMenu: Array<IGroupMenuLinkItem> = UserHelper.isSupplier ? SUPPLIER_HEADER_LINKS : CLIENT_HEADER_LINKS;

    if (filterAction) {
      currentUserMenu = currentUserMenu?.filter((menuItem) => !menuItem.actionFunction);
    }

    currentUserMenu = MenuHelper.filterUserMenuByFeatures(currentUserMenu, internalUser);

    return currentUserMenu
      .filter((headerItem) => (
        headerItem.groups.some((headerItemGroup) => group?.includes(headerItemGroup)) &&
        (!headerItem.guardFunction || headerItem.guardFunction?.()) &&
        !pathsToExclude?.includes(headerItem.path)
      ))
      .map((headerItem) => ({
        ...headerItem,
        disabled: !headerItem.actionFunction && UserHelper.isSupplier
          ? this.isLinkDisabledForSupplier(
            headerItem,
            ['/supplier/qualifications/intro/', '/supplier/settings/'],
            ['/supplier/catalogs/'],
          )
          : false,
      }));
  }

  private static filterUserMenuByFeatures(
    currentUserMenu: Array<IGroupMenuLinkItem>,
    internalUser = false,
  ): Array<IGroupMenuLinkItem> {
    if (!UserHelper.isClient) {
      return currentUserMenu.filter((menuItem) => {
        if (menuItem.path?.startsWith('/supplier/shipments')) {
          /* Показывать раздел "Отгрузки", если включен флаг */
          return FeatureFlagsHelper.featureSupplierPartialShipmentFlag;
        }

        return true;
      });
    }

    return currentUserMenu.filter((menuItem) => {
      if (menuItem.label === 'Помощь') {
        /* Показывать раздел "Помощь" для всех клиентов, кроме Полюс */
        return !UserHelper.isPolus;
      }

      if (menuItem.path?.startsWith('/client/analytics')) {
        /* Показывать раздел "Аналитика", если включен флаг на клиенте или для "внутренних" (из под VPN) пользователей */
        const userStore = useUserStore();
        return !!userStore.clientOptions?.isAnalyticsAvailable || internalUser;
      }

      return true;
    });
  }
}

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ProviderMappingSchema = {
    domain: string;
    clientId: number;
    provider: ProviderMappingSchema.provider;
};
export namespace ProviderMappingSchema {
    export enum provider {
        POLYUS = 'POLYUS',
        AIM = 'AIM',
        NLMK = 'NLMK',
    }
}


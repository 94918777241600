<template>
  <div
    v-if="news && news.items"
    class="NewsFixedAnnounce"
    :style="{
      '--loader-duration': loaderDuration + 'ms',
    }"
  >
    <MButton
      class="NewsFixedAnnounce__close"
      :type="EButtonTypes.Text"
      :theme="EButtonThemes.TextMain"
      @click="$emit('close')"
    >
      <MIcon :src="MM.EIcon.Close" />
    </MButton>

    <MSlider
      :swiper="{
        speed: SLIDER_SPEED,
        slidesPerView: 1,
        effect: 'fade',
        fadeEffect: { crossFade: true },
        autoplay: {
          delay: SLIDER_AUTOPLAY_DELAY,
        },
      }"
      @slider-init="onSliderInit"
    >
      <MSliderItem
        v-for="item in news.items"
        :key="item.id"
        class="NewsFixedAnnounce__slide"
      >
        <div class="NewsFixedAnnounce__slide-img">
          <MNuxtImg :src="newsService.getImage(item.image) || 'images/banner-no-items-icons.svg'" />
        </div>
        <div
          :key="loaderKey"
          class="NewsFixedAnnounce__slide-img-loader"
        />
        <div
          class="NewsFixedAnnounce__slide-title"
          @click="onNewsItemClick(item)"
        >
          {{ item.title }}
        </div>

        <div class="NewsFixedAnnounce__slide-info">
          <div
            v-for="ndx in item.tags?.length"
            :key="ndx"
            class="NewsFixedAnnounce__slide-tag"
            @click.stop="onNewsTagClick(item)"
          >
            {{ item.shortSourceUrl ? item.shortSourceUrl : item.tags?.[0]?.label }}
          </div>
          <div
            v-if="item.publicationDate"
            class="NewsFixedAnnounce__slide-date"
            v-text="DateFormat.default(item.publicationDate)"
          />
        </div>
      </MSliderItem>
    </MSlider>
    <div class="NewsFixedAnnounce__nav">
      <MSliderNavigation
        v-if="slider && news.items"
        is-loop
        :slider="slider"
        :type="MM.ESliderNavType.SmallArrowsAndDots"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import MNuxtImg from 'components/MNuxtImg/MNuxtImg.vue';
import { MM } from '~/types';
import { IStrapiNewsListResponse } from 'models/strapi/strapiNews.model';
import { NewsService } from 'services/news/news.service';
import { useRequestUrl } from 'composables/useRequestUrl';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { DateFormat } from 'shared/utils/dateFormat.util';
import type { Swiper } from 'swiper/types';
import { EButtonTypes, EButtonThemes } from '@maksmart/maksify/dist/types';
import { NewsApiService } from '~/services/api/newsApi.service';
import { IBasePagedResponse } from 'shared/models/baseApi.model';

const news = ref<IBasePagedResponse<IStrapiNewsListResponse>>();

async function loadNewsData() {
  await NewsApiService.getNews({
    pageSize: 4,
    page: 1,
    sectionId: 1,
    mainPage: true,
  })
    .then((res) => {
      news.value = res;
    })
    .catch((err) => {
      console.error(err);
    });
}

onMounted(() => {
  loadNewsData();
});

defineEmits<{
  (e: 'close'): void;
}>();

/** Задержка между слайдерами */
const SLIDER_AUTOPLAY_DELAY = 5000;
const SLIDER_SPEED = 1000;

const newsService = new NewsService(useRequestUrl());

function onNewsItemClick(item: IStrapiNewsListResponse) {
  if (item.shortSourceUrl) {
    window.open(sanitizeUrl(item.sourceUrl), '_blank');
    return;
  }

  useRouter().push(`/news/${item.id}`);
}

function onNewsTagClick(item: IStrapiNewsListResponse) {
  if (item.shortSourceUrl) {
    window.open(sanitizeUrl(item.sourceUrl), '_blank');
    return;
  }

  navigateTo(`/news/?categoryId=${item.tags?.[0]?.id}`);
}

/** Инстанс слайдера */
const slider = ref<Swiper>();
/** Реактивный индекс текущего слайда */
const sliderActiveIndex = ref<number>(0);

/** Ключ элемента-лоадера */
const loaderKey = ref(0);

/** Длительность анимации лоадера */
const loaderDuration = computed(
  () => (loaderKey.value ? SLIDER_AUTOPLAY_DELAY + SLIDER_SPEED : SLIDER_AUTOPLAY_DELAY), // первый слайд без перехода
);

/** Обновляет реактивное значеие слацдера */
function updateSliderIndex() {
  sliderActiveIndex.value = slider.value?.activeIndex ?? 0;
}

/** Сбрасывает анимацию лоадера */
function resetLoader() {
  // меняет ключ лоадера, чтобы vue перерисовал элемент (посчитав его новым)
  loaderKey.value++;
}

/** Запускается при смене слайда */
function onSlideChange() {
  resetLoader();
  updateSliderIndex();
}

function onSliderInit(_slider: Swiper) {
  slider.value = _slider;

  // прослушиваем смену слайдов для сбросы анимации лоадера
  _slider.on('slideChange', onSlideChange);
}
</script>

<style lang="scss" scoped>
.NewsFixedAnnounce {
  --popup-indent: 28px;
  --popup-width: 240px;
  position: fixed;
  z-index: 33;
  right: var(--popup-indent);
  bottom: var(--popup-indent);

  width: 240px;
  background-color: $color-white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 0 10px rgba(black, 0.1);

  // 1720 - 1600 + 60*2 контейнер с ушами которые выпадают
  @media (min-width: calc(1720px + (var(--popup-indent) * 2 + var(--popup-width)) * 2)) {
    right: calc((100vw - 1720px) / 2 - var(--popup-indent) - var(--popup-width));
  }

  &__close {
    position: absolute;
    top: 12px;
    right: 12px;
    height: 20px;
    width: 20px;
  }

  &__slide {
    --img-size: 80px;
    --stroke-size: 5px;

    text-align: center;
    padding: var(--stroke-size) 0;

    &-img {
      @include cover;
      border-radius: 50%;
      width: var(--img-size);
      height: var(--img-size);
      background-color: $color-gray-light;
      margin: auto;
      margin-bottom: 20px;
      position: relative;
      z-index: 2;
    }

    // лоадер вокруг картинки
    &-img-loader {
      --angle: 0deg;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%);
      width: calc(var(--img-size) + var(--stroke-size) * 2);
      height: calc(var(--img-size) + var(--stroke-size) * 2);
      border-radius: 50%;
      background: $color-green-gradient;
      // управляемая углом маска
      mask: conic-gradient(black 0deg var(--angle), transparent var(--angle) 360deg);
      animation: animateAngle var(--loader-duration) linear;
    }

    @keyframes animateAngle {
      @for $i from 0 through 360 {
        #{$i / 360 * 100%} {
          --angle: #{$i}deg;
        }
      }
    }

    &-title {
      cursor: pointer;
      @include text-r14;
      @include clamp(3);
      transition: 0.2s;
      @include hover {
        color: $color-green;
      }
    }

    &-info {
      @include text-r12;
      margin-top: 4px;
      color: $light-green;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    &-tag {
      margin-right: 16px;
      position: relative;
      transition: 0.2s;
      cursor: pointer;

      @include hover {
        color: $color-green;
      }

      &:after {
        pointer-events: none;
        position: absolute;
        right: -9px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        height: 12px;
        width: 1px;
        display: block;
        background-color: $color-gray-dark;
      }
    }
  }

  &__nav {
    margin-top: 20px;
  }
}
</style>

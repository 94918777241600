export const list: Array<{
  /** Название региона */
  label: string;
  /** Описание РЦ */
  text: string;
  /** ID path региона */
  id: string;
  /** Позиция маркера в пикселях */
  position: {
    left: number;
    top: number;
  };
}> = [
  {
    label: 'Красноярский край',
    text: 'РЦ обеспечивает консолидированную доставку в 48 бизнес-единиц',
    id: 'region2',
    position: {
      left: 677,
      top: 436,
    },
  },
  {
    label: 'Московская обл.',
    text: 'Кратко',
    id: 'region13',
    position: {
      left: 176,
      top: 439,
    },
  },
  {
    label: 'Респ. Саха (Якутия)',
    text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat aperiam est, officiis modi soluta beatae architecto, aliquam excepturi ex numquam similique? Sed.',
    id: 'region20',
    position: {
      left: 923,
      top: 350,
    },
  },
  {
    label: 'Магаданская обл.',
    text: '',
    id: 'region15',
    position: {
      left: 1113,
      top: 274,
    },
  },
  {
    label: 'Волгоградская обл.',
    text: '',
    id: 'region9',
    position: {
      left: 146,
      top: 558,
    },
  },
  {
    label: 'Краснодарский край',
    text: '',
    id: 'region11',
    position: {
      left: 42,
      top: 575,
    },
  },
  {
    label: 'Липецкая обл.',
    text: '',
    id: 'region8',
    position: {
      left: 111,
      top: 469,
    },
  },
  {
    label: 'Кемеровская область - Кузбасс',
    text: '',
    id: 'region22',
    position: {
      left: 610,
      top: 620,
    },
  },
  {
    label: 'Ставропольский край',
    text: '',
    id: 'region10',
    position: {
      left: 82,
      top: 621,
    },
  },
  {
    label: 'Пермский край',
    text: '',
    id: 'region7',
    position: {
      left: 335,
      top: 472,
    },
  },
  {
    label: 'Мурманская обл.',
    text: '',
    id: 'region19',
    position: {
      left: 297,
      top: 242,
    },
  },
  {
    label: 'г. Санкт-Петербург',
    text: '',
    id: 'region1',
    position: {
      left: 160,
      top: 313,
    },
  },
  {
    label: 'Иркутская обл.',
    text: '',
    id: 'region5',
    position: {
      left: 796,
      top: 607,
    },
  },
  {
    label: 'Новосибирская обл.',
    text: '',
    id: 'region17',
    position: {
      left: 524,
      top: 621,
    },
  },
  {
    label: 'Тюменская обл.',
    text: '',
    id: 'region4',
    position: {
      left: 441,
      top: 559,
    },
  },
  {
    label: 'Приморский край',
    text: '',
    id: 'region16',
    position: {
      left: 1196,
      top: 634,
    },
  },
  {
    label: 'Свердловская обл.',
    text: '',
    id: 'region3',
    position: {
      left: 392,
      top: 515,
    },
  },
  {
    label: 'Хабаровский край',
    text: '',
    id: 'region12',
    position: {
      left: 1151,
      top: 537,
    },
  },
];

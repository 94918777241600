<template>
  <div class="HeaderUserBar">
    <LazyLayoutsDefaultCatalogButton
      :categories="categories"
      :is-button-disabled="isButtonDisabled"
      :oci-mode="ociMode"
      @on-mobile-menu-open="toggleMobileMenu"
      @on-popup-open="onPopupOpen"
      @on-popup-close="onPopupClose"
    />
    <LayoutsDefaultSearch
      :categories="categories"
      :placeholder="searchPlaceholder"
    />
    <LazyLayoutsDefaultSearchIcons
      :show-location-in-search="showLocationInSearch"
      :address="addressToShow"
      @click-go-to-basket="$emit('clickGoToBasket')"
    />
  </div>

  <LazyLayoutsDefaultCatalogPopup
    :categories="categories"
    :oci-mode="ociMode"
    @close-popup="onPopupClose"
  />
</template>

<script setup lang="ts">
import { EFetchKey } from 'enums/fetchKey.enum';
import { PimCatalogService } from 'services/api/pimCatalogApi.service';
import HeaderService from '~/services/header.service';
import { useHeaderStore } from '~/store/Header.store';
import { useBasis } from 'composables/useBasis';
import { useUserStore } from '~/store/user.store';
import BasesService from '~/services/basesManager.service';
import { UserHelper } from 'utils/userHelper.util';

const props = defineProps<{
  /** Режим работы, когда на маркетплейс заходят через свой SAP браузер НЛМК пользователи,
   * когда делают заказ. По сути у них там не обычный браузер,
   * а в их платформе открывается наша без всяких браузерных кнопок к которым мы привыкли,
   * и после окончания работы (оформления заказа в OCI корзине) их обратно редиректит в их систему.
   */
  ociMode?: boolean;
  /** id клиента */
  clientId?: number;
  /** Плейсхолдер для поискового инпута */
  searchPlaceholder?: string;
  /** Отображается ли адресс в строке поиска */
  showLocationInSearch?: boolean;
}>();

defineEmits<{
  (e: 'clickGoToBasket'): void;
}>();

const headerStore = useHeaderStore();
const userStore = useUserStore();
const basesManager = inject<BasesService>(BasesService.getServiceName());

const { authBasisId, unAuthRegionFiasId } = useBasis();

const { data: categories } = await useLazyAsyncData(
  EFetchKey.HeaderCategories,
  async () => {
    try {
      return (
        (
          await PimCatalogService.getCategories(
            3,
            unAuthRegionFiasId.value,
            undefined,
            undefined,
            props.ociMode ? undefined : props.ociMode,
            props.ociMode,
            props.ociMode,
            props.clientId,
            authBasisId.value,
          )
        )?.category?.subcategory || []
      );
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  { default: () => [] },
);

const headerService = new HeaderService();
const isButtonDisabled = ref(false);
const isMobileMenu = ref(false);

function onPopupOpen() {
  headerService.setCatalogOpened(true);
  headerStore.state.userBarSearchTypeOpened = false;

  headerStore.state.isCatalogOpened = true;
}

function onPopupClose() {
  headerStore.state.isCatalogOpened = false;
  headerService.setCatalogOpened(false);
}

function toggleMobileMenu() {
  isMobileMenu.value = !isMobileMenu.value;
}

const addressToShow = computed(() => {
  if (!userStore.isUserLoggedIn || UserHelper.isSupplier) {
    return basesManager?.city ? basesManager?.city?.replaceAll?.('"', '') : 'Укажите ваш город';
  }
  return basesManager?.base ? basesManager.base?.replaceAll?.('"', '') : 'Базис доставки';
});
</script>

<style lang="scss" scoped>
.HeaderUserBar {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: minmax(156px, 241px) minmax(646px, 1fr) max-content;
  /* после того как появятся иконки поставить макс 166px */
  margin-top: 12px;

  &--location {
    grid-template-columns: minmax(156px, 241px) minmax(646px, 1fr) auto;

    .search__icons {
      max-width: 404px;
      min-width: 70px;
    }
  }

  .search__field:-webkit-autofill,
  .search__field:-webkit-autofill:hover,
  .search__field:-webkit-autofill:focus {
    -webkit-text-fill-color: $text-black;
    -webkit-box-shadow: 0 0 0 1000px $text-white inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  @include maxw(1279) {
    grid-template-columns: 1fr;
    :deep(.search__navigation),
    :deep(.search__icons),
    :deep(.search__catalog) {
      display: none;
    }
  }

  @include maxw(767) {
    :deep(.search__wrap) {
      height: 48px;

      #search_input {
        padding-left: 12px;
      }

      .search-buttons__container {
        padding-top: 8px;
        padding-bottom: 8px;
        margin-right: 12px;
      }
    }

    :deep(.select-wrapper) {
      display: none;
    }
  }
}

.search__catalog {
  display: flex;
  background: $header-catalog-bg;
  padding: 16px 24px 16px 20px;
  color: $header-catalog-c;
  border-radius: 6px;
  margin-right: 4px;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  align-items: center;

  &:hover {
    background: $header-catalog-bg-h;
  }

  &--disabled {
    color: $text-disabled;
    background: $light-gray;

    &:hover {
      background: $light-gray;
    }

    svg {
      path {
        fill: $btn-primary-disabled-c;
      }
    }
  }

  svg {
    width: 24px;
    height: 24px;
    margin-left: auto;

    path {
      fill: $text-white;
    }
  }
}

.search__wrap {
  background: $header-search-bg;
  border: 1px solid $header-search-bc;
  border-radius: 6px;
  margin-right: 4px;
  display: flex;
  position: relative;
  height: 56px;
  z-index: 1;
  overflow: hidden;
}

.search__field {
  width: 100%;
  border-width: 0;
  font-size: 14px;
  line-height: 20px;
  padding-left: 8px;
  color: $header-search-c;

  &::placeholder {
    text-overflow: ellipsis;
  }

  &:placeholder-shown {
    text-overflow: ellipsis;
  }

  &[placeholder] {
    text-overflow: ellipsis;
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    text-overflow: ellipsis;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    text-overflow: ellipsis;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    text-overflow: ellipsis;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    text-overflow: ellipsis;
  }
}

.search-buttons__container {
  display: flex;
  margin-right: 20px;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 12px 0;

  button {
    background: transparent;
    border-color: transparent;
    height: 100%;

    &:nth-child(2) {
      border-left: 1px solid $header-links-item-bc;
      margin-left: 16px;
      padding-left: 16px;
    }
  }
}

.search__dropdown {
  display: flex;
  align-items: center;
  background: $header-search-dropdown-bg;
  border-radius: 4px;
  margin: 8px;
  padding: 9px 10px 10px 16px;
  font-size: 14px;
  line-height: 20px;
  color: $header-search-c;
  cursor: pointer;
  border: none;

  svg {
    margin-left: 8px;
  }
}
</style>

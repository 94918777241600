<template>
  <MModal
    class="fill-upd-lock-modal"
    title="Заполните реестр УПД"
    lock-scroll
    is-close-btn-hidden
    @click-outside="() => null"
    @close="() => null"
  >
    <template #content>
      <p>У вас есть незаполненные реализации за предыдущий квартал. Вам необходимо заполнить их до 23.01.2025.</p>
      <p>Предупреждаем, что с 20.01.2025 вам будет заблокирована возможность работы с новыми заказами до заполнения реестра УПД.</p>

      <div class="buttons">
        <MButton
          :size="MM.EButtonSizes.Large"
          full-width
          @click="onClickBtn"
        >
          Заполнить
        </MButton>
        <MButton
          :size="MM.EButtonSizes.Large"
          :theme="MM.EButtonThemes.Secondary"
          full-width
          @click="emit('close')"
        >
          Позже
        </MButton>
      </div>
    </template>
  </MModal>
</template>

<script setup lang="ts">
import { MM } from 'types';

const emit = defineEmits<{
  (e: 'fill'): void;
  (e: 'close'): void;
}>();

async function onClickBtn() {
  emit('fill');
  await navigateTo('/supplier/documents/upd-registry/');
}
</script>

<style lang="scss">
.m-modal.fill-upd-lock-modal {
  z-index: 99999;

  .m-modal__content {
    width: 550px;
  }
}
</style>

<style scoped>
.buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>

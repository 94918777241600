import { wait } from '../../utils/wait.util';
import { RETRY_OPTIONS, RETRY_REQUEST_OPTIONS } from '../../constants/retryRequest.const';
import { IPaginatedApiResponse, IRetryRequestOptions } from '../../models/apiHelper.model';
import { cloneDeep } from 'lodash-es';

export class ApiHelper {
  public static getParamArray(valueList?: Array<string | number>, field: string, isStartPoint = true): string {
    if (!valueList?.length) {
      return '';
    }

    const value = Array.isArray(valueList) ? valueList : [valueList];
    return `${isStartPoint ? '?' : '&'}${`${field}=${value.join(`&${field}=`)}`}`;
  }

  public static async retryRequest<T = unknown, R = Error>(
    handler,
    args: Array<unknown>,
    options?: Partial<IRetryRequestOptions<T, R>>,
    retryData = cloneDeep(RETRY_OPTIONS),
  ): Promise<T> {
    const _options = { ...RETRY_REQUEST_OPTIONS, ...options };
    const { isAbortRetryError, retryErrorTimeout, retryTimeout, isNeedRetry, maxErrorRetry } = _options;
    try {
      const response = await handler(...args);
      retryData.currentErrorRetry = 0;
      if (isNeedRetry?.(response)) {
        await wait(retryTimeout);
        return await ApiHelper.retryRequest<T, R>(handler, args, _options, retryData);
      }
      return response;
    } catch (e) {
      if (retryData.currentErrorRetry >= maxErrorRetry || isAbortRetryError?.(e)) {
        throw Error(e);
      }
      await wait(retryErrorTimeout);
      retryData.currentErrorRetry++;
      return await ApiHelper.retryRequest<T, R>(handler, args, _options, retryData);
    }
  }

  public static async fetchAllPages<T>(makeRequest: (nextPageToken?: string | null) => Promise<IPaginatedApiResponse<T>>): Promise<Array<T>> {
    const values: Array<T> = [];
    let savedNextPageToken: string | undefined | null;
    do {
      const res = await makeRequest(savedNextPageToken);
      savedNextPageToken = res.nextPageToken;
      values.push(...res.items);
    } while (savedNextPageToken);
    return values;
  }

  /**
   * isNeedRetry Колбэк-условие для повторного запроса.
   * Возвращая true - повторяет запрос. Возвращая false - не повторяет.
   * По умолчанию всегда возвращает true.
   * Пример:
   *   const job = await ApiHelper.pooling(
   *     () => ReadyGluingApi.getJobById({ jobId: jobInfo.id }),
   *     (response) => response.status === 'wait',
   *     { interval:1000, attempts: 10 }
   *   );
   * */
  public static async pooling<T>(
    sendRequestCb: () => Promise<T>,
    isNeedRetry: (response: T) => boolean = () => true,
    options: {
      interval: number;
      attempts: number;
    } = { attempts: 10, interval: 2000 },
  ): Promise<T> {
    let retryCount = 0;
    let response = undefined as T;
    await sendRequest();

    async function sendRequest() {
      response = await sendRequestCb();
      retryCount++;
      await wait(isNeedRetry(response) ? options.interval : 0);
      if (isNeedRetry(response) && retryCount < options.attempts) {
        await sendRequest();
      } else {
        return response;
      }
    }

    return response;
  }
}

export function formatPrice(
  price: number,
  isShowFractionPartForInt = false,
  fixFractionDigits = 2,
  sign = '₽',
  disabledRounding = false,
  hideSign = false,
  allowNegative = true,
): string {
  let internalPrice = (price ?? 0);
      internalPrice = internalPrice < 0 && !allowNegative ? 0 : internalPrice;
  const isInteger = Number.isInteger(internalPrice);
  const integerPart = Number(Math.trunc(internalPrice)).toLocaleString('ru');
  const fractionalPart = !isInteger || isShowFractionPartForInt
    ? disabledRounding
      ? Number(internalPrice).toString().split('.')?.[1] ?? ''
      : Number(internalPrice).toFixed(fixFractionDigits).split('.')?.[1] ?? ''
    : undefined;

  const priceSign = !hideSign ? ` ${sign}` : '';

  return !isInteger || isShowFractionPartForInt
    ? `${integerPart}${fractionalPart ? ',' + fractionalPart : ''}${priceSign}`
    : `${integerPart}${priceSign}`;
}

<template>
  <div class="CatalogCategoriesPromo">
    <div class="CatalogCategoriesPromo__sidebar">
      <h2 class="CatalogCategoriesPromo__title">
        Обширный <br>
        каталог товаров
      </h2>
      <div class="CatalogCategoriesPromo__text">
        Максмарт находится в постоянном контакте со своими партнерами и руководствуется обратной связью для улучшения продуктов и сервиса:
      </div>
      <div class="CatalogCategoriesPromo__benefits">
        <div
          v-for="(benefit, ndx) in benefits"
          :key="ndx"
          class="CatalogCategoriesPromo__benefits-item"
        >
          <div class="CatalogCategoriesPromo__benefits-counter">
            <MCounter
              :end="benefit.counter"
              :step="100"
            /> +
          </div>
          <div v-html="benefit.label" />
        </div>
      </div>
    </div>

    <div class="CatalogCategoriesPromo__list">
      <div
        v-for="(item, ndx) in categories"
        :key="ndx"
        class="CatalogCategoriesPromo__item"
      >
        <div class="CatalogCategoriesPromo__item-img">
          <img :src="item.src">
        </div>
        <div class="CatalogCategoriesPromo__item-title">
          <div>{{ item.label }}</div>
        </div>
        <div class="CatalogCategoriesPromo__item-additional">
          <div class="CatalogCategoriesPromo__item-sublist">
            <div
              v-for="(subCategory, subIndex) in item.subCategories"
              :key="subIndex"
            >
              {{ subCategory }}
            </div>
          </div>

          <div class="text-m12 CatalogCategoriesPromo__item-counter">
            + {{ item.subCategoriesCounter }} категории
          </div>
        </div>
      </div>

      <div class="CatalogCategoriesPromo__item CatalogCategoriesPromo__item--btn">
        <div class="CatalogCategoriesPromo__item-img">
          <MIcon :src="MM.EIconPath.NavigationMainArrowSvg" />
        </div>
        <div class="CatalogCategoriesPromo__item-title">
          Каталог <br>товаров
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import MCounter from '~/components/MCounter.vue';
import { MM } from '~/types';

const benefits: Array<{
  label: string;
  counter: number;
  counterString: string;
}> = [
  {
    label: 'Проверенных поставщиков <br>товара по всей России',
    counter: 2500,
    counterString: '2 500 +',
  },
  {
    label: 'Уникальных предложений <br>поставщиков',
    counter: 3500000,
    counterString: '3 500 000 +',
  },
  {
    label: 'Товарных категорий <br>в каталоге',
    counter: 3000,
    counterString: '3 000 +',
  },
];

const categories: Array<{
  label: string;
  src: string;
  subCategories: string[];
  subCategoriesCounter: number;
}> = [
  {
    label: 'Инструмент',
    src: '/images/categories/category-1.png',
    subCategories: ['Ручной инструмент', 'Электроинструмент', 'Пневмоинструмент', 'Расходные материалы'],
    subCategoriesCounter: 357,
  },
  {
    label: 'Канцелярские товары',
    src: '/images/categories/category-2.png',
    subCategories: ['Письменные принадлежности', 'Бумажная продукция', 'Офисные принadлежности', 'Печатная продукция'],
    subCategoriesCounter: 46,
  },
  {
    label: 'Хозяйственные товары',
    src: '/images/categories/category-3.png',
    subCategories: ['Бытовая химия', 'Инвентарь для уборки', 'Текстиль', 'Гигиенические средства'],
    subCategoriesCounter: 107,
  },
  {
    label: 'Строительство и ремонт',
    src: '/images/categories/category-4.png',
    subCategories: ['Отделочные материалы', 'Расходные строительные материалы', 'Изоляционные материалы', 'Лакокрасочные материалы'],
    subCategoriesCounter: 215,
  },
  {
    label: 'Электрика',
    src: '/images/categories/category-5.png',
    subCategories: ['Контрольно-измерительные приборы', 'Освещение', 'Системы безопасности', 'Источники питания'],
    subCategoriesCounter: 70,
  },
  {
    label: 'Крепеж и фурнитура',
    src: '/images/categories/category-6.png',
    subCategories: ['Метизы', 'Монтажные ленты', 'Профили', 'Специальный крепеж'],
    subCategoriesCounter: 36,
  },
  {
    label: 'Грузоподъемное оборудование',
    src: '/images/categories/category-7.png',
    subCategories: ['Такелаж', 'Стропы', 'Домкраты', 'Лебедки'],
    subCategoriesCounter: 47,
  },
  {
    label: 'Автотовары',
    src: '/images/categories/category-8.png',
    subCategories: ['Шины и диски', 'Автозапчасти', 'Авиоинструмент', 'Аксессуары'],
    subCategoriesCounter: 143,
  },
  {
    label: 'СИЗ',
    src: '/images/categories/category-9.png',
    subCategories: ['Спецодежда', 'Защита дыхания', 'Защита рук', 'Рабочая обувь'],
    subCategoriesCounter: 40,
  },
  {
    label: 'Масла и смазки',
    src: '/images/categories/category-10.png',
    subCategories: ['Смазка проникающая', 'Масло моторное', 'Смазка силиконовая', 'Масло гидравлическое'],
    subCategoriesCounter: 11,
  },
  {
    label: 'Пожарное оборудование',
    src: '/images/categories/category-11.png',
    subCategories: ['Огнетушители', 'Пожарные шкафы', 'Оповещатели', 'Пожарные рукава'],
    subCategoriesCounter: 44,
  },
  {
    label: 'Складское оборудование',
    src: '/images/categories/category-12.png',
    subCategories: ['Металлические шкафы', 'Ящики, лотки, боксы', 'Тележки', 'Упаковочное и фасовочное оборудование'],
    subCategoriesCounter: 58,
  },
  {
    label: 'Сантехника и инженерные системы',
    src: '/images/categories/category-13.png',
    subCategories: ['Трубы и фитинги', 'Душевое оборудование', 'Санфаянс', 'Сантехническая арматура'],
    subCategoriesCounter: 48,
  },
  {
    label: 'Сварочное оборудование',
    src: '/images/categories/category-14.png',
    subCategories: ['Сварочные аппараты', 'Паяльное оборудование', 'Защитная экипировка', 'Расходные материалы'],
    subCategoriesCounter: 21,
  },
  {
    label: 'Климатическая техника',
    src: '/images/categories/category-15.png',
    subCategories: ['Вентиляционное оборудование', 'Тепловое оборудование', 'Системы обработки воздуха', 'Системы кондиционирования'],
    subCategoriesCounter: 147,
  },
];
</script>

<style lang="scss" scoped>
.CatalogCategoriesPromo {
  $s: &;
  $trs: 0.2s;

  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 2fr;

  &__title {
    padding-top: 60px;
    @include text-m48;
  }

  &__text {
    @include text-r16;
    margin-top: 24px;
  }

  &__sidebar {
    padding-right: 24px;
  }

  &__benefits {
    display: flex;
    flex-direction: column;
    grid-gap: 60px;
    margin-top: 40px;

    &-item {
      @include text-m20;
    }

    &-counter {
      @include text-m48;

      display: inline-block;
      margin-bottom: 12px;

      background: $color-green-gradient;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 8px;
  }

  &__item {
    --padding-X: 32px;
    --padding-Y: 24px;

    border: 1px solid transparent;
    background-color: $color-gray-extra-light;
    border-radius: 8px;
    height: 220px;
    max-width: 100%;
    overflow: hidden;
    padding: var(--padding-Y) var(--padding-X);
    display: flex;
    flex-direction: column;
    position: relative;
    transition: $trs;

    &-img {
      position: absolute;
      left: var(--padding-X);
      top: var(--padding-X);
      transition: $trs;
    }

    &:before {
      content: '';
      flex-shrink: 1;
      height: 100%;
      transition: $trs;
    }

    &-title {
      @include text-m16;

      flex: 100000 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      overflow: hidden;
      position: relative;
      z-index: 3;
      transition: $trs;
    }

    &-additional {
      flex: 0 100000 auto;
      display: flex;
      flex-direction: column;
      grid-gap: 8px;
      overflow: hidden;
      margin-top: 8px;
      position: relative;
      z-index: 3;
      transition: $trs;
      justify-content: space-between;
    }

    &-sublist {
      @include text-r12;

      display: flex;
      flex-direction: column;
      grid-gap: 8px;
      overflow: hidden;
      position: relative;
      flex: 1 0 auto;
      max-height: 100px;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 20px;
        background: linear-gradient(to top, rgba(white, 100%), rgba(white, 0%));
        opacity: 0;
        transition: $trs / 2;
      }
    }

    &-counter {
      flex: 0 0 auto;
    }

    @include hover {
      border-color: $color-gray-light;

      &:not(#{$s}__item--btn) {
        background-color: $color-white;

        #{$s}__item-img {
          opacity: 0;
        }

        &:before {
          height: 0%;
        }

        #{$s}__item-title {
          flex-shrink: 0;
          flex-grow: 0;
        }

        #{$s}__item-additional {
          flex-shrink: 0;
          flex-grow: 100000;
        }
        #{$s}__item-sublist {
          &::after {
            opacity: 1;
            transition: calc(#{$trs} / 2) calc(#{$trs} / 2);
          }
        }
      }
    }

    &--btn {
      color: $color-white;
      cursor: pointer;
      position: relative;
      background: $color-green-gradient-hover;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        pointer-events: none;
        background: $color-green-gradient;
        transition: $trs;
      }

      #{$s}__item-img {
        background-color: $color-white;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      :deep(path) {
        fill: $color-green;
      }

      @include hover {
        &:before {
          opacity: 0;
        }

        #{$s}__item-img {
          transform: scale(1.25);
        }
      }
    }
  }

  @include maxw(1599) {
    &__item {
      --padding-X: 24px;
      --padding-Y: 24px;

      &-title {
        @include text-m14;
      }
    }
  }

  @include maxw(1279) {
    grid-template-columns: 1fr;

    &__benefits {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-bottom: 40px;
      &-item {
        @include text-m14;

        @include minw(768) {
          :deep(br) {
            display: none;
          }
        }
      }
    }

    &__item {
      height: 176px;

      &-img {
        &:deep(img) {
          max-width: 64px;
          max-height: 64px;
        }
      }
    }
  }
  @include maxw(767) {
    &__title {
      padding-top: 0;
    }

    &__text {
      display: none;
    }

    &__benefits {
      display: flex;
      flex-direction: column;
      grid-gap: 24px;

      &-counter {
        font-size: 32px;
      }
    }

    &__list {
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      height: 156px;
      padding: 12px;

      &-img {
        &:deep(img) {
          max-width: 56px;
          max-height: 56px;
        }
      }
    }
  }
}
</style>

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ERPNeedRetrieveSchema } from './ERPNeedRetrieveSchema';
import type { OrderHistoryActionTypeEnum } from './OrderHistoryActionTypeEnum';
import type { SupplierOrderCompleteRetrieveSchema } from './SupplierOrderCompleteRetrieveSchema';
import type { SupplierOrderDeliveryConditionsChangedEnum } from './SupplierOrderDeliveryConditionsChangedEnum';
import type { UserShortOutSchema } from './UserShortOutSchema';
export type ERPSupplierOrderCompleteRetrieveSchema = {
    id: number;
    number: string;
    supplierId: number;
    status: ERPSupplierOrderCompleteRetrieveSchema.status;
    dateDelivery?: string;
    cancelingAllowed: boolean;
    deliveryCost?: number;
    approverSub?: string;
    approvedByUser?: UserShortOutSchema;
    approved?: boolean;
    detail?: string;
    detailDate?: string;
    detailType?: OrderHistoryActionTypeEnum;
    isAvailableBasis: boolean;
    items?: Array<SupplierOrderCompleteRetrieveSchema>;
    deliveryConditionsChanged?: SupplierOrderDeliveryConditionsChangedEnum;
    initialClientDeliveryDate?: string;
    oldDeliveryDate?: string;
    oldClientDeliveryDate?: string;
    needs: Array<ERPNeedRetrieveSchema>;
};
export namespace ERPSupplierOrderCompleteRetrieveSchema {
    export enum status {
        DRAFT = 'draft',
        WAITING = 'waiting',
        BOSS_WAITING = 'boss_waiting',
        DECLINED = 'declined',
        CANCELED = 'canceled',
        RETURNED = 'returned',
        ACCEPTED = 'accepted',
        DELIVERY = 'delivery',
        DELIVERED = 'delivered',
        RETURN_COMPLETE = 'return_complete',
        ERP_PROCESSING = 'erp_processing',
        RC_TO_DELIVERY = 'rc_to_delivery',
        RC_ACCEPTED = 'rc_accepted',
        WAREHOUSE_DELIVERED = 'warehouse_delivered',
        SHIPPED = 'shipped',
        RC_DELIVERED = 'rc_delivered',
    }
}


<template>
  <div class="AboutLogistic">
    <div class="AboutLogistic__head">
      <h2 class="AboutLogistic__title">
        Доставляем <br>
        по всей России
      </h2>
      <div class="AboutLogistic__toggler">
        <MToggler
          v-model="viewMode"
          :buttons="[
            { label: 'в цифрах', value: EViewMode.Numbers },
            { label: 'на карте', value: EViewMode.Map },
          ]"
        />
      </div>
    </div>

    <template v-if="viewMode === EViewMode.Numbers">
      <div class="AboutLogistic__content-numbers">
        <div class="AboutLogistic__numbers-list">
          <div
            v-for="(numbersItem, index) in numbersList"
            :key="index"
            class="AboutLogistic__number"
          >
            <div
              class="AboutLogistic__number-title"
              v-html="numbersItem.title"
            />
            <div
              class="AboutLogistic__number-text"
              v-html="numbersItem.text"
            />
          </div>
        </div>
        <div class="AboutLogistic__numbers-img">
          <MNuxtImg src="images/AboutLogistic-car.png" />
        </div>
      </div>
    </template>
    <div
      v-if="viewMode === EViewMode.Map"
      class="AboutLogistic__content-map"
    >
      <div
        ref="refMap"
        class="AboutLogistic__content-map-inner"
      >
        <DistributionCentersMap />
      </div>
      <div
        ref="refScroll"
        class="AboutLogistic__content-map-scroll"
      >
        <div />
      </div>
    </div>
    <div class="AboutLogistic__foot">
      <div class="AboutLogistic__subtitle">
        Сопровождаем сделки от оформления заказа <br>
        до доставки конечному пользователю
      </div>
      <div class="AboutLogistic__benefits-list">
        <div
          v-for="(benefit, index) in benefitsList"
          :key="index"
          class="AboutLogistic__benefit"
        >
          <MIcon
            :src="MM.EIcon.ColorCircleSuccess"
            width="24px"
            height="24px"
          />
          <div
            class="AboutLogistic__benefit-title"
            v-html="benefit.title"
          />
          <div
            class="AboutLogistic__benefit-text"
            v-html="benefit.text"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { MM } from '~/types';
import DistributionCentersMap from '/components/static/DistributionCentersMap/DistributionCentersMap.vue';
import MNuxtImg from 'components/MNuxtImg/MNuxtImg.vue';

const benefitsList = [
  {
    title: 'Консолидируем заказы<br> от разных поставщиков',
    text: 'Заказы формируются на основе потребности благодаря бесшовной интеграции с ERP-системой покупателя',
  },
  {
    title: 'Поддерживаем клиентов<br> на всех этапах заказа',
    text: 'Возможность закупки как по индивидуальным ценам от конкретных поставщиков, так и по всем предложениям на маркетплейсе',
  },
  {
    title: 'Контролируем доставку<br> до базиса получателя',
    text: 'Максмарт осуществляет доставку заказов даже в отдаленные регионы',
  },
];

const numbersList = [
  {
    title: '18',
    text: 'Распределительных <br>центров на территории РФ',
  },
  {
    title: 'в 5 раз',
    text: 'Увеличилась скорость доставки товара до конечного заявителя',
  },
  {
    title: '30%',
    text: 'Экономия в результате консолидации заказов от разных поставщиков',
  },
];

enum EViewMode {
  Numbers = 'numbers',
  Map = 'map',
}

const viewMode = ref(EViewMode.Numbers);

const refScroll = ref<HTMLElement>();
const refMap = ref<HTMLElement>();

function syncScroll(blockScrolling: HTMLElement, blockSync: HTMLElement) {
  blockSync.scrollLeft = blockScrolling.scrollLeft;
}

watchEffect(() => {
  if (refScroll.value && refMap.value) {
    refScroll.value.addEventListener('scroll', () => syncScroll(refScroll.value, refMap.value));
    refMap.value.addEventListener('scroll', () => syncScroll(refMap.value, refScroll.value));
  }
});

onUnmounted(() => {
  if (refScroll.value && refMap.value) {
    refScroll.value.removeEventListener('scroll', () => syncScroll(refScroll.value, refMap.value));
    refMap.value.removeEventListener('scroll', () => syncScroll(refMap.value, refScroll.value));
  }
});
</script>

<style lang="scss" scoped>
.AboutLogistic {
  $s: &;

  background-color: $color-green-extra-dark;
  padding: 60px var(--container-offset-X) 80px;
  border-radius: 12px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  color: $color-white;

  &::before {
    content: '';
    display: block;
    right: -280px;
    top: -140px;
    position: absolute;
    pointer-events: none;
    z-index: -1;
    width: 1000px;
    height: 1000px;
    filter: blur(400px);
    background-image: $color-green-gradient;
    border-radius: 50%;
    opacity: 0.1;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 6;
  }

  &__title {
    @include text-m48;
    margin-bottom: 0;
  }

  &__subtitle {
    @include text-m28;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: $color-green-gradient;
  }

  &__content-map {
    $minWidthMap: 1200px;

    margin-top: -180px;
    margin-bottom: -60px;

    &-inner {
      @include scrollbar;

      --scrollbar-size: 0px;
      margin-left: calc(-1 * var(--container-offset-X));
      margin-right: calc(-1 * var(--container-offset-X));
      padding: 0 var(--container-offset-X);

      :deep(.DistributionCentersMap) {
        min-width: $minWidthMap;
      }
    }

    &-scroll {
      @include scrollbar;

      div {
        width: $minWidthMap;
        height: 1px;
      }
    }
  }

  &__content-numbers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 80px;
    margin-top: 60px;
  }
  &__numbers-img {
    flex: 0 1 auto;
    margin-right: calc(-1 * var(--container-offset-X));
    margin-top: -60px;
    margin-bottom: -60px;
  }
  &__numbers-list {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    grid-gap: 60px;
  }

  &__number {
    max-width: 440px;
    display: flex;
    flex-direction: column;
    grid-gap: 12px;

    &-title {
      @include text-m48;

      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: $color-green-gradient;
    }

    &-text {
      @include text-m20;
    }
  }

  &__foot {
    padding-top: 60px;
    margin-top: 120px;
    border-top: 1px solid $color-green-dark-muted;
  }

  &__benefits-list {
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 48px;
  }

  &__benefit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 12px;

    &-title {
      @include text-m20;
    }

    &-text {
      color: $color-green-muted;
    }
  }

  @include maxw(1599) {
    &__content-numbers {
      grid-gap: 24px;
      margin-top: 40px;
    }

    &__numbers-list {
      grid-gap: 40px;
    }

    &__foot {
      margin-top: 100px;
    }
  }
  @include maxw(1279) {
    &__content-numbers {
      flex-direction: column;
    }
    &__numbers-list {
      flex-direction: row;
    }
    &__numbers-img {
      margin-top: 30px;
      padding-left: 60px;
    }
  }

  @include maxw(767) {
    padding-bottom: 48px;
    &__head {
      grid-gap: 20px;
      flex-wrap: wrap;
    }
    &__content-numbers {
      margin-top: 20px;
    }

    &__numbers-list {
      flex-direction: column;
      grid-gap: 20px;
    }
    &__numbers-img {
      padding-left: 0px;
    }
    &__benefits-list {
      display: flex;
      flex-direction: column;
      grid-gap: 36px;
      margin-top: 32px;
    }

    &__subtitle {
      @include text-m32;
    }
    &__benefit {
      &-title {
        @include text-m24;
      }
      &-text {
        @include text-m20;
      }
    }
  }
}
</style>

<template>
  <div
    class="main-wrap"
    :class="{ logged: isSearchGroupAccess }"
  >
    <div class="m-container-offset">
      <section class="section__MainFirstScreen">
        <MainFirstScreen />
      </section>
    </div>

    <div class="m-container">
      <h2 class="text-m48 main-page-title">
        Улучшаем закупочный процесс <br>
        для всех его участников
      </h2>
      <!-- Покупателям -->
      <section class="section__consumers">
        <ClientsBenefits :data="consumers" />
      </section>

      <!-- Поставщикам -->
      <section class="section__suppliers">
        <ClientsBenefits :data="suppliers" />
      </section>
    </div>

    <div class="m-container-offset">
      <!-- Сеть распределительных центров Максмарт -->
      <section class="section__logistic">
        <AboutLogistic />
      </section>
    </div>

    <div class="m-container">
      <!-- Каталог товаров -->
      <section class="section__catalog">
        <CatalogCategoriesPromo />
      </section>

      <!-- Партнёры о нас -->
      <!-- Скрыто пока нет контента, в первом релизе скорее всего не будет -->
      <!-- <section class="section__partners">
        <PartnersAboutUs />
      </section> -->

      <!-- Новости Максмарт -->

      <h2 class="main-page-title">
        Максмарт подтверждает <br>свою экспертность
      </h2>
      <section class="section__NewsListAnnounce">
        <NewsListAnnounce />
      </section>

      <section class="section__MassMediaListAnnounce">
        <MassMediaListAnnounce />
      </section>
    </div>
    <div class="m-container-offset">
      <section class="section__CompanyAwards">
        <!-- Максмарт высоко оценивается в профессиональном комьюнити -->
        <CompanyAwards />
      </section>

      <section class="section__AboutCompany">
        <!-- B2B-маркетплейс быстрых закупок -->
        <AboutCompany />
      </section>
    </div> 

    <div class="main-wrap__NewsFixedAnnounce">
      <NewsFixedAnnounce
        v-if="isShowNewsFixedAnnounce"
        @close="hideNewsFixedAnnounce"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useSearchGroupAccess } from 'composables/useGroup';
import 'vue3-carousel/dist/carousel.css';

import { useRedirectMirror } from 'composables/useRedirectMirror';

import CatalogCategoriesPromo from '/components/catalog/CatalogCategoriesPromo/CatalogCategoriesPromo.vue';
import ClientsBenefits from '/components/static/ClientsBenefits.vue';
import CompanyAwards from '/components/static/CompanyAwards.vue';

import { EClientApprovalType } from '~/enums/client/clientApprovalType.enum';
import NewsListAnnounce from '~/components/news/NewsListAnnounce.vue';
import MassMediaListAnnounce from '~/components/MassMedia/MassMediaListAnnounce.vue';

/** Временно отключено */
// import PartnersAboutUs from '~/components/static/PartnersAboutUs.vue';
import MainFirstScreen from '~/components/static/MainFirstScreen/MainFirstScreen.vue';
import AboutLogistic from '~/components/static/AboutLogistic.vue';
import NewsFixedAnnounce from '~/components/news/NewsFixedAnnounce.vue';
import AboutCompany from '~/components/static/AboutCompany.vue';

/* Старые блоки которые не используются */
// import SliderMainPageFirst from '/components/sliders/SliderMainPageFirst/SliderMainPageFirst.vue';
// import CompanyBenefits from '/components/static/CompanyBenefits.vue';
// import AboutCompany from 'components/static/AboutCompany.vue';
// import BannerDark from 'components/static/BannerDark.vue';
// import BrandsCarousel from '/components/static/BrandsCarousel.vue';

definePageMeta({
  props: { headerInverse: true, headerTransparent: true, headerDarkMode: true },
});

/** Отображение попапа с новостями */
const isShowNewsFixedAnnounce = ref(true);

/** Скрыть попап с новостями */
function hideNewsFixedAnnounce() {
  isShowNewsFixedAnnounce.value = false;
}

await useRedirectMirror();

useHead({
  title: 'B2B электронная торговая площадка, Б2Б маркетплейс быстрых оптовых закупок - Максмарт',
  meta: [
    { name: 'google-site-verification', content: useRuntimeConfig()?.public?.googleSiteVerificationToken },
    { name: 'yandex-verification', content: useRuntimeConfig()?.public?.yandexVerificationKey },
    {
      name: 'description',
      content:
        'Максмарт - B2B электронная торговая площадка, маркетплейс быстрых закупок. Оптимизация закупок для крупнейших промышленных компаний, снижение стоимости закупок, широкий ассортимент, логистика до базиса клиента.',
    },
  ],
});

const isSearchGroupAccess = useSearchGroupAccess();
useShowRedirectNotification();

const isMounted = ref<boolean>(false);

const { onSupplierRegistration } = useSupplierRegistration();
const { onCLickClientApprovalModal } = useClientApproval();

function onClientCreateClick() {
  onCLickClientApprovalModal(EClientApprovalType.Client);
}

onMounted(async () => {
  isMounted.value = true;
});

const consumers = {
  title: 'Покупателям',
  text: 'Предлагаем уникальный набор сервисов',

  list: [
    {
      title: 'Автоматизированные закупки из ERP',
      text: 'Заказы формируются на основе потребности благодаря бесшовной интеграции с ERP-системой покупателя',
      img: '/images/buyer-features-icon-1v2.png',
    },
    {
      title: 'Контролируемая доставка до базиса получателя',
      text: 'Максмарт осуществляет доставку заказов даже в отдаленные регионы',
      img: '/images/buyer-features-icon-4v2.png',
    },
    {
      title: 'Индивидуальные цены от поставщиков',
      text: 'Возможность закупки как по индивидуальным ценам от конкретных поставщиков, так и по всем предложениям на маркетплейсе',
      img: '/images/icon-4v2.png',
    },
    {
      title: 'Электронный документооборот',
      text: 'Автоматизация работы с документами: унификация, прослеживаемый и быстрый обмен, сохранение цепочек документов, удобная отчетность',
      img: '/images/icon-2v2.png',
    },
    {
      title: 'Консалтинг и оптимизация процессов',
      text: 'Не тратьте время и деньги на согласование документов, сопоставление номенклатур и проверку поставщиков. Делегируйте это нам — покупайте быстрее',
      img: '/images/icon-1@2x.png',
    },
  ],
  footerTitle: 'С нами работают:',
  brands: Array.from({ length: 12 }, (_, i) => ({
    img: `/images/consumer-brands/consumer-brand-${i + 1}.png`,
  })),
  action: {
    label: 'Стать покупателем',
    onClick: () => onClientCreateClick(),
  },
};

const suppliers = {
  title: 'Поставщикам',
  text: 'Эксклюзивный канал продаж крупнейшим компаниям России',
  list: [
    {
      title: 'Регулярные заказы от корпоративных клиентов',
      text: 'Прямой выход на закупки крупнейших компаний',
      img: '/images/seller-feature-icon-1.png',
    },
    {
      title: 'Простая логистика',
      text: 'Доставляйте товары в распределительные центры маркетплейса. Консолидацию заказов и их доставку в удаленные базисы Максмарт берет на себя',
      img: '/images/icon-6v2.png',
    },
    {
      title: 'Отсутствие тендерных процедур',
      text: 'Единожды пройденная квалификация открывает доступ к заказам всех покупателей маркетплейса',
      img: '/images/seller-feature-icon-1.png',
    },
    {
      title: 'Помощь в создании цифрового каталога',
      text: 'Поможем создать электронный каталог ваших товаров, сопоставим ассортимент с номенклатурой покупателя и разместим его на маркетплейсе',
      img: '/images/seller-feature-icon-1.png',
    },
    {
      title: 'Электронный документооборот',
      text: 'Максмарт является единым контрагентом для всех заказов, что сокращает затраты на сопровождение заказа',
      img: '/images/icon-2v2.png',
    },
  ],
  footerTitle: 'С нами работают:',
  brands: Array.from({ length: 34 }, (_, i) => ({
    img: `/images/supplier-brands/supplier-brand-${i + 1}.png`,
  })), // бренд один попросили скрыть
  action: {
    label: 'Стать поставщиком',
    onClick: () => onSupplierRegistration(),
  },
};
</script>

<style scoped lang="scss">
@import 'shared/assets/styles/base/common/variables';

.section {
  &__catalog {
    margin-bottom: 120px;
  }

  &__MainFirstScreen,
  &__BannerDark,
  &__partners,
  &__consumers,
  &__suppliers,
  &__logistic,
  &__DistributionCentersMap,
  &__NewsListAnnounce,
  &__MassMediaListAnnounce {
    margin-bottom: 80px;
  }

  @include maxw(1599) {
    &__catalog,
    &__BannerDark,
    &__BrandsCarousel,
    &__consumers,
    &__suppliers,
    &__logistic,
    &__DistributionCentersMap,
    &__NewsListAnnounce,
    &__MassMediaListAnnounce {
      margin-bottom: 60px;
    }
  }

  @include maxw(1279) {
    &__MainFirstScreen,
    &__BannerDark,
    &__BrandsCarousel,
    &__consumers,
    &__suppliers,
    &__logistic,
    &__DistributionCentersMap,
    &__NewsListAnnounce,
    &__MassMediaListAnnounce {
      margin-bottom: 48px;
    }
  }
}

.main-page-title {
  margin-bottom: 60px;

  @include text-m48;

  @include maxw(1279) {
    margin-bottom: 48px;
  }
}

.main-wrap__NewsFixedAnnounce {
  @include maxw(1279) {
    display: none;
  }
}
</style>

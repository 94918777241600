<template>
  <div
    class="date-range"
    :class="{
      'date-range--hidden-inputs-icons': filter.hideInputsIcons
    }"
  >
    <div class="date-range__input-container">
      <DatePicker
        v-model="dateFrom"
        class="date-range__input"
        :placeholder="dateFromPlaceholder"
        :enable-time-picker="false"
        format="dd.MM.yyyy"
        :max-date="dateTo"
        :min-date="minDateFrom"
        auto-apply
        :close-on-auto-apply="true"
        :disabled="disabled"
        month-name-format="long"
        :hide-input-icon="filter.hideInputsIcons"
        text-input
        :clearable="clearable"
      >
        <template #day="{ day, date }">
          <MTooltip
            v-if="isDateFromLessThanMinDate(date)"
            is-show-arrow
            :text="modelValue.dateFromDisabledTextByMinDate"
          >
            {{ day }}
          </MTooltip>
          <template v-else>
            {{ day }}
          </template>
        </template>
      </DatePicker>
    </div>

    <div class="date-range__input-container">
      <DatePicker
        v-model="dateTo"
        class="date-range__input"
        :placeholder="dateToPlaceholder"
        :enable-time-picker="false"
        format="dd.MM.yyyy"
        :min-date="dateFrom"
        :max-date="maxDateTo"
        :disabled="disabled"
        auto-apply
        :close-on-auto-apply="true"
        month-name-format="long"
        :hide-input-icon="filter.hideInputsIcons"
        text-input
        :clearable="clearable"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IFilterDateRange } from '../../models/filters.model';
import DatePicker from '../DatePicker.vue';
import { cloneDeep } from 'lodash-es';
import { WatchSubscription } from '../../utils/watchSubscription';
import useSSRUnsubscribeWatch from '../../composables/useSSRUnsubscribeWatch';
import { MTooltip } from '@maksmart/maksify';
import { isBefore } from 'date-fns';

const props = withDefaults(
  defineProps<{
    filter: IFilterDateRange;
    modelValue: IFilterDateRange;
    disabled?: boolean;
    clearable?: boolean;
  }>(),
  {
    clearable: true,
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', filter: IFilterDateRange): void;
}>();

const dateFrom = ref<string>(props.modelValue?.dateFrom || props.filter?.dateFrom);
const dateTo = ref<string>(props.modelValue?.dateTo || props.filter?.dateTo);

const watchSubscription = new WatchSubscription();
const dateFromPlaceholder = computed(() => props.filter?.dateFromPlaceholder || 'От');
const dateToPlaceholder = computed(() => props.filter?.dateToPlaceholder || 'До');
const minDateFrom = computed(() => props.filter?.minDateFrom ? new Date(props.filter.minDateFrom) : undefined);
const maxDateTo = computed(() => props.filter?.maxDateTo ? new Date(props.filter.maxDateTo) : undefined);

function isDateFromLessThanMinDate(date: Date): boolean {
  if (!minDateFrom.value || !props.modelValue.dateFromDisabledTextByMinDate) {
    return false;
  }

  return isBefore(date, minDateFrom.value);
}

watchSubscription.add(
  watch(
    () => [dateFrom.value, dateTo.value],
    () => {
      const cloneFilter = cloneDeep(props.filter);
      cloneFilter.dateFrom = dateFrom.value;
      cloneFilter.dateTo = dateTo.value;
      emit('update:modelValue', cloneFilter);
    },
  ),
);

useSSRUnsubscribeWatch(watchSubscription);
</script>

<style lang="scss" scoped>
.date-range {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__input {
    padding-bottom: 0;

    &-container {
      flex: 1;
      width: 153px;

      &:first-child {
        margin-right: 20px;
      }

      :deep(.dp__main) {
        .dp__input_icon {
          padding-left: 12px;
          top: 45%;
        }

        .dp__input {
          height: 44px;
          padding-left: 40px;
        }
      }
    }
  }

  &--hidden-inputs-icons {
    :deep(.dp__main) {
      .dp__input {
        padding-left: 12px;
      }
    }
  }
}

:deep(.dp__month_year_col_nav) {
  background-color: red;
}
</style>

<template>
  <div class="MassMediaListAnnounce">
    <div class="MassMediaListAnnounce__head">
      <div>
        <h3 class="text-m28">
          Новости Максмарт
        </h3>
        <div class="text-r16 MassMediaListAnnounce__subtitle">
          Делимся инновационным опытом
        </div>
      </div>
      <div>
        <MButton
          :icon-right="MM.EIconPath.NavigationMainArrowSvg"
          :type="MM.EButtonTypes.Text"
          @click="onGoToAllNews"
        >
          Все новости
        </MButton>
      </div>
    </div>
    <div class="MassMediaListAnnounce__list">
      <template v-if="!news.items">
        <MSkeletonLoader
          v-for="t in [1, 2, 3, 4]"
          :key="t"
          :is-loading="true"
          height="200px"
          type="box"
        />
      </template>
      <template v-else>
        <NewsCard
          v-for="item in news.items"
          :key="item.id"
          :data="item"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { MM } from 'types';
import { NewsApiService } from '~/services/api/newsApi.service';
import { IBasePagedResponse } from 'shared/models/baseApi.model';
import { IStrapiNewsListResponse } from '~/models/strapi/strapiNews.model';
import NewsCard from 'components/news/NewsCard.vue';

const news = ref<IBasePagedResponse<IStrapiNewsListResponse>>({});

function onGoToAllNews() {
  navigateTo('/news/?categoryId=1');
}

async function loadNewsData() {
  await NewsApiService.getNews({
    pageSize: 4,
    page: 1,
    sectionId: 1,
    mainPage: true,
  })
    .then((res) => {
      news.value = res;
    })
    .catch((err) => {
      console.error(err);
    });
}

onMounted(() => {
  loadNewsData();
});
</script>

<style lang="scss" scoped>
.MassMediaListAnnounce {
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    grid-gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 32px;
  }

  &__subtitle {
    margin-top: 12px;
    color: $color-green-dark-muted;
  }

  &__list {
    max-width: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(4, 3fr);
    grid-gap: 8px;
  }

  @media only screen and (max-width: 1279px) {
    &__list {
      grid-gap: 40px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media only screen and (max-width: 767px) {
    &__list {
      grid-gap: 8px;
    }
  }
}
</style>

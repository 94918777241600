import { ApiHelper } from 'shared/services/api/apiHelper.service';
import { PimOfferApiService } from '~/services/api/pimOfferApi.service';
import { useUserStore } from 'store/user.store';
import { useSessionStorage } from '@vueuse/core';

/** Композиция для управления алертом на вкладке "Каталоги" в ЛК поставщика */
export const useNewHiddenOffersPolling = () => {
  const POLLING_INTERVAL = 60_000;

  const { supplierId } = useUserStore();

  /** Объект со списками скрытых офферов по id поставщика. Хранится в рамках сессии */
  const supplierInitialHiddenOffersLists = useSessionStorage<Record<string, Array<number> | undefined>>(
    'supplierInitialHiddenOffersLists',
    {},
  );

  /** Флаг наличия скрытых офферов у поставщиков. Хранится в рамках сессии */
  const supplierHasHiddenOffers = useSessionStorage<Record<string, boolean | undefined>>('supplierHasHiddenOffers', {});

  /** Список офферов на начало сессии */
  const initialOfferIdsList = computed<Array<number> | undefined>(() => supplierInitialHiddenOffersLists.value[String(supplierId)]);

  /** Установить начальный список скрытых офферов поставщика */
  function setInitialOfferIdsList(list?: Array<number>) {
    if (!list) {
      return;
    }

    supplierInitialHiddenOffersLists.value[String(supplierId)] = list;
  }

  /** Текущий список офферов */
  const newOfferIdsList = ref<Array<number>>();

  /** Появились новые оффера? */
  const hasNewHiddenOffers = computed(() => hasNewIds(initialOfferIdsList.value, newOfferIdsList.value));

  /** Флаг остановки поллинга офферов */
  const isNeedAbortOffersPolling = ref(false);

  /** Сбрасывает флаг наличия новых офферов */
  function resetHasNewHiddenOffers() {
    setInitialOfferIdsList(newOfferIdsList.value);
  }

  /** Запускает поллинг */
  async function startPolling() {
    try {
      await ApiHelper.pooling(getAllHiddenOfferIds, handlePollingResponse, {
        interval: POLLING_INTERVAL,
        attempts: Infinity,
      });
    } catch {
      handlePollingError();
    }
  }

  /** Обрабатывает ответ поллинга */
  function handlePollingResponse(response: Array<number>) {
    if (isNeedAbortOffersPolling.value) {
      return false;
    }

    updateInitialOfferIdsList(response);
    updateNewOfferIdsList(response);
    updateSupplierHiddenOffersFlag(response);

  // продолжить поллинг
    return true; 
  }

  /** Обновляет начальный список офферов */
  function updateInitialOfferIdsList(response: Array<number>) {
    if (!initialOfferIdsList.value) {
      // устанавливает начальный список офферов если его нет
      setInitialOfferIdsList(response);
    } else {
      // удаляем оффера из начального списка, если они были возвращены в трансляцию и их нет в новом списке
      const updatedList = initialOfferIdsList.value.filter((id) => response.includes(id));
      setInitialOfferIdsList(updatedList);
    }
  }

  /** Обновляет список новых офферов */
  function updateNewOfferIdsList(response: Array<number>) {
    newOfferIdsList.value = response;
  }

  /** Устанавливает флаг наличия скрытых офферов */
  function updateSupplierHiddenOffersFlag(response: Array<number>) {
    supplierHasHiddenOffers.value[String(supplierId)] = !!response.length;
  }

  /** Обрабатывает ошибку поллинга */
  function handlePollingError() {
    if (!isNeedAbortOffersPolling.value) {
      // перезапуск поллинга
      setTimeout(startPolling, POLLING_INTERVAL); 
    }
  }

  /** Останавливает поллинг */
  function stopPolling() {
    isNeedAbortOffersPolling.value = true;
  }

  /** Получает все скрытые оффера поставщика */
  async function getAllHiddenOfferIds() {
    const offers = await PimOfferApiService.getOffersFromAllPages({
      hidden: true,
      supplier: String(supplierId),
    });

    // возвращаем список id офферов
    return offers.map((offer) => offer.id);
  }

  /** Проверяет наличие новых офферов */
  function hasNewIds(
    // начальный массив id
    initialList?: Array<number>,
    // новый массив id
    newList?: Array<number>,
  ) {
    if (!initialList || !newList) {
      return false;
    }
    const initialSet = new Set(initialList);

    // Проверяем, есть ли в newList хотя бы один ID, которого нет в initialList
    return newList.some((number) => !initialSet.has(number));
  }

  return {
    /** Флаг наличия скрытых офферов у поставщиков. Хранится в рамках сессии */
    supplierHasHiddenOffers,
    /** Появились новые оффера? */
    hasNewHiddenOffers,
    /** Сбрасывает флаг наличия новых офферов */
    resetHasNewHiddenOffers,
    /** Запускает поллинг */
    startPolling,
    /** Останавливает поллинг */
    stopPolling,
  };
};

import { Ref } from 'vue';
import { IColumnHeader } from 'shared/models/table.model';
import { ILimitInfo } from 'models/basket.model';
import { ITableSort } from 'shared/models/table.model';

export abstract class BaseBasketCategory<T = Record<string, unknown>> {
  public categoryName = ref<string>();
  public limitInfo: ILimitInfo = {
    limit: ref(),
    delta: ref(),
    spent: ref(),
    limitInfoDetails: ref(),
    isOverPermissible: ref(),
  };

  public tableSort = ref<ITableSort | null>(null);

  abstract tableData: Ref<Array<T>>;
  abstract headerList: Ref<IColumnHeader[]>;

  public preparedTableData = computed(
    () => this.tableData.value,
  );

  public onChangedTableSort(tableSort: ITableSort | null): void {
    this.tableSort.value = tableSort;
  }

  public sortNeedsByFirstItemsCatalogId(itemACatalogId?: number, itemBCatalogId?: number, asc = true): number {
    if ((!itemACatalogId && !itemBCatalogId)
      || (itemACatalogId < 0 && itemBCatalogId < 0)
    ) {
      return 0;
    }

    if ((!itemACatalogId && itemBCatalogId)
      || (itemACatalogId < 0 && itemBCatalogId > 0)
    ) {
      return 1;
    }

    if ((itemACatalogId && !itemBCatalogId)
      || (itemACatalogId > 0 && itemBCatalogId < 0)
    ) {
      return -1;
    }

    return itemACatalogId > itemBCatalogId
      ? (asc ? 1 : -1)
      : itemACatalogId < itemBCatalogId
        ? (asc ? -1 : 1)
        : 0;
  }
}

import { WritableComputedRef } from 'vue';
import { ELocalStorageKey } from '../enums/localStorageKey.enum';

export function useLocalStorage(key: ELocalStorageKey | string, prefix = 'mm'): WritableComputedRef<string | undefined> {
  const value = computed<string | undefined>({
    get() {
      if (!process.client) {
        return undefined;
      }

      return localStorage?.getItem(getKey()) || undefined;
    },
    set(newValue) {
      if (!process.client) {
        return;
      }

      localStorage.setItem(getKey(), newValue);
    },
  });

  function getKey(): string {
    return `${prefix ? `${prefix}-` : ''}${key}`;
  }

  return value;
}

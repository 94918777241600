import { WatchStopHandle } from 'vue';
import { BasketCategory } from 'services/basket-services/basket/basketCategory.service';
import { IAddProduct, IAddProductResponse, IBasket, IPlaceOrderRecipient } from 'models/basket.model';
import { BaseBasket } from 'services/basket-services/base-basket/baseBasket.service';
import Notificator from 'shared/services/notificator.service';
import { EUserGroup } from 'shared/enums/user/userGroup.enum';
import { EBasketActionLoaderType } from 'enums/basket/basketLoaderInitiator.enum';
import Disabler from 'shared/utils/disablerHelper.util';
import { basketLoaderMap } from 'constants/basket/basket.const';
import Modal from 'shared/components/modals/Modal.vue';
import SelectDeliveryAddressBasketModal from 'components/modals/SelectDeliveryAddressBasketModal.vue';
import AddRecipentModal from 'components/modals/client/AddRecipentModal.vue';
import { IDeliveryDestination } from 'shared/models/bases.model';
import { BasesApiService } from 'services/api/basesApi.service';
import ChooseApproverModal from 'components/modals/client/ChooseApproverModal.vue';
import { StringsHelper } from 'shared/utils/strings.util';
import { BAD_REQUEST_ERROR_CODE, CONFLICT_ERROR_CODE, NOT_FOUND_ERROR_CODE } from 'shared/constants/error.const';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import { BasketManager } from './basketManager.service';
import { TranslateOptions } from '@nuxtjs/i18n/dist/runtime/composables';
import ModalManager from 'shared/services/modalManager.service';
import { CARD_NOT_FOUND_ERROR, INVALID_CART_ERROR } from 'constants/error.const';
import FilesService from 'shared/services/api/files.service';
import Loader from 'shared/utils/loaderHelper.util';
import { ApproveRuleApiService } from 'services/api/approveRuleApi.service';
import { IBasisApproveRule } from 'models/client/approveRule.model';
import { EBasketPlacementStatus } from 'enums/basket/basketPlacementStatus.enum';
import { EBasketInternalStatus } from 'enums/basket/basketInternalStatus.enum';
import { EERpBasketDistributionStatus } from 'enums/basket/erpBasketDistributionStatus.enum';

export class Basket extends BaseBasket<IBasket, EBasketPlacementStatus> {
  protected static readonly basketPagePath = '/basket';

  private static instance: undefined | Basket;

  public categoryList = shallowRef<Array<BasketCategory>>([]);
  public isBasketLoading = Disabler.getReactiveInstance();
  public basketLoaderInfoMap = basketLoaderMap;

  private basketManager = new BasketManager();
  public readonly modalManager = new ModalManager();

  private preSaveBasketFlow: Array<(next: () => void, abort: () => void) => Promise<void> | void> = [
    this.openRecipientModal,
    this.startApproveFlow,
    this.openSelectDeliveryModal,
  ];

  private deliveryDestinations: Array<IDeliveryDestination> = [];

  private optimizationProcess = false;
  private preOptimizationBasketId?: number;

  private distributionStatusWatcher: WatchStopHandle | undefined;

  public exportingSpecification = Loader.getReactiveInstance();

  public empty = computed(
    () => !this.data.value
      || !Object.keys(this.data.value).length
      || !this.data.value.itemCount,
  );

  public optimized = computed(
    () => this.data.value?.status === EBasketInternalStatus.DraftOptimized,
  );

  constructor(
    basketId: number,
    translateFn: (key: string, plural?: number, options?: TranslateOptions<string>) => string,
  ) {
    if (Basket.instance) {
      return Basket.instance;
    }
    super(translateFn);
    Basket.instance = this;
    this.basketId = basketId;
    this.initOrderInfo();
    this.watchBasketDistributionStatus();
  }

  public killBasket(): void {
    super.killBasket();
    Basket.instance = undefined;
    this.resetOptimization();
    this.distributionStatusWatcher?.();
    this.distributionStatusWatcher = undefined;
  }

  public static async refreshBasket(): Promise<void> {
    if (Basket.instance) {
      await Basket.instance.refreshBasket();
    }
  }

  public async refreshBasket(): Promise<void> {
    this.loadingInit.activate();
    await this.loadBasket();
    this.loadingInit.deactivate();
  }

  public async loadBasket(): Promise<void> {
    try {
      this.isLoading.activate();
      await this.onLoadBasket(await this.basketManager.getBasket());
      this.clearLoaders();
    } catch (error) {
      this.initEmptyBasket();
      this.clearLoaders();
      clientSentry.captureServiceException(error, Basket.getServiceName());
    } finally {
      this.clearLoaders();
    }
  }

  private clearLoaders(): void {
    this.isLoading.deactivate();
    this.isDisableFooterLoader.deactivate();
    this.isBasketLoading.deactivate();
    this.loaderInfo.value = this.basketLoaderInfoMap?.get(EBasketActionLoaderType.Empty);
    this.loadingInit.deactivate();
  }

  public async reloadBasket(): Promise<void> {
    this.isDisableFooterLoader.deactivate();
    this.isBasketLoading.deactivate();
    this.loaderInfo.value = this.basketLoaderInfoMap?.get(EBasketActionLoaderType.Empty);
    await this.loadBasket();
  }

  async orderAlreadyPlacedModal(): Promise<void> {
    const modalName = 'basketServiceOrderAlreadyPlacedModal';
    await this.modalManager?.openAsyncDismissModal({
      bind: {
        title: 'Заказ уже оформлен',
        'click-to-close': true,
        contentTextClass: 'mm-client-employee-modal-text',
        headerClass: 'mm-client-add-employee-modal-header',
        okButtonText: 'Хорошо',
        contentText: 'Пожалуйста обновите страницу',
        name: modalName,
      },
    });
  }

  public async saveBasket(): Promise<void> {
    if (this.isLoading.value) {
      return;
    }

    this.loaderInfo.value = this.basketLoaderInfoMap?.get(EBasketActionLoaderType.Save);
    this.isLoading.activate();
    this.isBasketLoading.activate();
    try {
      const { orderNumber, id } = await this.basketManager.placeOrder({
        divisible: !!this.isDivisible.value,
        recipient: this.recipient.value as IPlaceOrderRecipient,
        deliveryDestinationId: this.deliveryDestinationId.value,
        approver: this.approverSub.value,
        approveRule: this.getClearedApproveRule(),
      }, {
        secondStep: this.options.value.fromUserId
          ? this.options.value.fromUserId !== this.data.value?.userId
          : undefined,
      });
      navigateTo({
        path: '/success-order',
        query: {
          id,
          orderNumber,
        },
      });
      Basket.basketCounter.clear();
    } catch (error) {
      const fetchError = error as FetchError;

      if ([INVALID_CART_ERROR, CARD_NOT_FOUND_ERROR]?.includes(error?.data?.data?.src)) {
        return this.orderAlreadyPlacedModal();
      }

      if (fetchError.status === BAD_REQUEST_ERROR_CODE && fetchError?.data?.data?.detail?.includes(EBasketPlacementStatus.NotSyncWithDuplicate)) {
        Notificator.showDetachedNotification('Состав корзины был изменен. Запустите оптимизацию корзины повторно.');
        this.resetOptimization();
        return await this.reloadBasket();
      }

      if ([BAD_REQUEST_ERROR_CODE, CONFLICT_ERROR_CODE].includes(error.status)) {
        Notificator.showDetachedNotification('Произошла ошибка оформления заказа. Состояние страницы будет обновлено.');
        return await this.reloadBasket();
      }

      clientSentry.captureServiceException(
        error,
        Basket.getServiceName(),
      );

      Notificator.showDetachedNotification('Ошибка оформления заказа');
    } finally {
      this.isLoading.deactivate();
      this.isBasketLoading.deactivate();
      this.loaderInfo.value = this.basketLoaderInfoMap?.get(EBasketActionLoaderType.Empty);
    }
  }

  public async updateProductCount(categoryIndex: number, productIndex: number, quantity: number): Promise<void> {
    const product = this.categoryList.value[categoryIndex]?.getProduct(productIndex);
    if (!product || product.quantity == quantity) {
      return;
    }
    this.isLoading.activate();
    const prevValue = product.quantity;
    product.quantity = quantity;

    try {
      await this.onLoadBasket(await this.basketManager.updateProductCount(product.id, quantity));
    } catch (error) {
      product.count = prevValue;
      await this.checkNotFoundBasketError(error);
    } finally {
      this.isLoading.deactivate();
    }
  }

  public async deleteProduct(categoryIndex: number, productIndex: number): Promise<void> {
    if (this.isLoading.value) {
      return;
    }

    const product = this.categoryList.value[categoryIndex]?.getProduct(productIndex);
    if (!product) {
      return;
    }

    if (Basket.basketCounter.counter.value === product.quantity) {
      const isClearBasketConfirm = await this.confirmClearBasket();

      if (!isClearBasketConfirm) {
        return;
      }

      await this.clearBasket(false);
      return;
    }

    try {
      this.isDisableFooterLoader.activate();
      this.loaderInfo.value = this.basketLoaderInfoMap?.get(EBasketActionLoaderType.Delete);
      this.isLoading.activate();
      this.isBasketLoading.activate();
      this.onLoadBasket(await this.basketManager.deleteProduct(product.id));
    } catch (error) {
      await this.checkNotFoundItemInBasketError(error);
    } finally {
      this.isDisableFooterLoader.deactivate();
      this.isLoading.deactivate();
      this.isBasketLoading.deactivate();
      this.loaderInfo.value = this.basketLoaderInfoMap?.get(EBasketActionLoaderType.Empty);
    }
  }

  private confirmClearBasket(): Promise<boolean> {
    const confirmClearBasketModal = 'confirmClearBasketModal';
    const basketModalManager = this.modalManager;

    return new Promise<boolean>((resolve) => this.modalManager?.show({
      component: Modal,
      bind: {
        name: confirmClearBasketModal,
        title: 'Вы уверены, что хотите очистить корзину?',
        okButtonText: 'Очистить корзину',
        cancelButtonText: 'Отменить',
        okBtnId: 'confirm_clear_cart_button',
        cancelBtnId: 'cancel_clear_cart_button',
        zIndex: 10,
        lockScroll: true,
        classes: 'modal fixed-bottom-modal',
      },
      on: {
        async ok(): Promise<void> {
          basketModalManager?.hide(confirmClearBasketModal);
          resolve(true);
        },
        cancel(): void {
          basketModalManager?.hide(confirmClearBasketModal);
          resolve(false);
        },
        close(): void {
          basketModalManager?.hide(confirmClearBasketModal);
          resolve(false);
        },
      },
      slots: {
        default: 'Содержимое корзины будет удалено безвозвратно.<br>Подтвердите, что хотите очистить корзину полностью.',
      },
    }));
  }

  public async addProduct(addProduct: IAddProduct): Promise<IAddProductResponse> {
    try {
      //TODO: реализовать delta (29.07.2022)
      const response = await this.basketManager.addProduct(addProduct);
      Basket.basketCounter.setCount(response.count);
      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public async clearBasket(showConfirmModal = true): Promise<void> {
    const basket = Basket.instance;

    if (basket?.isLoading.value) {
      return;
    }

    if (!showConfirmModal) {
      basket?.isLoading.activate();
      try {
        await this.basketManager.clearBasket();
        Basket.clearBasketValues();
      } finally {
        basket?.isLoading.deactivate();
      }
      return;
    }

    if (!(await basket?.confirmClearBasket())) {
      return;
    }

    try {
      basket?.isLoading.activate();
      basket?.isBasketLoading.activate();
      this.onLoadBasket(await this.basketManager.clearBasket());
      Basket.clearBasketValues();
    } finally {
      basket?.isLoading.deactivate();
      basket?.isBasketLoading.deactivate();
    }
  }

  public static clearBasketValues(): void {
    Basket.basketCounter.clear();
    Basket.instance?.initEmptyBasket();
  }

  public async exportSpecification(): Promise<void> {
    if (!this.basketId) {
      return;
    }

    this.exportingSpecification.activate();

    try {
      const response = await this.basketManager.downloadSpecification();
      downloadFileByBlob(response.data, FilesService.getFileNameFromResponse(response));
    } catch (error) {
      clientSentry.captureServiceException(
        error,
        BaseBasket.getServiceName(),
        undefined,
        {
          extra: {
            basket: this.basketId,
          },
        },
      );

      Notificator.showDetachedNotification('При формировании файла произошла ошибка. Попробуйте еще раз или  обратитесь в поддержку support@maksmart.ru');
    } finally {
      this.exportingSpecification.deactivate();
    }
  }

  public async onLoadBasket(basketData?: IBasket): Promise<void> {
    if (!basketData) {
      return this.handleEmptyBasketData();
    }

    await this.compareBasis(basketData);

    if (!Object.keys(basketData).length || !basketData?.itemCount) {
      const notEmptyBasketType = (basketData as IBasket)?.sum?.find((sumItem) => sumItem.sum);
      if (notEmptyBasketType) {
        this.isLoading.deactivate();
        await this.selectBasketType(notEmptyBasketType.type);
        return;
      }
      Basket.basketCounter.clear();
      this.initEmptyBasket();
      return;
    }

    this.setTypeList(basketData.sum);
    if (await this.correctBasketType(basketData)) {
      return;
    }

    this.initBasket(basketData as IBasket);
    await this.checkCartReportStatus(basketData.cartReport);
  }

  private watchBasketDistributionStatus() {
    this.distributionStatusWatcher = watch(this.basketManager.currentBasketDistributionStatus, (newStatus, oldStatus) => {
      if (newStatus === EERpBasketDistributionStatus.Fail && oldStatus === EERpBasketDistributionStatus.InProgress) {
        Notificator.showDetachedNotification(
          this.optimizationProcess
            ? 'Во время оптимизации корзины произошла ошибка. Попробуйте начать оптимизацию еще раз.'
            : 'Произошла ошибка во время подбора предложений',
        );

        this.optimizationProcess && this.resetOptimization();
      }
    });
  }

  public async optimizeBasket(allowAnalogs?: boolean): Promise<void> {
    if (!this.basketManager.basketId.value
      || this.isLoading.value
    ) {
      return;
    }

    this.isLoading.activate();
    this.isBasketLoading.activate();
    this.loaderInfo.value = this.basketLoaderInfoMap.get(EBasketActionLoaderType.Optimization);

    try {
      this.preOptimizationBasketId = this.basketManager.basketId.value;
      await this.basketManager.optmizeBasket(allowAnalogs);
      this.optimizationProcess = true;

      await this.basketManager.startPollingDistributionStatus();

    } catch (error) {
      clientSentry.captureServiceException(
        error,
        Basket.getServiceName(),
        undefined,
        {
          extra: {
            allowAnalogs,
            basketData: this.data.value,
          },
        },
      );

      Notificator.showDetachedNotification('Ошибка при оптимизиации корзины');
      this.resetOptimization();
      this.clearLoaders();
    }
  }

  public async resetOptimizedBasket(): Promise<void> {
    if (this.isLoading.value) {
      return;
    }

    this.resetOptimization();
    await this.loadBasket();
  }

  private initBasket(basketData: IBasket): void {
    this.data.value = basketData;
    Basket.basketCounter.setCount(basketData?.itemCount ?? 0);
    this.status.value = basketData.status || '';
    this.errors.value = basketData.errors;
    this.setTypeList(basketData.sum);
    this.beId.value = basketData.beId;
    this.categoryList.value = basketData.categories?.map((category) => new BasketCategory(category)) || [];
    this.isDivisible.value = basketData.divisible === null;
    this.isNeedApproval.value = basketData.isNeedApproval;
    this.isInventory.value = !!basketData.isInventory;
    this.setBasketStatus(basketData.placementStatus);
    this.setBasketId(basketData.id);
  }

  public async startSaveFlow(): Promise<void> {
    for (const currentFn of this.preSaveBasketFlow) {
      const aborted = await new Promise((resolve) => currentFn?.call(
        this, () => resolve(false), () => resolve(true),
      ));

      if (aborted) {
        return;
      }
    }

    await this.saveBasket();
  }

  private openRecipientModal(next: () => void, abort: () => void): void {
    if (this.isInventory.value) {
      this.setBasketRecipient({
        name: StringsHelper.arrayToString([
          this.userStore.userInfo?.lastName,
          this.userStore.userInfo?.name,
          this.userStore.userInfo?.patronymic,
        ], ' '),
        mobilePhone: this.userStore.userInfo?.phone || null,
        workPhone: this.userStore.userInfo?.beWorkPhone,
        additionalCode: this.userStore.userInfo?.additionalCode,
        sub: this.userStore.userInfo?.sub,
      });
      next();
      return;
    }

    const modalName = 'openRecipentModal';
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const currentObject = this;
    this.modalManager?.show({
      component: AddRecipentModal,
      bind: {
        title: 'Укажите получателя',
        basketService: this,
        name: modalName,
      },
      on: {
        close() {
          currentObject.modalManager?.hide(modalName);
          abort();
        },
        async ok() {
          currentObject.modalManager?.hide(modalName);
          next();
        },
      },
    });
  }

  private isNeedChooseApproverModal(): boolean {
    if (this.approveRule.value) {
      /** показывать модалку, если есть настроенное правило согласования на базисе и в нем есть из кого выбирать **/
      return this.isApproveRuleHasManualSelect();
    }
    /** показывать модалку, если требуется согласование по настройкам BE и нет роли руководителя **/
    return this.isNeedApproval.value && !this.userStore.isUserHasGroup(EUserGroup.ClientBoss);
  }

  private async startApproveFlow(next: () => void, abort: () => void): Promise<void> {
    this.setApproveRule(await ApproveRuleApiService.getBasisApproveRuleByBasisId(this.userStore?.selectedBase?.id as number));
    if (!this.isNeedChooseApproverModal()) {
      return next();
    }

    const hasNextStep = await this.isShowSelectDeliveryModal(true);
    const modalName = 'ChooseApproverModal';
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const currentObject = this;
    this.modalManager?.show({
      bind: {
        title: 'Укажите согласующее лицо',
        name: modalName,
        beId: this.beId.value,
        okButtonText: hasNextStep ? 'Продолжить' : 'Оформить заказ',
        approveRule: this.isApproveRuleHasManualSelect() ? this.approveRule.value : undefined,
      },
      component: ChooseApproverModal,
      on: {
        close() {
          currentObject.modalManager?.hide(modalName);
          abort();
        },
        ok(approverSub: string | IBasisApproveRule) {
          currentObject.modalManager?.hide(modalName);

          if (currentObject.isApproveRuleHasManualSelect() && typeof approverSub !== 'string') {
            currentObject.setApproveRule(approverSub as IBasisApproveRule);
          } else {
            currentObject?.setApprover(approverSub as string);
          }

          next();
        },
      },
    });
  }

  private async isShowSelectDeliveryModal(force = false): Promise<boolean> {
    if (!this.deliveryDestinations.length || force) {
      try {
        this.isLoading.activate();
        this.deliveryDestinations = await BasesApiService.getDeliveryDestinationsByBasesId(this.basketManager.selectedBaseId.value as number);
      } catch (error) {
        Notificator.showDetachedNotification('Ошибка при загрузке мест доставки');
      } finally {
        this.isLoading.deactivate();
      }
    }

    if (!this.deliveryDestinations?.length) {
      return false;
    }

    if (this.deliveryDestinations?.length === 1) {
      this.deliveryDestinationId.value = this.deliveryDestinations[0]?.id;
      return false;
    }

    return true;
  }

  private async openSelectDeliveryModal(next: () => void, abort: () => void): Promise<void> {
    if (!await this.isShowSelectDeliveryModal()) {
      next();
      return;
    }

    const modalName = 'selectDeliveryAddressBasketModal';
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const currentObject = this;
    this.modalManager?.show({
      component: SelectDeliveryAddressBasketModal,
      bind: {
        name: modalName,
        deliveryDestinations: this.deliveryDestinations,
        basesName: this.basesManager?.base,
      },
      on: {
        submit(deliveryDestinationId: number): void {
          currentObject.modalManager?.hide(modalName);
          currentObject.deliveryDestinationId.value = deliveryDestinationId;
          next();
        },
        close() {
          currentObject.modalManager?.hide(modalName);
          abort();
        },
      },
    });
  }

  private async compareBasis(basketData: IBasket): Promise<void> {
    if (this.basesManager?.currentUserBase?.id === basketData.basisId
      && (
        (this.basesManager?.currentUserBase?.subdivisionId ?? null) === (basketData.subdivisionId ?? null)
      )
    ) {
      return;
    }
    await this.basesManager?.updateCurrentUserBasis();
  }

  private async checkNotFoundBasketError(error: unknown): Promise<boolean> {
    if (![error?.status, error?.statusCode].includes(NOT_FOUND_ERROR_CODE)) {
      return false;
    }

    Notificator.showDetachedNotification('Корзина была удалена из-за технических обновлений, страница будет перезагружена.');
    await this.basesManager?.forceCheckUserBasis(true);
    await this.reloadBasket();
    return true;
  }

  private async checkNotFoundItemInBasketError(error: unknown): Promise<boolean> {
    if (![error?.status, error?.statusCode].includes(BAD_REQUEST_ERROR_CODE)) {
      return false;
    }

    Notificator.showDetachedNotification('Произошла ошибка, товар уже был удален из корзины, страница будет перезагружена.');
    await this.basesManager?.forceCheckUserBasis(true);
    await this.reloadBasket();
    return true;
  }

  private isApproveRuleHasManualSelect(): boolean {
    return !!this.approveRule.value?.approveGroups.some(
      (approveGroup) => approveGroup.isSelectable && !!approveGroup.approvers?.length,
    );
  }

  private getClearedApproveRule(): IBasisApproveRule | null {
    if (!this.approveRule.value) {
      return null;
    }

    return {
      ...this.approveRule.value,
      approveGroups: this.approveRule.value.approveGroups.map(
        (approveGroup) => ({
          ...approveGroup,
          approversFio: undefined,
        }),
      ),
    };
  }

  private handleEmptyBasketData(): Promise<void> | undefined {
    if (this.optimizationProcess) {
      return this.showNotFoundOptimizedBasketModal();
    }

    this.initEmptyBasket();
  }

  private async showNotFoundOptimizedBasketModal(): Promise<void> {
    await this.modalManager?.showConfirmModal(
      'Более оптимальный вариант корзины не найден',
      'Сейчас в ней собраны лучшие предложения, вы можете оформить заказ',
      'Закрыть',
      undefined,
      {
        hideCloseButton: true,
        hideCancelButton: true,
      },
    );

    this.resetOptimization();
  }

  private resetOptimization(): void {
    if (this.preOptimizationBasketId) {
      this.basketManager.setBasketId(this.preOptimizationBasketId);
    }

    this.optimizationProcess = false;
    this.preOptimizationBasketId = undefined;
  }
}

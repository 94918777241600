/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BasisRetrieveSchema } from './BasisRetrieveSchema';
import type { CustomerCompleteRetrieveSchema } from './CustomerCompleteRetrieveSchema';
import type { DeclinedOrderItemRetrieveSchema } from './DeclinedOrderItemRetrieveSchema';
import type { ERPDistributionEnum } from './ERPDistributionEnum';
import type { ERPSupplierOrderCompleteRetrieveSchema } from './ERPSupplierOrderCompleteRetrieveSchema';
import type { RecipientCompleteRetrieveSchema } from './RecipientCompleteRetrieveSchema';
import type { schemas__erp_order__OrderLimitsSchema } from './schemas__erp_order__OrderLimitsSchema';
export type ERPOrderRetrieveSchema = {
    id: number;
    number: string;
    status: ERPOrderRetrieveSchema.status;
    source: string;
    divisible: boolean;
    delivery: string;
    clientId: number;
    userId: string;
    customer: CustomerCompleteRetrieveSchema;
    recipient: RecipientCompleteRetrieveSchema;
    basis: BasisRetrieveSchema;
    beId: number;
    subdivisionId?: number;
    costVat: number;
    costNet: number;
    deliveryCost?: number;
    deliveryVatCost?: number;
    costWithVat: number;
    costWithDelivery: number;
    costWithVatAndDelivery: number;
    summaryVat?: number;
    isDeliveryInboundRequired?: boolean;
    isReadyOrLater?: boolean;
    purchaseOrder?: string;
    isPurchaseOrderEditable?: boolean;
    createTs: string;
    registrationDate?: string;
    items: Array<ERPSupplierOrderCompleteRetrieveSchema>;
    erpOrderId?: number;
    limits: Array<schemas__erp_order__OrderLimitsSchema>;
    declinedModels: Array<DeclinedOrderItemRetrieveSchema>;
    erpDistribution?: ERPDistributionEnum;
};
export namespace ERPOrderRetrieveSchema {
    export enum status {
        DRAFT = 'draft',
        ACTIVE = 'active',
        ACTION_REQUIRED = 'action_required',
        DONE = 'done',
        CANCELED = 'canceled',
    }
}


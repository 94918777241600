<template>
  <div
    :class="{
      'mm-table--not-pagination': !isPaginationPaddingHidden && !isPaginationVisible,
      'mm-table--fixed-cols-wrap': fixedColumn,
      'mm-table--with-shadow': enableShadow,
      'mm-table--with-selectable-control': selectableControl,
    }"
  >
    <div
      :id="tableId"
      class="mm-table"
      :class="{
        'mm-table--disabled': isLoading,
        'mm-table--full-loading': isFullLoading,
        'mm-table--fixed-cols-scroller': fixedColumn,
        'mm-table--with-shadow-wrap': enableShadow,
        'mm-table--header-sticky': isStickyThead,
      }"
    >
      <ClipLoader
        v-if="isLoading"
        class="mm-table__loader"
        :class="{
          'mm-table__loader--centered': needToShowEmptyDataBanner,
        }"
      />
      <ClipLoader
        v-if="isFullLoading"
        class="mm-clip-loader--full mm-table__loader mm-table__loader--centered"
        :placeholder="fullLoadingPlaceholder"
      />
      <div
        v-if="isLeftShadowActive || isNeedLeftShadow"
        class="shadow-left"
      />
      <div
        v-if="isRightShadowActive"
        class="shadow-right"
      />

      <ConditionalWrapper
        ref="perfectScrollbarRef"
        :wrap="Boolean(fixedColumn)"
        :wrapper="PerfectScrollbar"
        @ps-scroll-x="onHorizontalScrollChange"
        @ps-scroll-y="onScrollChange"
      >
        <table
          ref="tableRef"
          class="mm-table__table"
          :class="{
            'mm-table--theme-default': !isThemeSeparated,
            'mm-table--theme-separated': isThemeSeparated,
            'mm-table--children': !!childrenDataKey,
            'mm-table--separate-columns': isColumnsSeparated,
            'mm-table--fixed-cols': fixedColumn,
            'mm-table--header-in-row': tableHeaderInRow,
          }"
        >
          <thead
            v-if="!tableHeaderInRow"
            class="mm-table__head"
            :class="{ 'mm-table__head-sticky': isStickyThead }"
          >
            <slot name="headerSortSection" />

            <tr class="mm-table__header-row">
              <th
                v-for="column in internalColumns"
                :key="`${column.field}_${updateVersion}`"
                :class="{ [column.cssClass]: column.cssClass }"
                :style="{ minWidth: column.minWidth, width: column.width, textAlign: column.align || 'left' }"
              >
                <template v-if="column.visible !== false">
                  <div>
                    <Checkbox
                      v-if="isValidSelectableSection(column.field, ETableSelectableSectionColumnType.Header)"
                      class="mm-table__header-selectable-checkbox"
                      :model-value="isAllSelectedItems"
                      active-border
                      :disabled="isSelectableColumnDisabled"
                      :check-box-id="tableCheckBoxId"
                      @update:model-value="onAllRowsSelect($event)"
                    />

                    <slot
                      v-else
                      :name="column.headerSlotName"
                    >
                      <div
                        :id="tableHeaderRowContent"
                        class="mm-table__header-row-content"
                        :class="[
                          {
                            ...getSortableAdditionalClass(column)
                          },
                          column.cellCssClass,
                          {
                            'mm-table__header-row-content--disabled': disabledHeadSort
                          }
                        ]"
                        @click="column.sortable && handleSort(column.field)"
                      >
                        {{ column.title }}
                        <slot
                          v-if="column.hasContent"
                          name="headerContent"
                        />
                        <div
                          v-if="column.sortable"
                          class="mm-table__sort-icon"
                        >
                          <SvgIcon :src="EIconPath.NavigationArrowDownSmallSvg" />
                          <SvgIcon :src="EIconPath.NavigationArrowUpSmallSvg" />
                        </div>
                      </div>
                    </slot>
                  </div>
                </template>
              </th>
              <th v-if="isAccordion && isNeedAccordionAction">
                <slot name="accordionColumnHeader" />
              </th>
            </tr>
          </thead>

          <template v-if="tableHeaderInRow">
            <tr
              v-if="!internalData?.length"
              class="mm-table__row mm-table__row--empty"
            >
              <td
                align="center"
                :colspan="internalColumns.length"
              >
                Нет данных для отображения
              </td>
            </tr>
            <template v-else>
              <tbody class="mm-table__body">
                <tr
                  v-for="(columnRow, columnRowIndex) in internalColumns"
                  :key="getRowKey?.(columnRow) || columnRowIndex"
                  :class="['mm-table__row', getAdditionalRowClass?.(columnRow)]"
                  @click="onRowClick({ columnRow, columnRowIndex, event: $event })"
                >
                  <td
                    v-for="(cell, cellIndex) in internalData"
                    :key="`${cellIndex}_${updateVersion}`"
                    :style="{ textAlign: columnRow.align || 'left', ...(getCellStyle?.(cell, columnRow) || {}) }"
                    :class="{ [columnRow.cellCssClass]: columnRow.cellCssClass }"
                  >
                    <div
                      v-if="columnRow.title && !(columnRow.checkbox && cell.checkbox)"
                      class="mm-table--header-in-row__column-row-title"
                    >
                      {{ cellIndex ? '' : columnRow.title }}
                    </div>

                    <slot
                      name="header-in-row-checkbox-tooltip"
                      :row="cell"
                      :column="columnRow"
                    />

                    <slot
                      :name="columnRow.field"
                      :index="columnRowIndex"
                      :row="cell"
                    >
                      <div>
                        <Checkbox
                          v-if="isValidSelectableSection(columnRow.field, ETableSelectableSectionColumnType.Cell)"
                          v-model="cell.selected"
                          :disabled="isSelectableColumnDisabled || cell?.disabledSelect"
                          @update:model-value="onRowSelect({ cell, cellIndex, value: $event })"
                        />
                        <div
                          v-else
                          v-html="sanitize(getCellValue(cell, columnRow) || '—')"
                        />
                      </div>
                    </slot>
                  </td>

                  <td class="mm-table--header-in-row__column-row-empty" />

                  <slot
                    name="tooltip"
                    :index="columnRowIndex"
                    :row="columnRow"
                  />
                </tr>
              </tbody>
            </template>
          </template>

          <template v-else-if="isAccordion">
            <tr
              v-if="!internalData?.length"
              key="table-not-data"
              class="mm-table__row mm-table__row--empty"
            >
              <td
                align="center"
                :colspan="internalColumns.length + 1"
              >
                Нет данных для отображения
              </td>
            </tr>

            <tbody
              v-for="(row, rowIndex) in internalData"
              :id="tableBodyId"
              :key="getRowKey?.(row) || rowIndex"
              ref="tableTbodyRefs"
              :class="['mm-table__body', getAdditionalRowClass?.(row), { expanded: isExpandedAccordion(rowIndex), highlighted: props.highlightedIndex === rowIndex }]"
              @mouseover="onMouseoverRow(rowIndex)"
              @mouseout="onMouseoutRow()"
            >
              <tr
                :class="['mm-table__row', { 'mm-table__expanded-anchor-row expanded': isExpandedAccordion(rowIndex) }]"
                @click="onRowClick({ row, rowIndex, event: $event })"
              >
                <template v-if="useMobileContent ? isMobile || isSmallMobile : false">
                  <slot
                    name="mobileContent"
                    :row="row"
                    :index="rowIndex"
                    :is-expanded="isExpandedAccordion(rowIndex)"
                  />
                </template>

                <template v-else>
                  <td
                    v-for="(column, cellIndex) in internalColumns"
                    :key="`${column.field}_${updateVersion}`"
                    :style="{
                      textAlign: column.align || 'left',
                      ...((isShowRowShadow(rowIndex, cellIndex) && getRowShadowStyle(rowIndex, tableTbodyRefs)) || {}),
                    }"
                    :class="{
                      'mm-table__data-cell': true,
                      'mm-table__row-shadow': isShowRowShadow(rowIndex, cellIndex),
                      [column.cellCssClass]: column.cellCssClass
                    }"
                  >
                    <slot
                      :name="column.field"
                      :row="row"
                      :index="rowIndex"
                      :is-expanded="isExpandedAccordion(rowIndex)"
                    >
                      <div>
                        <Checkbox
                          v-if="isValidSelectableSection(column.field, ETableSelectableSectionColumnType.Cell)"
                          v-model="row.selected"
                          :disabled="isSelectableColumnDisabled || row?.disabledSelect"
                          @update:model-value="onRowSelect({ row, rowIndex, value: $event })"
                        />

                        <div
                          v-else
                          v-html="sanitize(column.internalKey ? row[column.field]?.[column.internalKey] : row[column.field])"
                        />
                      </div>
                    </slot>
                  </td>
                </template>

                <td
                  v-if="isAccordion && isNeedAccordionAction"
                  class="mm-table__action-cell"
                >
                  <button
                    :class="['btn btn-round btn-outlined mm-table__expand-btn',
                             {'mm-b-none': !isExpandedAccordion(rowIndex)}
                    ]"
                    @click="onExpandRowClick({ row, rowIndex, value: !isExpandedAccordion(rowIndex) })"
                  >
                    <SvgIcon :src="`navigation/arrow-${isExpandedAccordion(rowIndex) ? 'up' : 'down'}`" />
                  </button>
                </td>
              </tr>
              <tr
                v-if="isExpandedAccordion(rowIndex)"
                :class="{ 'mm-table_expanded-divider__row': isExpandedAccordion(rowIndex) }"
                rowspan="2"
              >
                <td
                  class="mm-table_expanded-divider__table-data"
                  :colspan="isAccordion ? internalColumns.length + 1 : internalColumns.length"
                >
                  <div class="mm-table_expanded-divider__line" />
                </td>
              </tr>
              <slot
                v-if="isExpandedAccordion(rowIndex)"
                name="expandedRow"
                :row="row"
                :index="rowIndex"
                :columns-length="internalColumns.length + 1"
                :is-expanded="isExpandedAccordion(rowIndex)"
              >
                <tr class="mm-table__expanded-container-row expanded">
                  <slot
                    name="expandedCells"
                    :row="row"
                    :index="rowIndex"
                    :columns-length="internalColumns.length + 1"
                  >
                    <td
                      class="mm-table__expanded-container"
                      :colspan="internalColumns.length + 1"
                    >
                      <div class="mm-table__expanded-content">
                        <slot
                          name="expandedContent"
                          :index="rowIndex"
                          :row="row"
                        />
                      </div>
                    </td>
                  </slot>
                </tr>
              </slot>

              <div
                v-if="isShowMobileAccordionBtn"
                class="mm-table__action-cell mobile-action-cell"
              >
                <button
                  :class="['btn btn-round btn-outlined mm-table__expand-btn',
                           {'mm-b-none': !isExpandedAccordion(rowIndex)}
                  ]"
                >
                  <SvgIcon :src="`navigation/arrow-${isExpandedAccordion(rowIndex) ? 'up' : 'down'}`" />
                </button>
              </div>
            </tbody>

            <tbody
              v-if="isShowMoreButtonVisible"
              id="table-accordion-show-more-tbody"
              key="table-accordion-show-more-tbody"
              class="mm-table__body mm-table__body--show-more"
            >
              <tr
                key="table-accordion-show-more-tr"
                class="mm-table__row mm-table__row--show-more"
              >
                <td
                  key="table-accordion-show-more-td"
                  :colspan="internalColumns.length + 1"
                >
                  <div key="table-accordion-show-more-div">
                    <slot name="showMoreButton">
                      <LoaderButton
                        key="table-accordion-show-more-btn"
                        class="mm-table__row mm-table__row--show-more--btn"
                        :loader="apiUrl ? internalShowMoreDisabler : showMoreBtnParams?.loader"
                        :icon-src="showMoreBtnParams?.iconSrc || EIconPath.IndicatorsProgressDarkSvg"
                        :button-text="showMoreBtnParams?.buttonText || `Показать ещё ${showMoreCount}`"
                        :btn-classes="showMoreBtnParams?.btnClasses || ['btn-secondary', 'mm-font-500']"
                        :custom-disabler="disablePagination"
                        @confirm="onShowMore"
                      />
                    </slot>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>

          <template v-else>
            <tr
              v-if="!internalData?.length && !isLoading"
              class="mm-table__row mm-table__row--empty"
            >
              <td
                align="center"
                :colspan="internalColumns.length"
              >
                Нет данных для отображения
              </td>
            </tr>
            <template v-else>
              <tbody
                v-if="!childrenDataKey"
                class="mm-table__body"
              >
                <tr
                  v-for="(row, rowIndex) in internalData"
                  :id="tableRowId"
                  :key="getRowKey?.(row) || rowIndex"
                  ref="tableRowsRefs"
                  :class="['mm-table__row', getAdditionalRowClass?.(row), getRowClass?.(row, rowIndex)]"
                  @click="onRowClick({ row, rowIndex, event: $event })"
                  @mouseover="onMouseoverRow(rowIndex)"
                  @mouseout="onMouseoutRow()"
                >
                  <td
                    v-for="(column, cellIndex) in internalColumns"
                    :id="column.cellId"
                    :key="`${column.field}_${updateVersion}`"
                    :style="{
                      textAlign: column.align || 'left',
                      ...(getCellStyle?.(row, column) || {}),
                      ...((isShowRowShadow(rowIndex, cellIndex) && getRowShadowStyle(rowIndex)) || {}),
                    }"
                    :class="{
                      [column.cellCssClass]: column.cellCssClass,
                      'mm-table__row-shadow': isShowRowShadow(rowIndex, cellIndex),
                    }"
                    :colspan="column.colspan"
                  >
                    <slot
                      :name="column.field"
                      :index="rowIndex"
                      :row="row"
                    >
                      <div>
                        <Checkbox
                          v-if="isValidSelectableSection(column.field, ETableSelectableSectionColumnType.Cell)"
                          v-model="row.selected"
                          :check-box-id="tableCheckBoxId"
                          :disabled="isSelectableColumnDisabled || row?.disabledSelect"
                          @update:model-value="onRowSelect({ row, rowIndex, value: $event })"
                        />
                        <div
                          v-else
                          v-html="sanitize(getCellValue(row, column))"
                        />
                      </div>
                    </slot>
                  </td>
                  <slot
                    name="tooltip"
                    :index="rowIndex"
                    :row="row"
                  />
                </tr>
                <tr
                  v-show="needToShowEmptyDataBanner"
                  class="mm-table__row"
                >
                  <td
                    align="center"
                    :colspan="internalColumns.length + 1"
                  >
                    Нет данных для отображения
                  </td>
                </tr>
              </tbody>
              <tbody
                v-for="(row, rowIndex) in internalData"
                v-else
                :id="tableBodyId"
                :key="getRowKey?.(row) ?? rowIndex"
                ref="tableTbodyRefs"
                class="mm-table__body mm-table__body--children"
                @mouseover="onMouseoverRow(rowIndex)"
                @mouseout="onMouseoutRow()"
              >
                <slot
                  v-if="getIsEmptyRow?.(row)"
                  name="emptyRow"
                  :row="row"
                  :index="rowIndex"
                />
                <template v-else>
                  <tr
                    :class="['mm-table__row', getAdditionalRowClass?.(row), getRowClass?.(row, rowIndex)]"
                    @click="onRowClick({ row, rowIndex, event: $event })"
                  >
                    <td
                      v-for="(column, cellIndex) in internalColumns.filter((col) => !col.isInner)"
                      :key="`${column.field}_${updateVersion}`"
                      :rowspan="getParentRowspan(column, row)"
                      :style="{
                        textAlign: column.align || 'left',
                        ...((isShowRowShadow(rowIndex, cellIndex) && getRowShadowStyle(rowIndex, tableTbodyRefs)) || {}),
                      }"
                      :class="{
                        [column.cellCssClass]: column.cellCssClass,
                        'mm-table__row-shadow': isShowRowShadow(rowIndex, cellIndex),
                      }"
                    >
                      <slot
                        :name="column.field"
                        :row="row"
                        :index="rowIndex"
                        :child-row="column.isInner ? row[childrenDataKey]?.[0] : undefined"
                      >
                        <div>
                          <Checkbox
                            v-if="isValidSelectableSection(column.field, ETableSelectableSectionColumnType.Cell)"
                            v-model="row.selected"
                            :disabled="isSelectableColumnDisabled || row?.disabledSelect"
                            @update:model-value="onRowSelect({ row, rowIndex, value: $event })"
                          />
                          <div
                            v-else
                            v-html="sanitize(getCellValue(column.isInner ? row[childrenDataKey]?.[0] : row, column))"
                          />
                        </div>
                      </slot>
                    </td>
                  </tr>
                  <slot
                    name="insideChildRows"
                    :row="row"
                  />
                  <template v-if="(row[childrenDataKey] || []).length">
                    <template
                      v-for="(childRow, childRowIndex) in row[childrenDataKey] || []"
                      :key="childRowIndex"
                    >
                      <slot
                        v-if="getIsEmptyChildRow?.(childRow, row)"
                        name="emptyChildRow"
                        :row="row"
                        :index="rowIndex"
                        :child-index="childRowIndex"
                        :child-row="childRow"
                      />
                      <template v-else>
                        <tr
                          :class="['mm-table__row', getRowClass?.(row, rowIndex, childRow, childRowIndex)]"
                          @click="onRowClick({ row: childRow, rowIndex: childRowIndex, event: $event })"
                        >
                          <td
                            v-for="column in internalColumns.filter((col) => col.isInner)"
                            :key="`${column.field}_${updateVersion}`"
                            :style="{ textAlign: column.align || 'left' }"
                            :class="[column.cellCssClass, getCellClass?.(row, rowIndex, childRow, childRowIndex)]"
                          >
                            <slot
                              :name="column.field"
                              :row="row"
                              :index="rowIndex"
                              :child-index="childRowIndex"
                              :child-row="childRow"
                            >
                              <div v-html="sanitize(getCellValue(childRow, column))" />
                            </slot>
                          </td>
                        </tr>
                        <template v-if="getAdditionalChildRows?.(childRow, row)">
                          <slot
                            name="additionalChildRows"
                            :row="row"
                            :index="rowIndex"
                            :child-index="childRowIndex"
                            :child-row="childRow"
                          />
                        </template>
                      </template>
                    </template>
                  </template>
                  <slot
                    v-else
                    name="notChildren"
                    :row="row"
                    :index="rowIndex"
                  />
                </template>
              </tbody>
            </template>

            <tr
              v-if="props.isShowMoreBtnAvailable && isShowMoreButtonVisible"
              key="table-show-more-tr"
              class="mm-table__row mm-table__row--show-more"
            >
              <td
                key="table-show-more-td"
                :colspan="internalColumns.length"
              >
                <div class="table-show-more-div">
                  <slot name="showMoreButton">
                    <LoaderButton
                      key="table-show-more-btn"
                      class="mm-table__row mm-table__row--show-more--btn"
                      :loader="apiUrl ? internalShowMoreDisabler : showMoreBtnParams?.loader"
                      :icon-src="showMoreBtnParams?.iconSrc || EIconPath.IndicatorsProgressDarkSvg"
                      :btn-classes="showMoreBtnParams?.btnClasses || ['btn-secondary', 'mm-font-500']"
                      :button-text="showMoreBtnParams?.buttonText || `Показать ещё ${internalMoreNumber}`"
                      :custom-disabler="disablePagination"
                      @confirm="onShowMore"
                    />
                  </slot>
                </div>
              </td>
            </tr>
            <tr
              v-else-if="!props.isShowMoreBtnAvailable"
              class="mm-table__row mm-table__row--show-more"
            >
              <td :colspan="internalColumns.length">
                <div
                  class="mm-table__row mm-table__row--show-more--text"
                >
                  {{ itemsShownText }}
                </div>
              </td>
            </tr>
          </template>
        </table>
      </ConditionalWrapper>
    </div>

    <Pagination
      v-if="isPaginationVisible"
      ref="paginationRef"
      v-bind="pagination"
      :selected-page-size="pageSize"
      :page-size-list="pageSizeList"
      :is-selectable-page-size="isSelectablePageSize"
      :disabled="disablePagination || (apiUrl ? internalShowMoreDisabler?.value : showMoreBtnParams?.loader?.value)"
      @page-changed="onPageChanged"
      @page-size-changed="onPageSizeChanged"
      @on-next-page="$emit('onNextPage')"
      @on-prev-page="$emit('onPrevPage')"
    />

    <slot
      v-if="selectableMode && selectableControl"
      name="selectableControl"
    >
      <TableSelectableControl
        :cancel-text="selectableControlCancelText"
        :accept-text="selectableControlAcceptText"
        :cancel-disabled="selectableControlCancelDisabled || loading"
        :accept-disabled="selectableControlAcceptDisabled || loading"
        @cancel="onCancelTableSelectableControl"
        @accept="onAcceptTableSelectableControl"
      />
    </slot>
  </div>
</template>

<script setup lang="ts">
import SvgIcon from './SvgIcon.vue';
import { sanitize } from '../directives/sanitize';
import {
  IColumnHeader,
  IGetTableDataParams,
  ITableAllRowsSelectEvent,
  ITableDataResponse,
  ITableExpandRowClickEvent,
  ITablePagination,
  ITableRowClickEvent,
  ITableRowSelectEvent,
  ITableShowMoreBtnProps,
  ITableSort,
} from '../models/table.model';
import { ETableThemes } from '../enums/tableThemes.enum';
import { ESortState } from '../enums/sortState.enum';
import { cloneDeep, isEqual } from 'lodash-es';
import WindowWidthManager from '../services/windowWidth.service';
import { paginationSkip } from '../utils/paginationSkip.util';
import Pagination from './Pagination.vue';
import { ref, watch } from '@vue/runtime-core';
import { useUpdate } from '../composables/useUpdate';
import { computed, StyleValue } from 'vue';
import ConditionalWrapper from './ConditionalWrapper.vue';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import ClipLoader from './ClipLoader.vue';
import { WatchSubscription } from '../utils/watchSubscription';
import useSSRUnsubscribeWatch from '../composables/useSSRUnsubscribeWatch';
import LoaderButton from './LoaderButton.vue';
import TableSelectableControl from './TableSelectableControl.vue';
import { EIconPath } from '../enums/iconPath.enum';
import Disabler from '../utils/disablerHelper.util';
import { TableUtils } from '../utils/table.util';
import { ETableSelectableSectionColumnType } from '../enums/components/table/tableSelectableSectionColumnType.enum';
import { TABLE_SELECTABLE_SECTION_FIELD } from '../constants/table.const';

const props = withDefaults(
  defineProps<{
    // Заголовки столбцов таблицы
    columns: Array<IColumnHeader>;
    // Данные для отображения
    data?: Array<Record<string, unknown>>;
    // Параметры пагинации
    pagination?: ITablePagination;
    // API для подгрузки данных
    apiUrl?: string;
    // Аккордеонные строки таблицы
    isAccordion?: boolean;
    // Должна ли раскрываться строка при клике
    expandOnRowClick?: boolean;
    // Индексы раскрывающихся строк
    expandedRowIndexes?: Array<number>;
    // Должна ли первая строка быть развернута по умолчанию
    isFirstRowOpened?: boolean;
    // Отключить пагинацию и отображать все
    isNonPageable?: boolean;
    // Количество отображаемых строк
    showRowsCount?: number;
    // Количество строк, отображаемых при клике на "показать еще"
    showMoreCount?: number;
    // Тема таблицы
    theme?: ETableThemes;
    // Режим выбора строк
    selectableMode?: boolean;
    // Режим выбора одной строки
    selectableSingleMode?: boolean;
    // Следует ли отображать контролы для выбора строк
    selectableControl?: boolean;
    // Текст кнопки отмены в режиме выбора строк
    selectableControlCancelText?: string;
    // Текст кнопки подтверждения в режиме выбора строк
    selectableControlAcceptText?: string;
    // Отключить кнопку отмены в режиме выбора строк
    selectableControlCancelDisabled?: boolean;
    // Отключить кнопку подтверждения в режиме выбора строк
    selectableControlAcceptDisabled?: boolean;
    // Сбрасывать ли выбор после подтверждения
    resetRowsAfterConfirmSelectableControl?: boolean;
    // Количество отображаемых по умолчанию строк
    size?: number;
    // Скрыть ли кнопку "Показать еще"
    isShowMoreBtnHidden?: boolean;
    // Отключен ли выбор строк в таблице
    isSelectableColumnDisabled?: boolean;
    // Ключ для доступа к дочерним компонентам в каждой строке
    childrenDataKey?: string;
    // Следует ли визуально разделять столбцы
    isColumnsSeparated?: boolean;
    // Сортировка данных в таблице
    sort?: ITableSort | null;
    // Должна ли кнопка "показать еще" находится вне таблицы
    isShowMoreBtnOutsideControl?: boolean;
    // Происходит ли в данный момент загрузка
    loading?: boolean;
    // Следует ли отображать лоадер
    showLoader?: boolean;
    // Следует ли отображать контролы пагинации
    isPaginationShown?: boolean;
    // Следует ли убрать паддинги пагинации
    isPaginationPaddingHidden?: boolean;
    // Нужно ли скрыть элементы пагинации
    hidePagination?: boolean;
    // Нужно ли отключить пагинацию
    disablePagination?: boolean;
    // Следует ли отключить внутренний рассчет видимых строк
    disableInternalCalcVisibleRows?: boolean;
    // Следует ли зафиксировать первый столбец таблицы
    fixedColumn?: boolean;
    // Следует ли включить тени для таблицы
    enableShadow?: boolean;
    // Должен ли пользователь выбирать размер таблицы
    isSelectablePageSize?: boolean;
    // массив возможных размеров
    pageSizeList?: Array<number>;
    // Полноэкранный лоадер
    isFullLoading?: boolean;
    // Плесхолдер для лоадера
    fullLoadingPlaceholder?: string;
    // Следует ли отображать кнопку аккордеона на мобильных устройствах
    isShowMobileAccordionBtn?: boolean;
    // Следует ли использовать мобильный контент
    useMobileContent?: boolean;
    // id таблицы
    tableId?: string;
    // внутренний id таблицы
    tableInnerId?: string;
    // контент верхней строки таблицы
    tableHeaderRowContent?: string;
    // id тела таблицы
    tableBodyId?: string;
    // id строк таблицы
    tableRowId?: string;
    // id кнопки "показать еще"
    showMoreRowId?: string;
    // id чекбокса
    tableCheckBoxId?: string;
    // Следует ли отображать заголовок внутри строки
    tableHeaderInRow?: boolean;
    // Параметры кнопки "Показать еще"
    showMoreBtnParams?: ITableShowMoreBtnProps;
    // Нужно ли зафиксировать шапку таблицы thead
    isStickyThead?: boolean;
    // Нужно ли отображать баннер о пустых данных
    needToShowEmptyDataBanner?: boolean;
    // Получение дополнительных полей в дочерних строках. Работает только с childrenDataKey
    getAdditionalChildRows?(childRow: Record<string, unknown>, row?: Record<string, unknown>): number;
    // Отображать ли пустое поле вместо родительской строки. Работает только с childrenDataKey
    getIsEmptyRow?(row: Record<string, unknown>): boolean;
    // Отображать пустое поле вместо дочернего элемента. Работает только с childrenDataKey
    getIsEmptyChildRow?(cshildRow: Record<string, unknown>, row?: Record<string, unknown>): boolean;
    // Получение дополнительных классов для управления стилями строк
    getAdditionalRowClass?(row: Record<string, unknown>): string;
    // Получение ключа строки
    getRowKey?(row: Record<string, unknown>): unknown;
    // Получение стилей для ячейки
    getCellStyle?(row: Record<string, unknown>, column: IColumnHeader): unknown;
    // Получение класса для строки
    getRowClass?(row: Record<string, unknown>, rowIndex: number, childRow?: Record<string, unknown>, childRowIndex?: number): string;
    // Получение класса для ячейки
    getCellClass?(row: Record<string, unknown>, rowIndex: number, childRow?: Record<string, unknown>, childRowIndex?: number): string;
    // Получение стилей теней для строки
    getRowShadowStyle?(rowIndex: number, refs: Array<HTMLElement>): StyleValue | undefined;
    // Вычисление, нужно ли отображать тени для строки
    isShowRowShadow?(mouseActiveRowIndex: number, rowIndex: number, cellIndex: number): boolean;
    // Нужна ли тень слева
    isNeedLeftShadow?: boolean;
    // Отображать ли в акардионе кнопку экшен. Работает только с isAccordion
    isNeedAccordionAction?: boolean;
    // Отключен ли внутренний выбор всех строк
    disabledSetSelectedAllInternal?: boolean;
    // Выбраны ли все строки
    allRowsSelected?: boolean;
    /** Доступна ли кнопка показать ещё для использования */
    isShowMoreBtnAvailable?: boolean;
    /** Подсветить строку */
    highlightedIndex?: number;
    disabledHeadSort?: boolean;
    initDataOnServer?: boolean;
  }>(),
  {
    theme: ETableThemes.Default,
    isFirstRowOpened: true,
    size: 20,
    isShowMoreBtnHidden: false,
    isNonPageable: false,
    showRowsCount: 20,
    isSelectableColumnDisabled: false,
    isColumnsSeparated: false,
    isShowMoreBtnOutsideControl: false,
    loading: false,
    isPaginationShown: false,
    showMoreCount: 20,
    isFullLoading: false,
    resetRowsAfterConfirmSelectableControl: true,
    fullLoadingPlaceholder: 'Пожалуйста, дождитесь окончания загрузки',
    useMobileContent: true,
    isNeedAccordionAction: true,
  },
);

const emit = defineEmits<{
  (e: 'onRowClick', value: ITableRowClickEvent): void;
  (e: 'onRowSelect', value: ITableRowSelectEvent): void;
  (e: 'onAllRowsSelect', value: ITableAllRowsSelectEvent): void;
  (e: 'selectable-control:cancel'): void;
  (e: 'selectable-control:accept', value: Array<unknown>): void;
  (e: 'onSortChanged', value: ITableSort): void;
  (e: 'onPageChanged', value: number, skip: number): void;
  (e: 'onPageSizeChanged', pageSize: number): void;
  (e: 'onShowMore', skip: number): void;
  (e: 'onChangeExpandRow', value: ITableExpandRowClickEvent): void;
  (e: 'onNextPage'): void;
  (e: 'onPrevPage'): void;
  (e: 'onScroll'): void;
}>();

const isLeftShadowActive = ref(false);
const isRightShadowActive = ref(true);
const tableRef = ref<HTMLTableElement>();
const tbodyRef = ref<HTMLElement>();
const perfectScrollbarRef = ref<InstanceType<PerfectScrollbar>>();
const tableRowsRefs = ref<Array<HTMLTableRowElement>>([]);
const tableTbodyRefs = ref<Array<HTMLTableElement>>([]);
const mouseActiveRowIndex = ref<number | null>(null);

const slots = useSlots();

const selectableColumnSource = { title: '', field: TABLE_SELECTABLE_SECTION_FIELD };

const { isMobile, isSmallMobile } = WindowWidthManager.getAdaptivaRefs();
const watchSubscription = new WatchSubscription();
const sortState = ref<ITableSort>(props.sort);
const internalData = ref([]);
const showMoreStartIndex = ref(props?.isSelectablePageSize ? props?.pagination?.pageSize ?? props.showRowsCount : props.showRowsCount);
const pagination = ref<ITablePagination | null>(props.pagination || null);
const isLoading = ref(false);
const internalOpenedRows = ref<Array<number>>([]);
const paginationRef = ref<InstanceType<typeof Pagination>>();
const { updateVersion } = useUpdate();

const internalShowMoreDisabler = Disabler.getReactiveInstance();

const pageSize = computed<number>(() => (props?.isSelectablePageSize ? props?.pagination?.pageSize ?? props.size : props.size));
const showMoreCount = computed<number>(() =>
  props?.isSelectablePageSize ? props?.pagination?.pageSize ?? props.showMoreCount : props.showMoreCount,
);
const isPaginationVisible = computed(
  () => !!pagination.value && !props.hidePagination && (props.isPaginationShown || pagination.value.dataCount > pageSize.value),
);
const isShowMoreButtonVisible = computed(() => {
  if (!props.data?.length || !internalData.value.length) {
    return false;
  }

  if (props.isShowMoreBtnOutsideControl) {
    return !props.isShowMoreBtnHidden;
  }
  if (props.isNonPageable) {
    return props.data?.length !== internalData.value.length;
  }

  return (
    pagination.value?.size &&
    !props.isShowMoreBtnHidden &&
    props.showMoreCount &&
    !paginationRef.value?.isLastPage &&
    internalMoreNumber.value > 0
  );
});
const itemsShownText = computed(() => `Показано ${internalData.value.length} из ${props.pagination?.dataCount}`);

const isThemeSeparated = computed(() => props.theme === ETableThemes.Separated);
const expandedRowIndexes = computed(() => props.expandedRowIndexes || internalOpenedRows.value);
const expandedUpdatingIndex = computed(() => (props.isFirstRowOpened ? [0] : []));
const isAllSelectedItems = computed<boolean>(
  () => props.allRowsSelected !== undefined ? props.allRowsSelected : internalData.value?.every((item) => !!item.selected),
);
const selectable = computed(() => props.selectableMode || props.selectableSingleMode);
const internalColumns = computed<Array<IColumnHeader>>(() => mapInternalColumns());
const diffDataCount = computed(() => (pagination.value?.dataCount || 0) - internalData.value?.length);
const internalMoreNumber = computed(() => (diffDataCount.value > props.showMoreCount ? props.showMoreCount : diffDataCount.value));

function handleSort(field: string) {
  if (field !== sortState.value?.field) {
    sortState.value = {
      field,
      order: ESortState.Desc,
    };
    return;
  }

  const order = sortState.value?.order ? (sortState.value.order === ESortState.Desc ? ESortState.Asc : null) : ESortState.Desc;

  if (order === null) {
    sortState.value = null;
    return;
  }

  sortState.value = {
    field,
    order,
  };
}

function mapInternalColumns(): Array<IColumnHeader> {
  const clonedColumns = cloneDeep(props.columns);

  if (selectable.value && !clonedColumns.some((column) => column.field === TABLE_SELECTABLE_SECTION_FIELD)) {
    clonedColumns.unshift(selectableColumnSource);
  }

  return clonedColumns;
}

function getSortableAdditionalClass(column: IColumnHeader): Record<string, boolean> {
  return {
    'mm-table__header-row-content--sortable': !!column.sortable,
    'is-sort-active': sortState.value?.field === column.field && !!sortState.value.order,
    asc: sortState.value?.field === column.field && sortState.value?.order === ESortState.Asc,
    desc: sortState.value?.field === column.field && sortState.value?.order === ESortState.Desc,
  };
}

function isExpandedAccordion(rowIndex: number): boolean {
  return !!expandedRowIndexes.value?.includes(rowIndex);
}

function onExpandRowClick(event: ITableExpandRowClickEvent): void {
  if (props.expandOnRowClick) {
    return;
  }
  emitExpandRowClick(event);
}

function emitExpandRowClick(event: ITableExpandRowClickEvent): void {
  emit('onChangeExpandRow', event);
}

function onRowClick(event: ITableRowClickEvent): void {
  if (props.expandOnRowClick) {
    toggleOpenedRow(event.rowIndex);
    emitExpandRowClick({ row: event.row, rowIndex: event.rowIndex, value: !isExpandedAccordion(event.rowIndex) });
  }
  emit('onRowClick', event);
  mouseActiveRowIndex.value = null;
  nextTick(() => (mouseActiveRowIndex.value = event.rowIndex));
}

function toggleOpenedRow(rowIndex: number): void {
  if (isExpandedAccordion(rowIndex)) {
    internalOpenedRows.value?.filter((item) => item !== rowIndex);
    return;
  }
  internalOpenedRows.value?.push(rowIndex);
}

function onShowMore(): void {
  if (props.isShowMoreBtnOutsideControl) {
    emit('onShowMore', Infinity);
    return;
  }
  if (props.apiUrl) {
    loadMore();
  }

  if (props.data && !props.disableInternalCalcVisibleRows) {
    showMoreStartIndex.value += props.showMoreCount;
    internalData.value = props.data.slice(0, showMoreStartIndex.value);
  }

  emit(
    'onShowMore',
    props.disableInternalCalcVisibleRows
      ? paginationSkip(pagination.value?.page + 1, pageSize.value)
      : paginationSkip(pagination.value?.page, pageSize.value) + showMoreStartIndex.value,
  );
}

function onRowSelect(event: ITableRowSelectEvent): void {
  emit('onRowSelect', cloneDeep({ ...event, row: { ...event.row, selected: undefined } }));
}

function onAllRowsSelect(value: boolean): void {
  if (!props.disabledSetSelectedAllInternal) {
    internalData.value.forEach((item) => (item.selected = value));
  }
  emit('onAllRowsSelect', cloneDeep({
    rows: internalData.value.map((item) => ({ ...item, selected: undefined })),
    value,
  }));
}

function onCancelTableSelectableControl(): void {
  props.resetRowsAfterConfirmSelectableControl && resetSelectedRows();
  emit('selectable-control:cancel');
}

function onAcceptTableSelectableControl(): void {
  emit(
    'selectable-control:accept',
    cloneDeep(
      (internalData.value || [])
        .filter((item) => !!item.selected)
        .map((item) => ({ ...item, selected: undefined }))),
  );
  props.resetRowsAfterConfirmSelectableControl && resetSelectedRows();
}

function resetSelectedRows(): void {
  internalData.value?.forEach((item) => (item.selected = false));
}

function resetShowMoreStartIndex(): void {
  showMoreStartIndex.value = 0;
}

function onPageChanged(page: number): void {
  resetShowMoreStartIndex();
  if (props.apiUrl) {
    updateData({ page });
  }
  internalOpenedRows.value = expandedUpdatingIndex.value;
  emit('onPageChanged', page, paginationSkip(page, props.pagination?.pageSize || pageSize.value));
}

function onPageSizeChanged(pageSize: number) {
  if (pageSize && props.apiUrl) {
    updateData({ perPage: pageSize });
  }
  emit('onPageSizeChanged', pageSize);
}

async function updateData(params?: IGetTableDataParams) {
  const response = await loadData(params);
  internalData.value = response?.data;
  pagination.value = response?.pagination;
}

async function loadMore() {
  if (!pagination.value) {
    return;
  }

  internalShowMoreDisabler.activate();
  try {
    const { data, pagination: cPagination } = await loadData({ page: pagination.value?.page + 1 });
    internalData.value = [...internalData.value, ...data];
    pagination.value = cPagination;
  } finally {
    internalShowMoreDisabler.deactivate();
  }
}

async function loadData(requestParams?: IGetTableDataParams): Promise<ITableDataResponse> {
  const params: IGetTableDataParams = {
    page: requestParams?.page || pagination.value?.page || 1,
    sort: sortState.value ? JSON.stringify(sortState.value) : null,
    apiUrl: props.apiUrl,
  };

  isLoading.value = true;
  const { data } = await useFetch<ITableDataResponse>(props.apiUrl, { params });
  isLoading.value = false;

  if (selectable.value) {
    data.value.data = data.value?.data?.map((item) => ({ ...item, selected: false }));
  }

  return data.value;
}

function isValidSelectableSection(
  columnField: string,
  columnType: ETableSelectableSectionColumnType = ETableSelectableSectionColumnType.Cell,
): boolean {
  if (!internalData.value?.length) {
    return false;
  }

  if (columnField !== TABLE_SELECTABLE_SECTION_FIELD) {
    return false;
  }

  return (props.selectableSingleMode && columnType === ETableSelectableSectionColumnType.Cell) || props.selectableMode;
}

function setInternalData(data: Array<Record<string, unknown>>): void {
  const formattedData = props.showMoreCount ? data.slice(0, showMoreCount.value) : data;
  internalData.value = selectable.value ? formattedData.map((item) => ({
    ...item,
    selected: false,
  })) : [...formattedData];
}

async function initData(): Promise<void> {
  internalOpenedRows.value = expandedUpdatingIndex.value;

  if (props.sort) {
    sortState.value = props.sort;
  }

  if (props.data) {
    return setInternalData(props.data);
  }

  if (props.apiUrl) {
    const { data } = await useFetch<ITableDataResponse>(props.apiUrl);
    internalData.value = selectable.value ? data.value?.data?.map((item) => ({
      ...item,
      selected: false,
    })) : data.value?.data;
    pagination.value = data.value?.pagination;
  }
}

function getCellValue(row: Record<string, Record<string, unknown>> | undefined, column: IColumnHeader): unknown {
  if (!row) {
    return null;
  }
  return column.internalKey ? row[column.field]?.[column.internalKey] : row[column.field];
}

function getParentRowspan(column: IColumnHeader, row: Record<string, unknown>): number | undefined {
  if (column.isInner) {
    return 1;
  }

  if (slots?.insideChildRows) {
    return;
  }

  const rowChildren = row[props.childrenDataKey] as Array<Record<string, unknown>>;
  if (!rowChildren?.length && slots?.notChildren) {
    return 2;
  }

  if (rowChildren?.length) {
    const childrenWarnRows = rowChildren.reduce((count, child) => count + (props?.getAdditionalChildRows?.(child) || 0), 0);
    return rowChildren.length + childrenWarnRows + 1;
  }

  return 1;
}

function updateShadows() {
  const scrollContainer = perfectScrollbarRef.value?.$el;

  if (!scrollContainer) {
    return;
  }

  isLeftShadowActive.value = scrollContainer?.scrollLeft > 0;
  isRightShadowActive.value = scrollContainer?.scrollLeft + scrollContainer?.clientWidth !== scrollContainer?.scrollWidth;
}

function onHorizontalScrollChange() {
  updateShadows();
  emit('onScroll');
}

function onScrollChange() {
  emit('onScroll');
}

function getRowShadowStyle(rowIndex: number, elementRefs: Array<HTMLElement> = tableRowsRefs.value): StyleValue {
  return props.getRowShadowStyle?.(rowIndex, elementRefs) ?? TableUtils.getRowShadowStyle(rowIndex, elementRefs);
}

function onMouseoverRow(rowIndex: number): void {
  mouseActiveRowIndex.value = rowIndex;
}

function onMouseoutRow(): void {
  mouseActiveRowIndex.value = null;
}

function isShowRowShadow(rowIndex: number, cellIndex = 0): boolean {
  return props.isShowRowShadow
    ? props.isShowRowShadow(mouseActiveRowIndex.value, rowIndex, cellIndex)
    : mouseActiveRowIndex.value === rowIndex && cellIndex === 0;
}

watchSubscription.add(
  watch(
    () => props.data,
    setInternalData,
    { deep: true },
  ),

  watch(
    () => props?.sort,
    (value) => (sortState.value = value),
    { deep: true },
  ),

  watch(
    () => sortState.value,
    (value, previous) => {
      if (props.apiUrl) {
        updateData();
      }
      internalOpenedRows.value = expandedUpdatingIndex.value;
      !isEqual(previous, value) && emit('onSortChanged', value);
    },
  ),

  watch(
    () => props.pagination,
    (newPagination) => {
      pagination.value = newPagination;
    },
  ),

  watch(
    () => [props.loading, props.showLoader],
    ([newLoading, newShowLoader]) => (isLoading.value = Boolean(newLoading || newShowLoader)),
    { immediate: true },
  ),
);

if (process.server && props.initDataOnServer)  {
  await initData();
}

useSSRUnsubscribeWatch(watchSubscription);

onMounted(async () => await initData());

onBeforeUnmount(() => {
  tbodyRef.value = undefined;
});
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

$root: '.mm-table';

.mm-table {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 0 0 1px $gray-200;
  position: relative;

  &__loader {
    z-index: 2;

    &--centered {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
    }
  }

  #{$root}__row--empty {
    td {
      padding: 12px 0;
    }
  }

  #{$root}--header-sticky {
    border-collapse: separate;
    border-spacing: 0;
  }

  #{$root}__head {
    &.mm-table__head-sticky {
      position: sticky;
      top: 0;
      z-index: 20;
    }
  }

  &__body {
    width: 100%;

    .mm-table__loader {
      transform: translateY(-50%);
    }

    &:hover {
      td:first-child {
        &.mm-table__row-shadow::before {
          content: '';
          display: block;
          position: absolute;
          top: var(--row-top);
          left: var(--row-left);
          width: var(--row-width);
          height: var(--row-height);
          box-sizing: border-box;
          box-shadow: 0 2px 18px $table-row-hover-shadow-c;
          border-radius: 8px;
          pointer-events: none;
        }
      }
    }
  }

  &--theme-separated {
    border-collapse: separate;
    padding: 0 8px;
    border-spacing: 0;
    background-color: $light-gray;

    #{$root}__head {
      background-color: transparent;

      & > tr > th {
        padding: 20px 0;
        padding-left: 19px;

        .mm-table__header-row-content--sortable {
          padding-left: 5px;
        }
      }
    }

    #{$root}__body {
      border-radius: 8px;

      &::after {
        content: '';
        display: block;
        height: 8px;
        background: $light-gray;
      }

      > .mm-table__row--show-more {
        background-color: $light-gray;
        height: 40px;

        > td {
          border: 1px solid $gray-200;
        }
      }

      & > tr > td {
        padding: 16px;
      }
    }

    #{$root}__header-row {
      background-color: transparent;
    }

    #{$root}__expanded-row {
      display: table-row;
    }

    #{$root}__expanded-cell {
      display: table-cell;
      padding: 16px;
      font-size: 14px;
      line-height: 20px;
      color: $table-cell-c;
      vertical-align: top;
    }

    #{$root}__action-cell {
      vertical-align: middle;
    }

    #{$root}__expand-btn {
      width: 32px;
      height: 32px;
      padding: 6px;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      position: relative;
      z-index: 4;
    }

    #{$root}__body {
      transition: box-shadow $transition-speed, border-color $transition-speed;

      #{$root}_expanded-divider__row {
        #{$root}_expanded-divider__table-data {
          background-color: $white;
          height: 1px;
          padding: 0 24px 0 16px;
        }

        #{$root}_expanded-divider__line {
          height: 1px;
          background-color: $gray-200;
          padding: 0;
        }
      }

      & > tr {
        cursor: pointer;

        & > td {
          font-size: 14px;
          line-height: 20px;
          color: $table-cell-c;
        }

        & > td#{$root}__data-cell,
        & > td#{$root}__action-cell {
          padding: 20px 24px;
          vertical-align: middle;
        }

        & > td#{$root}__expanded-container {
          padding-top: 0;
        }
      }

      &.mm-table__body--show-more {
        &:hover {
          box-shadow: none;
        }
      }
    }

    tr {
      background-color: $table-separated-row-bg;

      &#{$root}__expanded-anchor-row {
        td:first-child {
          border-bottom-left-radius: 0;
        }

        td:last-child {
          border-bottom-right-radius: 0;
        }
      }

      &.expanded {
        background-color: $app-table-elements-color;

        &:first-child {
          td:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 0;
          }

          td:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 0;
          }
        }

        & + .expanded {
          td {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }
    }

    tr:first-child {
      td:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      td:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      #{$root}__data-cell {
        border-bottom-left-radius: 0;
      }
    }

    tr:last-child {
      td:first-child {
        border-bottom-left-radius: 8px;
      }

      td:last-child {
        border-bottom-right-radius: 8px;
      }

      td#{$root}__data-cell {
        border-bottom-right-radius: 0;
      }
    }
  }

  &--theme-default {
    border-collapse: collapse;

    &#{$root}--separate-columns {
      #{$root}__head {
        tr > th:not(:first-child) {
          border-left: solid 1px $gray-200;
        }
      }
    }

    #{$root}__body {
      & > tr {
        cursor: pointer;
        transition: box-shadow $transition-speed, border-color $transition-speed;

        &:not(:first-child) {
          border-top: 1px solid $table-border-c;
        }

        & > td {
          padding: 12px;
          font-size: 14px;
          line-height: 20px;
          color: $table-cell-c;
          vertical-align: top;

          &[colspan='0'] {
            display: none;
          }
        }
      }
    }

    &:not(#{$root}--children) {
      #{$root}__body {
        & > tr:not(#{$root}__row--show-more):not(#{$root}__row--empty):hover {
          border-radius: 8px;
        }
      }
    }
  }

  &--children {
    #{$root}__body--children {
      &:not(:first-child) {
        border-top: 1px solid $table-border-c;
      }
    }
  }

  &--children {
    #{$root}__body--children {
      &:not(:first-child) {
        border-top: 1px solid $table-border-c;
      }

      &:hover {
        z-index: 2;
        border-radius: 8px;
      }
    }
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.7;
    transition: 0.2s linear;
  }

  &--full-loading {
    pointer-events: none;
    opacity: 0.7;
    height: 500px;
    overflow: hidden;
    transition: 0.2s linear;
  }

  &--not-pagination {
    padding-bottom: 28px;
  }

  &__table {
    border-radius: 8px;
    overflow: hidden;
    width: 100%;

    thead {
      th {
        font-weight: 500;

        &:first-child {
          border-top-left-radius: 8px;
        }

        &:last-child {
          border-top-right-radius: 8px;
        }
      }

      :deep(.checkbox__input) {
        border: none;
      }
    }

    :deep(.checkbox) {
      &.hovered-border {
        & > span {
          border-radius: 4px;
        }
      }
    }

    :deep(.checkbox__input) {
      margin: 0 4px 0 24px;

      & > span {
        margin: 0;
      }
    }
  }

  &__head {
    background-color: $table-head-bc;

    & > tr {
      & > td,
      & > th {
        padding: 12px 0;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  &__header-row-content {
    color: $table-header-cell-c;
    padding: 5px;
    display: inline-flex;
    align-items: center;

    &--sortable {
      z-index: 5;
      padding: 5px 10px;
      cursor: pointer;
      border-radius: 4px;
      overflow: hidden;
      transition: color $transition-speed, background-color $transition-speed;

      &.is-sort-active,
      &:hover {
        color: $table-sortable-cell--active-c;
        background-color: $table-sortable-cell-cover-bc;

        & > #{$root}__sort-icon :deep(path) {
          fill: $table-sort-icon-active-fill;
        }
      }

      &.asc > #{$root}__sort-icon :deep(svg:first-child) > path {
        fill: $app-table-elements-color;
      }

      &.desc > #{$root}__sort-icon :deep(svg:last-child) > path {
        fill: $app-table-elements-color;
      }
    }

    &--disabled {
      color: $color-disabled-text;
      pointer-events: none;

      .mm-table__sort-icon {
        :deep(path) {
          fill: $color-green-muted;
        }
      }
    }

    &--disabled.is-sort-active {
      color: $white;
      background-color: $color-green-muted;
      pointer-events: none;
    }
  }

  &__header-selectable-checkbox {
    margin-left: 12px;
  }

  &__sort-icon {
    display: flex;
    flex-direction: column;
    width: 6px;
    margin-left: 8px;

    :deep(path) {
      transition: fill $transition-speed;
    }

    & > svg:nth-child(n + 2) {
      margin-top: 3px;
    }
  }

  &__row {
    &--show-more {
      padding: 0;

      & > td {
        > div {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          background-color: $table-show-more-bc;
          overflow: hidden;
          padding: 14px 0;
          box-shadow: 0 0 0 1px #e6e6e6;
          z-index: 5;

          &.mm-table__row--show-more--text {
            @include text-r14;

            color: $color-secondary;
            cursor: auto;
          }

          &:hover {
            button {
              opacity: 0.7;
            }
          }

          button {
            padding: 2px 5px;
            color: $table-show-more-c;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            outline: none;
            border: 0;
            background-color: transparent;
            transition: opacity $transition-speed;
          }
        }
      }
    }
  }

  &--fixed-cols-scroller {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  &--fixed-cols {
    overflow: unset;

    &.mm-table--theme-default:not(.mm-table--children) .mm-table__body > tr:not(.mm-table__row--show-more):not(.mm-table__row--empty) {
      box-shadow: none !important;
      border-bottom: 1px solid $table-border-c !important;
      transition: none;
      z-index: unset;

      :deep(.mm-select__wrapper) {
        z-index: 5;
      }
    }

    thead {
      th {
        background-color: $light-gray;

        &:first-child {
          top: 1px;
          left: 0;
          position: sticky;
          z-index: 5;
          background-color: $light-gray;
        }

        &:nth-child(2) {
          top: 1px;
          left: 56px;
          position: sticky;
          z-index: 5;
        }
      }
    }

    tbody {
      tr {
        &:last-child {
          td {
            border-bottom-left-radius: 8px;
          }
        }

        td {
          background-color: $white;

          &:first-child {
            left: 0;
            position: sticky;
            z-index: 6;
          }

          &:nth-child(2) {
            left: 56px;
            position: sticky;
            z-index: 6;
          }
        }
      }
    }
  }

  &--with-shadow {
    padding-bottom: 15px;
    background: $light-gray;

    .ps {
      padding-right: 0;
      padding-bottom: 20px;
    }

    .shadow-left {
      position: absolute;
      top: 0;
      left: 256px;
      right: 0;
      height: calc(100% - 25px);
      width: 40px;
      background: linear-gradient(270deg, #031912 0%, rgba(3 25 18 / 0%) 100%);
      opacity: 0.1;
      transform: matrix(-1, 0, 0, 1, 0, 0);
      z-index: 3;
    }

    .shadow-right {
      position: absolute;
      top: 0;
      right: 0;
      height: calc(100% - 25px);
      width: 40px;
      background: linear-gradient(270deg, #031912 0%, rgba(3 25 18 / 0%) 100%);
      opacity: 0.1;
      z-index: 3;
    }

    &-wrap {
      background: $light-gray;
      box-shadow: none;
      border: none;
      border-radius: 0;
      padding: 0 1px;

      &::before {
        content: '';
        position: absolute;
        width: calc(100% - 2px);
        height: calc(100% - 25px);
        box-shadow: 0 0 0 1px rgb(0 0 0 / 15%);
        z-index: 1;
        top: 1px;
        left: 1px;
        border-radius: 8px;
      }
    }
  }

  &--header-in-row {
    &__column-row-title {
      font-size: 12px;
      line-height: 16px;
      color: $light-green;
      padding-bottom: 12px;
      min-height: 28px;
    }

    &__column-row-empty {
      padding: 0;
    }
  }
}
</style>

import { ProviderService } from 'shared/models/providerService.model';
import {
  IAddProduct,
  IAddProductResponse,
  IBasket,
  IBasketCountInfo,
  IBasketCreateBody,
  IBasketInfo,
  IBasketOptimizeInfo,
  IMpBasketPlaceBody,
  IMpBasketPlaceParams,
  ISaveBasketResponse,
} from 'models/basket.model';
import { BasketApi } from 'services/api/basketApi.service';
import { useUserStore } from 'store/user.store';
import { createAsyncLoader } from 'shared/utils/asyncLoader.util';
import { BasketCounter } from './basketCounter.service';
import { CARD_NOT_FOUND_ERROR, INVALID_CART_ERROR } from 'constants/error.const';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import Notificator from 'shared/services/notificator.service';
import Loader from 'shared/utils/loaderHelper.util';
import { EERpBasketDistributionStatus } from 'enums/basket/erpBasketDistributionStatus.enum';
import { ApiHelper } from 'shared/services/api/apiHelper.service';
import { EErrorType } from 'shared/enums/errorType.enum';
import { ELocalStorageKey } from 'shared/enums/localStorageKey.enum';
import { useLocalStorage } from 'shared/composables/useLocalStorage';

export class BasketManager extends ProviderService {
  protected static readonly serviceName = 'basketManagerService';

  private static instance: BasketManager | undefined;

  public basketId = ref<number | undefined>();
  public selectedBaseId = ref<number | undefined>();
  public userBaskets = ref<Array<IBasketInfo>>([]);

  // TODO: переделать условие, так как при обновлении страницы получаем undefined поскольку не успевает инициализироваться basketId
  public basketInfo = computed(() =>
    this.basketId.value
      ? this.userBaskets.value.find((userBasket) => userBasket.cartIds.includes(this.basketId.value as number))
      : undefined,
  );

  public loadingUserBaskets = Loader.getReactiveInstance();

  /** Статус автоподбора текущей корзины */
  public readonly currentBasketDistributionStatus = ref<EERpBasketDistributionStatus | undefined>();
  private isPollingDistributionStatusInProgress = false;

  private basketCounter = new BasketCounter();
  private subdivisionId = ref<number | undefined>();

  private loading: Promise<unknown> | undefined;

  private userId = computed(() => this.userStore.userId);

  constructor(
    private readonly userStore = useUserStore(),
    private readonly storagedBasketId = useLocalStorage(ELocalStorageKey.BASKET_ID),
  ) {
    if (BasketManager.instance) {
      return BasketManager.instance;
    }

    super();
    BasketManager.instance = this;
  }

  public clear(): void {
    this.basketId.value = undefined;
    this.selectedBaseId.value = undefined;
    this.userBaskets.value = [];
    this.loadingUserBaskets.deactivate();
    this.subdivisionId.value = undefined;
    this.basketCounter.clear();
    this.currentBasketDistributionStatus.value = undefined;
    this.isPollingDistributionStatusInProgress = false;
  }

  public setBasketId(basketId: number | undefined): void {
    this.basketId.value = basketId;
    this.onSetBasketId();
  }

  private onSetBasketId(): void {
    this.setCurrentBasketDistributionStatus();

    if (!this.basketId.value) {
      return;
    }

    this.startPollingDistributionStatus();
  }

  private setCurrentBasketDistributionStatus(status?: EERpBasketDistributionStatus): void {
    this.currentBasketDistributionStatus.value = status;
  }

  public async startPollingDistributionStatus(): Promise<void> {
    if (!this.basketId.value || this.isPollingDistributionStatusInProgress) {
      return;
    }

    this.isPollingDistributionStatusInProgress = true;

    try {
      const basketIdBeforeRequest = ref<number | undefined>();

      await ApiHelper.pooling(
        () => {
          basketIdBeforeRequest.value = this.basketId.value;
          return this.getCartDistributionStatus();
        },
        (res) => {
          // если за время запроса поменялась текущая корзина
          if (basketIdBeforeRequest.value !== this.basketId.value) {
            // повторяем запрос с обновленным id, без смены статуса
            return true;
          }

          this.setCurrentBasketDistributionStatus(res.distribution);

          if (this.currentBasketDistributionStatus.value !== EERpBasketDistributionStatus.InProgress) {
            this.isPollingDistributionStatusInProgress = false;
          }

          return this.isPollingDistributionStatusInProgress;
        },
        {
          interval: 2000,
          attempts: Infinity,
        },
      );
    } catch (error) {
      clientSentry.captureServiceException(error, BasketManager.getServiceName(), undefined, {
        extra: {
          basketId: this.basketId.value,
        },
      });

      Notificator.showDetachedNotification('Ошибка при проверке статуса корзины');
      this.isPollingDistributionStatusInProgress = false;
      this.setCurrentBasketDistributionStatus();
    }
  }

  private resetBasket(): void {
    this.basketCounter.setCount(0);
  }

  public getCurrentBasket() {
    return this.userBaskets.value?.find((basket) => basket.id === this.basketId.value);
  }

  async handleRequest<T>(makeRequest: () => T) {
    if (!this.basketId.value) {
      this.loading ? await this.loading : await this.refreshBasketData();
    }
    return makeRequest();
  }

  public async refreshBasketData(forceCreateBasket?: boolean, forceUpdate?: boolean) {
    if (!this.userId.value) {
      return;
    }

    if (this.loading) {
      return await this.loading;
    }

    const { loading, finishLoading } = createAsyncLoader();

    try {
      this.loading = loading;
      this.selectedBaseId.value = this.userStore.selectedBase?.id as number;

      if (!this.userBaskets.value?.length || forceUpdate) {
        await this.refreshUserBaskets();
      }

      if (!forceUpdate) {
        const storagedBasket = this.getStoragedBasket();
        if (storagedBasket) {
          this.setBasket(storagedBasket);
          this.setBasketId(Number(this.storagedBasketId.value));
          return;
        }
      }

      const basisBasket = this.userBaskets.value?.find((basket) => basket.basisId === this.selectedBaseId.value);
      if (basisBasket) {
        this.setBasket(basisBasket);
        return;
      }

      if (this.userStore.selectedBase && (!basisBasket || forceCreateBasket)) {
        const newBasket = await BasketApi.createUserBasket({
          basisId: this.userStore.selectedBase?.id as number,
          clientId: this.userStore.clientId,
          subdivisionId: this.userStore.selectedBase?.subdivisionId,
        });

        this.userBaskets.value?.push(newBasket);
        this.setBasket(newBasket);
      }
    } finally {
      finishLoading?.();
      this.loading = undefined;
    }
  }

  public setBasket(basket: IBasketInfo): void {
    this.basketCounter.setCount(basket.count, basket.basisId);
    this.selectedBaseId.value = basket.basisId;
    this.subdivisionId.value = basket.subdivisionId;
    this.setBasketId(basket.id);
  }

  public async findUserBasketByBasis(basisId: number, subdivisionId?: number): Promise<IBasketInfo | undefined> {
    try {
      await this.refreshUserBaskets();
      return (this.userBaskets.value || []).find(
        (userBasket) => userBasket.basisId === basisId && (!subdivisionId || subdivisionId === userBasket.subdivisionId),
      );
    } catch (error) {
      clientSentry.captureServiceException(error, BasketManager.getServiceName(), undefined, {
        extra: {
          basisId,
          subdivisionId,
          userId: this.userId.value,
        },
      });
    }
  }

  public async getUserBaskets(): Promise<Array<IBasketInfo>> {
    return this.handleRequest(() => BasketApi.getUserBaskets());
  }

  public async createBasket(body: IBasketCreateBody): Promise<IBasketInfo> {
    return this.handleRequest(() => BasketApi.createUserBasket(body));
  }

  public async getBasket(): Promise<IBasket | undefined> {
    if (!this.basketInfo) {
      return;
    }

    const basketData = await this.handleRequest(() => BasketApi.getBasket(this.basketId.value as number));
    if (basketData?.erpDistribution === EERpBasketDistributionStatus.InProgress) {
      this.setCurrentBasketDistributionStatus(EERpBasketDistributionStatus.InProgress);
      this.startPollingDistributionStatus();
    }

    return basketData;
  }

  public async clearBasket(): Promise<IBasket> {
    return this.handleRequest(async () => {
      this.resetBasket();
      return await BasketApi.clearBasket(this.basketId.value as number);
    });
  }

  public async addProduct(data: IAddProduct): Promise<IAddProductResponse> {
    try {
      return await this.handleRequest(() => BasketApi.addProduct(this.basketId.value as number, data));
    } catch (error) {
      if ([INVALID_CART_ERROR, CARD_NOT_FOUND_ERROR]?.includes(error?.data?.data?.src)) {
        await this.refreshBasketData(true);
        return await BasketApi.addProduct(this.basketId.value as number, data);
      }

      if (error?.data?.data?.src === EErrorType.InvalidOperationForDistributingCart) {
        this.startPollingDistributionStatus();
      }

      throw error;
    }
  }

  public async deleteProduct(itemId: number): Promise<IBasket> {
    return await this.handleRequest(() => BasketApi.deleteProduct(itemId));
  }

  public async updateProductCount(itemId: number, quantity: number): Promise<IBasket> {
    return await this.handleRequest(() => BasketApi.updateProductCount(itemId, quantity));
  }

  public async placeOrder(payload: IMpBasketPlaceBody, params?: IMpBasketPlaceParams): Promise<ISaveBasketResponse> {
    return await this.handleRequest(async () => {
      const response = await BasketApi.placeOrder(this.basketId.value as number, payload, params);
      await this.removeCurrentBasket();
      return response;
    });
  }

  private async removeCurrentBasket() {
    this.userBaskets.value = this.userBaskets.value.filter((basket) => basket.id !== this.basketId.value);
    this.setBasketId(undefined);
    this.refreshBasketData();
  }

  public async getCounterAndBasisId(): Promise<IBasketCountInfo> {
    if (this.loading) {
      await this.loading;
    }
    return {
      count: this.basketCounter.counter.value as number,
      basisId: this.selectedBaseId?.value as number,
      subdivisionId: this.subdivisionId?.value as number,
    };
  }

  public async getBasketReportById() {
    return this.handleRequest(() => BasketApi.getBasketReportById(this.basketId.value as number));
  }

  public async getCartDistributionStatus() {
    return this.handleRequest(() => BasketApi.getCartDistributionStatus(this.basketId.value as number));
  }

  public async downloadSpecification() {
    return this.handleRequest(() => BasketApi.downloadSpecification(this.basketId.value as number));
  }

  private async refreshUserBaskets(): Promise<void> {
    if (this.loadingUserBaskets.value || !this.userId.value) {
      return;
    }

    this.loadingUserBaskets.activate();
    try {
      this.userBaskets.value = (await BasketApi.getUserBaskets()) || [];
    } catch (error) {
      clientSentry.captureServiceException(error, BasketManager.getServiceName(), undefined, {
        extra: {
          userId: this.userId.value,
        },
      });
      Notificator.showDetachedNotification('Ошибка загрузки корзин пользователя');
    } finally {
      this.loadingUserBaskets.deactivate();
    }
  }

  public async optmizeBasket(allowAnalogs?: boolean): Promise<IBasketOptimizeInfo> {
    return this.handleRequest(async () => {
      const optimizedBasket = await BasketApi.optimizeBasket(this.basketId.value as number, allowAnalogs);
      this.setBasketId(optimizedBasket.id);

      return optimizedBasket;
    });
  }

  private getStoragedBasket(): IBasketInfo | undefined {
    const basketId = Number(this.storagedBasketId.value);

    if (!basketId) {
      return;
    }

    return this.userBaskets.value.find((userBasket) => userBasket.cartIds.includes(basketId));
  }
}

import { BasketManager } from 'services/basket-services/basket/basketManager.service';
import { watch } from 'vue';
import { useUserStore } from 'store/user.store';
import { UserHelper } from 'utils/userHelper.util';
import { ELocalStorageKey } from 'shared/enums/localStorageKey.enum';
import { useLocalStorage } from 'shared/composables/useLocalStorage';

export async function useRefreshBasketData(forceUpdate?: boolean) {
  const basketManager = new BasketManager();
  const watchSubscription = new WatchSubscription();
  const userStore = useUserStore();
  const storagedBasketId = useLocalStorage(ELocalStorageKey.BASKET_ID);

  watchSubscription.add(
    watch(
      () => userStore.isUserLoggedIn,
      async (isUserLoggedIn) => {
        if (isUserLoggedIn && UserHelper.isClient && (!basketManager.basketId.value || forceUpdate)) {
          await basketManager.refreshBasketData(false, forceUpdate);
        }
      },
      {
        immediate: true,
      },
    ),

    watch(
      () => basketManager.basketId.value,
      (newBasketId) => storagedBasketId.value = String(newBasketId),
      {
        flush: 'post',
      },
    ),
  );

  useSSRUnsubscribeWatch(watchSubscription);
}

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BasisRetrieveSchema } from './BasisRetrieveSchema';
import type { CustomerCompleteRetrieveSchema } from './CustomerCompleteRetrieveSchema';
import type { RecipientCompleteRetrieveSchema } from './RecipientCompleteRetrieveSchema';
import type { SupplierOrderCompleteRetrieveSchema } from './SupplierOrderCompleteRetrieveSchema';
export type OrderCompleteRetrieveSchema = {
    id: number;
    number: string;
    status: OrderCompleteRetrieveSchema.status;
    source: string;
    divisible: boolean;
    delivery: string;
    clientId: number;
    userId: string;
    customer: CustomerCompleteRetrieveSchema;
    recipient: RecipientCompleteRetrieveSchema;
    basis: BasisRetrieveSchema;
    beId: number;
    subdivisionId?: number;
    costVat: number;
    costNet: number;
    deliveryCost?: number;
    deliveryVatCost?: number;
    costWithVat: number;
    costWithDelivery: number;
    costWithVatAndDelivery: number;
    summaryVat?: number;
    isDeliveryInboundRequired?: boolean;
    isReadyOrLater?: boolean;
    purchaseOrder?: string;
    isPurchaseOrderEditable?: boolean;
    createTs: string;
    registrationDate?: string;
    items: Array<SupplierOrderCompleteRetrieveSchema>;
};
export namespace OrderCompleteRetrieveSchema {
    export enum status {
        DRAFT = 'draft',
        ACTIVE = 'active',
        ACTION_REQUIRED = 'action_required',
        DONE = 'done',
        CANCELED = 'canceled',
    }
}


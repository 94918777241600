/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type LimitsReportFiltersScheme = {
    /**
     * Тип получения (для ролевой модели)
     */
    fetchType?: LimitsReportFiltersScheme.fetch_type;
    /**
     * Год лимитов
     */
    years?: Array<number>;
    /**
     * Фильтр периодов
     */
    periodTypes?: Array<string>;
    /**
     * Фильтр по бизнес единицам
     */
    beIds?: Array<number>;
    /**
     * Фильтр по ПФМ
     */
    pmfs?: Array<string>;
};
export namespace LimitsReportFiltersScheme {
    /**
     * Тип получения (для ролевой модели)
     */
    export enum fetch_type {
        LIMITS_ONLY = 'limits_only',
        WITH_ORDERS = 'with_orders',
        WITH_ORDERS_AND_PRICES = 'with_orders_and_prices',
    }
}


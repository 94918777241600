import { IDeliveryBase } from 'shared/models/bases.model';
import { AuthHelper } from 'mp-utils/authHelper.util';
import { BasisRetrieveSchema } from 'types/api/client-service';

export class BasesApiService {
  public static async getBaseById(baseId: number): Promise<IDeliveryBase> {
    return AuthHelper.fetch(`/api/v1/oci/bases/${baseId}`);
  }

  public static async getBasisByIdV2(basisId: number): Promise<BasisRetrieveSchema> {
    return AuthHelper.fetch(`/api/v2/oci/bases/${basisId}`);
  }
}

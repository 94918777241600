<template>
  <div class="MainBrands">
    <div class="MainBrands__text">
      Нам доверяют

      <br>
      <div
        class="MainBrands__toggler"
        :class="{ 'MainBrands__toggler--active': activeBrands === EBrandsMode.Clients }"
        @click="toggleBrands(EBrandsMode.Clients)"
      >
        клиенты
      </div>
      и
      <div
        class="MainBrands__toggler"
        :class="{ 'MainBrands__toggler--active': activeBrands === EBrandsMode.Suppliers }"
        @click="toggleBrands(EBrandsMode.Suppliers)"
      >
        поставщики
      </div>
    </div>
    <div
      v-if="brands.length"
      class="MainBrands__list"
    >
      <MSlider
        :key="activeBrands"
        :swiper="{
          speed: 800,
          slidesPerView: 'auto',
          slidesPerGroupAuto: true,
          autoplay: false,
        }"
        @slider-init="onInitSlider"
      >
        <MSliderItem
          v-for="(brand, idx) in brands"
          :key="idx"
          class="MainBrands__item"
        >
          <MNuxtImg
            v-if="brand.img"
            :src="brand.img"
          />
        </MSliderItem>
      </MSlider>
    </div>
    <div class="MainBrands__nav">
      <MSliderNavigation
        v-if="sliderBrands && isShowNav"
        :slider="sliderBrands"
        :theme="MM.ESliderNavTheme.Light"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import MNuxtImg from 'components/MNuxtImg/MNuxtImg.vue';
import type { Swiper } from 'swiper/types';
import { MM } from '~/types';

enum EBrandsMode {
  Clients = 'clients',
  Suppliers = 'suppliers',
}

const activeBrands = ref(EBrandsMode.Clients);

const brandsConsumers = ref(
  Array.from({ length: 11 }, (_, i) => ({
    img: `/images/consumer-brands/consumer-brand-${i + 1}.svg`,
  })),
);

const brandsSuppliers = ref(
  Array.from({ length: 31 }, (_, i) => ({
    img: `/images/supplier-brands/supplier-brand-${i + 1}.svg`,
  })),
);

const brands = computed(() => (activeBrands.value === EBrandsMode.Suppliers ? brandsSuppliers.value : brandsConsumers.value));

const sliderBrands = ref<Swiper>();

const onInitSlider = (slider: Swiper) => {
  sliderBrands.value = slider;
};

// TODO поправить в библиотеке, там теряется слайдер при роутах, и при повторном роуте с этим модулем, навигация не рисовалась
const isShowNav = computed(() => sliderBrands.value.virtualSize > sliderBrands.value.width);

async function toggleBrands(val: EBrandsMode.Clients | EBrandsMode.Suppliers) {
  activeBrands.value = val;
  if (sliderBrands.value) {
    sliderBrands.value.slideTo(0);
  }
}
</script>

<style lang="scss" scoped>
.MainBrands {
  $s: &;

  user-select: none;
  display: flex;
  align-items: center;
  grid-gap: 24px 40px;
  max-width: 100%;
  overflow: hidden;

  &__text {
    color: rgba($color-white, 0.6);
    flex: 0 0 auto;
  }

  &__toggler {
    display: inline-block;
    border-bottom: 1px dashed white;
    cursor: pointer;
    transition: 0.2s;

    @include hover {
      color: $color-accent;
      border-color: $color-accent;
    }

    &--active {
      color: $color-white;
      pointer-events: none;
    }
  }

  &__list {
    user-select: none;
    flex: 0 1 auto;
    transition: 0.2s;
    display: flex;
    align-items: center;
    grid-gap: 40px;
    overflow: hidden;
    min-height: 84px;

    :deep(.swiper-wrapper) {
      align-items: center;
    }
  }

  &__item {
    width: auto;
    flex: 0 0 auto;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      padding-right: 80px;
    }
    :deep(img) {
      max-width: 100%;
    }
  }

  &__nav {
    width: 121px;
    flex: 0 0 121px;
    height: 48px;
  }
  &__item {
    :deep(img) {
      zoom: 0.75;
    }
  }

  @include maxw(1599) {
    &__item {
      :deep(img) {
        zoom: 0.56;
      }
    }
  }

  @include maxw(1279) {
    &__text {
      @include text-r14;
    }

    &__list {
      min-height: 38px;
    }
    &__item {
      :deep(img) {
        zoom: 0.45;
      }
      &:not(:last-child) {
        padding-right: 48px;
      }
    }
  }

  @include maxw(767) {
    flex-wrap: wrap;
    justify-content: space-between;

    &__list {
      width: 100%;
      order: 1;
    }
  }
}
</style>

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientSuborderState } from './ClientSuborderState';
import type { market__usecases__schemas__order_out__NeedResp } from './market__usecases__schemas__order_out__NeedResp';
export type SuborderResp = {
    id: number;
    number: string;
    supplierId: number;
    status: ClientSuborderState;
    dateDelivery?: string;
    cancelingAllowed: boolean;
    deliveryCost?: number;
    approverSub?: string;
    approvedByUser?: string;
    approved?: boolean;
    detail?: string;
    detailDate?: string;
    detailType?: SuborderResp.detail_type;
    isAvailableBasis: boolean;
    deliveryConditionsChanged?: SuborderResp.delivery_conditions_changed;
    initialClientDeliveryDate?: string;
    initialSupplierDeliveryDate?: string;
    needs: Array<market__usecases__schemas__order_out__NeedResp>;
};
export namespace SuborderResp {
    export enum detail_type {
        BOSS_APPROVEMENT = 'boss_approvement',
        BOSS_APPROVEMENT_AUTO = 'boss_approvement_auto',
        SUPPLIER_ORDER_SPLIT = 'supplier_order_split',
        SUPPLIER_ORDER_DECLINE = 'supplier_order_decline',
        SUPPLIER_ORDER_APPROVE_CHANGE = 'supplier_order_approve_change',
        SUPPLIER_ORDER_APPROVE = 'supplier_order_approve',
        SUPPLIER_ORDER_AUTO_DECLINE = 'supplier_order_auto_decline',
        ORDER_AUTO_DELETE_BY_ERP_MIN_COST = 'order_auto_delete_by_erp_min_cost',
        ORDER_APPROVE_DATE_EXPIRED = 'order_approve_date_expired',
        SUPPLIER_ORDER_AUTO_ACCEPT = 'supplier_order_auto_accept',
    }
    export enum delivery_conditions_changed {
        COST = 'COST',
        ALL = 'ALL',
        DATE = 'DATE',
    }
}


<template>
  <ClientOnly>
    <div
      ref="containerRef"
      class="select-wrapper"
    >
      <Select
        ref="selectRef"
        class="search-button"
        :model-value="modelValue"
        :options="options"
        :searchable="false"
        :deselect-from-dropdown="true"
        is-append-to-body
        :custom-calculate-position="calculatePositionDropdown"
        @update:model-value="$emit('update:modelValue', $event)"
        @open="onSelectOpen"
        @close="onSelectClose"
      >
        <template #search="{ attributes, events }">
          <div
            v-bind="attributes"
            class="search__dropdown"
            :class="{
              selected: !!modelValue && modelValue.value !== EVERYWHERE_SEARCH_OPTION.value,
              open: isOpen,
            }"
            v-on="events"
          >
            {{ modelValue?.label }}
          </div>
        </template>
        <template #selected-option-container>
          <span />
        </template>
        <template #open-indicator>
          <span />
        </template>
      </Select>
      <SvgIcon
        v-if="icon"
        class="search__dropdown-icon"
        :class="{
          selected: !!modelValue && modelValue.value !== EVERYWHERE_SEARCH_OPTION.value,
          open: isOpen,
        }"
        :src="icon"
        @click="$emit('update:modelValue', EVERYWHERE_SEARCH_OPTION)"
      />
    </div>
  </ClientOnly>
  <teleport to="body">
    <div
      v-if="isOpen"
      class="search__dropdown-backdrop"
      @click="onSelectClose"
    />
  </teleport>
</template>

<script lang="ts" setup>
import Select from 'shared/components/Select.vue';
import { SelectValue, ISelectOption } from 'shared/models/select.model';
import { useClickOutside } from 'shared/composables/useClickOutside';
import { EVERYWHERE_SEARCH_OPTION } from 'constants/search.const';
import { VueSelectInstance } from 'vue-select';
import { type ICalculatedPosition } from 'shared/models/select.model';
import { EIconPath } from 'shared/enums/iconPath.enum';
import { useHeaderStore } from '~/store/Header.store';

const props = defineProps<{
  modelValue: SelectValue;
  options: Array<ISelectOption>;
}>();

defineEmits<{
  (e: 'update:modelValue', selectedValue: SelectValue);
}>();

const headerStore = useHeaderStore();

const selectRef = ref<InstanceType<typeof Select>>();
const containerRef = ref<HTMLDivElement>();
const isOpen = headerStore.state.userBarSearchTypeOpened;

const icon = computed(() => {
  if (props.modelValue) {
    if (props.modelValue.value === EVERYWHERE_SEARCH_OPTION.value && !headerStore.state.userBarSearchTypeOpened) {
      return EIconPath.NavigationArrowDown20PxSvg;
    }

    if (props.modelValue?.value && headerStore.state.userBarSearchTypeOpened) {
      return EIconPath.NavigationArrowTop20PxSvg;
    }

    if (props.modelValue?.value) {
      return EIconPath.ActionCloseSvg;
    }
  }
});

function calculatePositionDropdown(dropdownList: HTMLUListElement, component: VueSelectInstance, position: ICalculatedPosition): void {
  const { top, left } = position;
  dropdownList.style.top = top;
  dropdownList.style.left = left;
  dropdownList.style.width = '358px';
  dropdownList.style.maxHeight = '320px';
}

function onSelectOpen() {
  selectRef.value?.setOpenSelect(true);
  headerStore.state.userBarSearchTypeOpened = true;
}

function onSelectClose() {
  selectRef.value?.setOpenSelect(false);
  headerStore.state.userBarSearchTypeOpened = false;
}

useClickOutside(containerRef, () => onSelectClose());
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.search-button {
  :deep(.vs__dropdown-menu) {
    max-height: 320px;
    width: 358px;
  }

  :deep(.vs__dropdown-toggle) {
    border: none !important;
    padding: 0 !important;
  }

  .search__dropdown {
    z-index: 10;
    white-space: nowrap;
    padding-right: 38px;
    margin: 0;
    -webkit-appearance: menulist-textfield;

    &.selected {
      background-color: $search-button-dropdown-selected-bc;
      color: $search-button-dropdown-selected-c;
      white-space: nowrap;
    }

    &.selected.open {
      background-color: $search-button-dropdown-selected-open-bc;
      color: $search-button-dropdown-selected-open-c;
    }
  }

  &.mm-select {
    :deep(.v-select) {
      .vs__selected-options {
        padding-right: 0;
      }
    }
  }
}

.search__dropdown-icon {
  height: 20px;
  width: 20px;
  position: absolute;
  z-index: 11;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  pointer-events: none;

  :deep(path) {
    fill: $search-button-dropdown-selected-bc;
  }
}

.search__dropdown-icon.selected {
  height: 20px;
  width: 20px;
  pointer-events: initial;

  :deep(path) {
    fill: #fff;
  }
}

.search__dropdown-icon.selected.open {
  height: 20px;
  width: 20px;

  :deep(path) {
    fill: $search-button-dropdown-selected-bc;
  }
}

.vs__dropdown-menu {
  z-index: 10;
}

.select-wrapper {
  display: flex;
  position: relative;
  margin: 8px 0 8px 8px;
  // min-width: 95px;
}

:global(.search__dropdown-backdrop) {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
</style>

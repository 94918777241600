export enum EFetchKey {
  Categories = 'categories',
  Category = 'category',
  SupplierCategory = 'supplierCategory',
  SupplierProducts = 'supplierProducts',
  Products = 'products',
  Filters = 'filters',
  Product = 'product',
  ProductCategory = 'productCategory',
  HeaderCategories = 'headerCategories',
  ProductSimilarsAndAnalogs = 'productSimilarsAndAnalogs',
  SearchCategories = 'searchCategories',
  DemandedNeeds = 'demandedNeeds'
}

import { ERedirectActions } from 'shared/enums/redirectActions.enum';
import { ERedirectErrors } from 'shared/enums/redirectErrors.enum';
import { ERedirectPayloadFields } from 'shared/enums/redirectPayloadFields.enum';
import { AccountApiService } from 'services/api/accountApi.service';
import Notificator from 'shared/services/notificator.service';
import { useUserStore } from 'store/user.store';
import { useAuthFnHelper } from 'composables/useAuthHelper';
import { UserHelper } from 'utils/userHelper.util';
import { CookieManager } from 'shared/utils/cookieManager.util';
import { ECookieKeys } from 'shared/enums/cookieKeys';
import { SsoAuthManagerService } from 'services/auth/ssoAuthManager.service';
import { IUserInfoResponse } from 'models/auth/userInfo.model';
import { AuthHelper } from './authHelper.util';
import { SupplierHelper } from 'utils/supplierHelper.util';
import { EClientOrderStatus } from 'shared/enums/orderStatus.enum';
import { AuthManagerService } from 'services/auth/authManager.service';
import { EQueryParam } from 'shared/enums/queryParam.enum';
import ModalManager from 'shared/services/modalManager.service';
import { NotificationApiService } from 'services/api/notificationApi.service';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import { BasketManager } from '~/services/basket-services/basket/basketManager.service';

export class RedirectManager {
  /*
   * Обработка событий происходит на SSR и на клиенте, если есть какая то логика например с отображением модалок или уведомлений
   * То необходимо добавлять проверку в начале метода if (process.server) return, чтобы избежать ошибок. Если нет какой то логики
   * на работу с клиентом, то необходимо возвращать ссылку чтобы редирект сработал на стороне SSR без отображения страницы редиректа.
   * */
  private static redirectActionAccordance = new Map<ERedirectActions, (payload?: Record<string, string>) => Promise<string | void>>([
    [ERedirectActions.NewOffer, RedirectManager.onNewOffer],
    [ERedirectActions.OfferChanged, RedirectManager.onOfferChanged],
    [ERedirectActions.BasketChanged, RedirectManager.onBasketChanged],
    [ERedirectActions.ErpOrder, RedirectManager.onErpOrderImported],
    [ERedirectActions.PasswordChangeProceed, RedirectManager.onChangeAccountPassword],
    [ERedirectActions.EmailChangedVerify, RedirectManager.onEmailChangedVerify],
    [ERedirectActions.KycResults, RedirectManager.onKycResultsReceived],
    [ERedirectActions.NewOrder, RedirectManager.onNewOderCreated],
    [ERedirectActions.Report, RedirectManager.onReportCompleted],
    [ERedirectActions.Auth, RedirectManager.onAuthCompleted],
    [ERedirectActions.OfferPolicy, RedirectManager.onOfferPolicyChanged],
    [ERedirectActions.SupplierShipments, RedirectManager.onSupplierShipments],
  ]);

  private static unauthenticatedRedirectActionAccordance = new Map<
    ERedirectActions,
    (payload?: Record<string, string>) => Promise<string | void>
  >([
    [ERedirectActions.SignupConfirm, RedirectManager.onSignupConfirm],
    [ERedirectActions.PasswordRestoreProceed, RedirectManager.onPasswordRestoreProceed],
    [ERedirectActions.IdpLogin, RedirectManager.onIdpLogin],
    [ERedirectActions.PasswordCreateProceed, RedirectManager.onPasswordCreate],
    [ERedirectActions.SubscriptionRequest, RedirectManager.onSubscriptionRequest],
  ]);

  private static beforeAuthenticatedRedirectActionAccordance = new Map<ERedirectActions, (payload?: Record<string, string>) => unknown>([
    [ERedirectActions.EmailChangedVerify, RedirectManager.onBeforeEmailChangedVerify],
  ]);

  private static godModeRedirectActionAccordance = new Map<ERedirectActions, (payload?: Record<string, string>) => Promise<void | string>>([
    [ERedirectActions.GodMode, RedirectManager.onGodMode],
  ]);

  static async applyRedirectAction(action?: string, payload?: Record<string, string>): Promise<string | void> {
    if (!action) {
      return;
    }

    const currentAction = action as ERedirectActions;

    if (this.godModeRedirectActionAccordance.has(currentAction)) {
      return RedirectManager.godModeRedirectActionAccordance.get(currentAction)?.(payload);
    }

    if (this.unauthenticatedRedirectActionAccordance.has(currentAction)) {
      return RedirectManager.unauthenticatedRedirectActionAccordance.get(currentAction)?.(payload);
    }

    if (this.beforeAuthenticatedRedirectActionAccordance.has(currentAction)) {
      await RedirectManager.beforeAuthenticatedRedirectActionAccordance.get?.(currentAction)?.(payload);
    }

    const userStore = useUserStore();
    if (userStore.isUserLoggedIn) {
      return RedirectManager.redirectActionAccordance.get(currentAction)?.(payload);
    }

    if (process.server) {
      return;
    }

    useAuthFnHelper().showLoginModal(
      async () => {
        userStore.isUserLoggedIn
          ? await RedirectManager.redirectActionAccordance.get(currentAction)?.(payload)
          : RedirectManager.goToMainPage();
      },
      undefined,
      undefined,
      false,
    );
  }

  static showSignupNotification(
    redirectConfig: { redirectAction?: ERedirectActions; redirectError?: ERedirectErrors },
    notificator: Notificator,
  ) {
    if (redirectConfig.redirectAction !== ERedirectActions.SignupConfirm || process.server || useUserStore().isUserLoggedIn) {
      return;
    }

    if (redirectConfig.redirectError === ERedirectErrors.SignupConfirmExpired) {
      notificator.setLinkExpiredAction();
      return;
    }

    notificator.setVerifiedAction();
  }

  private static async onSignupConfirm(payload?: Record<string, string>): Promise<void> {
    if (process.server) {
      return;
    }

    const tokenUuid = payload?.[ERedirectPayloadFields.TokenUuid];

    if (!tokenUuid) {
      this.goToMainPage();
      return;
    }

    try {
      await AccountApiService.verifyEmail(tokenUuid);
    } catch (error) {
      CookieManager.setCookieObject(ECookieKeys.RedirectError, ERedirectErrors.SignupConfirmExpired, null, true);
    } finally {
      RedirectManager.goToMainPage();
    }
  }

  private static async onNewOffer(payload?: Record<string, string>): Promise<string | void> {
    if (UserHelper.isClient) {
      return process.client ? RedirectManager.goToMainPage() : '/';
    }

    const orderLink = `/supplier/order/${payload?.[ERedirectPayloadFields.SupplierOrderId]}`;

    if (process.server) {
      return orderLink;
    }

    navigateTo(orderLink);
  }

  private static async onOfferChanged(payload?: Record<string, string>): Promise<string | void> {
    if (UserHelper.isClient) {
      const clientOrderLink = `/client/orders/${payload?.[ERedirectPayloadFields.OrderId]}`;

      if (process.server) {
        return clientOrderLink;
      }

      await navigateTo(clientOrderLink);
      return;
    }

    const supplierOrderLink = `/supplier/orders/${payload?.[ERedirectPayloadFields.OrderId]}`;
    if (process.server) {
      return supplierOrderLink;
    }

    navigateTo(supplierOrderLink);
  }

  private static async onBasketChanged(): Promise<string | void> {
    if (!UserHelper.isClient) {
      return process.client ? RedirectManager.goToMainPage() : '/';
    }

    if (process.server) {
      return '/basket';
    }

    navigateTo('/basket');
  }

  private static async goToMainPage(): Promise<void> {
    await navigateTo('/');
  }

  private static async onErpOrderImported(payload?: Record<string, string>): Promise<string | void> {
    if (!UserHelper.isClient) {
      return process.client ? RedirectManager.goToMainPage() : '/';
    }

    if (!payload?.id) {
      process.client ? navigateTo('/client/orders/') : '/client/orders/';
      return;
    }

    const orderLink = `/client/orders/erp/${payload[ERedirectPayloadFields.Id]}`;
    if (process.server) {
      return orderLink;
    }

    navigateTo(orderLink);
  }

  private static async onPasswordRestoreProceed(payload?: Record<string, string>, modalTitle?: string): Promise<void> {
    if (process.server) {
      return;
    }

    const tokenUuid = payload?.[ERedirectPayloadFields.TokenUuid];

    if (!tokenUuid) {
      return RedirectManager.goToMainPage();
    }

    try {
      if (!(await AccountApiService.checkLinkToken(tokenUuid))?.exists) {
        Notificator.showDetachedNotification('Ссылка устарела. Попробуйте сменить пароль еще раз');
        RedirectManager.goToMainPage();
        return;
      }
    } catch (error) {
      console.error(error);
      RedirectManager.goToMainPage();
      return Notificator.showDetachedNotification('Произошла ошибка. Попробуйте заново');
    }

    useAuthFnHelper().showPasswordRecoveryFormModal(
      modalTitle,
      tokenUuid,
      async () => {
        Notificator.showDetachedNotification('Пароль успешно изменен');
        if (!AuthManagerService.authStatus.isLoggedIn) {
          await navigateTo({
            path: '/',
            query: {
              [EQueryParam.Auth]: 'true',
            },
          });
        }

        if (!UserHelper.isIntegration) {
          await AuthManagerService.logout(true);
          await nextTick(() => ModalManager.getInstance().hideAll());
        }
      },
      async () => {
        const query = useRoute?.()?.query;

        if (!AuthManagerService.authStatus.isLoggedIn) {
          await navigateTo({
            path: '/',
            query: {
              [EQueryParam.Auth]: query?.[EQueryParam.Auth],
            },
          });
        }
      },
    );
  }

  private static async onPasswordCreate(payload?: Record<string, string>): Promise<void> {
    if (process.server) {
      return;
    }

    const tokenUuid = payload?.[ERedirectPayloadFields.TokenUuid];

    if (!tokenUuid) {
      return RedirectManager.goToMainPage();
    }

    try {
      const { exists } = await AccountApiService.checkLinkToken(tokenUuid);

      if (!exists) {
        return RedirectManager.onErrorRedirect('Ссылка устарела. Попробуйте запросить ссылку еще раз');
      }
    } catch (error) {
      RedirectManager.onErrorRedirect();
    }

    useAuthFnHelper().showPasswordRecoveryFormModal(
      'Создание пароля',
      tokenUuid,
      async () => {
        Notificator.showDetachedNotification('Пароль успешно создан');
        await navigateTo({
          path: '/',
          query: {
            [EQueryParam.Auth]: 'true',
          },
        });
      },
      RedirectManager.goToMainPage,
      {
        submitFn: AccountApiService.confirmPasswordCreate,
        errorNotification: 'Возникла ошибка при попытке создания пароля, попробуйте, пожалуйста, ещё раз',
        passwordFieldPlaceholder: 'Введите пароль',
        confirmFieldPlaceholder: 'Подтвердите пароль',
      },
    );
  }

  private static async onChangeAccountPassword(payload?: Record<string, string>): Promise<void> {
    const tokenUuid = payload?.[ERedirectPayloadFields.TokenUuid];

    if (!tokenUuid) {
      return RedirectManager.goToMainPage();
    }

    if (process.server) {
      return;
    }

    useUserStore().setToken(tokenUuid);

    try {
      const { exists } = await AccountApiService.checkLinkToken(tokenUuid);

      if (!exists) {
        return RedirectManager.onErrorRedirect('Ссылка устарела. Попробуйте сменить пароль еще раз');
      }
    } catch (error) {
      RedirectManager.onErrorRedirect();
    }

    if (UserHelper.isSupplier) {
      return await RedirectManager.changeSupplierPasswordRedirect();
    }

    if (!UserHelper.isIntegration) {
      return await RedirectManager.changeClientPasswordRedirect(payload);
    }

    return await RedirectManager.goToMainPage();
  }

  private static async changeSupplierPasswordRedirect(): Promise<void> {
    await navigateTo('/supplier/settings/change-password');
  }

  private static async changeClientPasswordRedirect(payload: Record<string, string>): Promise<void> {
    await navigateTo('/client/profile');
    await RedirectManager.onPasswordRestoreProceed(payload, 'Создание нового пароля');
  }

  private static async onBeforeEmailChangedVerify(payload?: Record<string, string>): Promise<void> {
    if (process.server) {
      return;
    }

    const tokenUuid = payload?.[ERedirectPayloadFields.TokenUuid];

    if (!tokenUuid) {
      return RedirectManager.goToMainPage();
    }

    try {
      if (useUserStore()?.isUserLoggedIn) {
        await AuthHelper.fetch('/api/v1/auth/logout', { method: 'POST' });
        useUserStore()?.setUserLogout();
      }

      await AccountApiService.confirmEmailChange(tokenUuid);
      Notificator.showDetachedNotification('Почта успешно изменена');
    } catch (error) {
      RedirectManager.onErrorRedirect();
      throw error;
    }
  }

  private static async onEmailChangedVerify(payload?: Record<string, string>): Promise<string | void> {
    if (process.server) {
      return;
    }

    const tokenUuid = payload?.[ERedirectPayloadFields.TokenUuid];

    if (!UserHelper.isSupplier || !tokenUuid) {
      return RedirectManager.goToMainPage();
    }

    await navigateTo('/supplier/settings/');
  }

  private static async onKycResultsReceived(payload?: Record<string, string>): Promise<string | void> {
    const incomingSupplierId = payload?.[ERedirectPayloadFields.SupplierId];
    const { supplierId } = useUserStore();

    if (!UserHelper.isSupplier || !incomingSupplierId || supplierId !== +incomingSupplierId) {
      return process.client ? RedirectManager.onErrorRedirect('Ссылка недействительна.') : '/';
    }

    if (SupplierHelper.isQualificationAccepted) {
      const ordersLink = '/supplier/orders/?orderType=all';

      if (process.server) {
        return ordersLink;
      }

      await navigateTo(ordersLink);
      return;
    }

    const qualificationLink = '/supplier/qualifications/intro/';
    if (process.server) {
      return qualificationLink;
    }

    await navigateTo(qualificationLink);
  }

  private static async onNewOderCreated(payload?: Record<string, string>): Promise<string | void> {
    if (!payload?.order_id) {
      const ordersLink = '/client/orders';

      if (process.server) {
        return ordersLink;
      }

      await navigateTo(ordersLink);
      return;
    }

    const orderLink = `/client/orders/${payload?.order_id}/`;
    if (process.server) {
      return orderLink;
    }

    await navigateTo(orderLink);
  }

  private static async onIdpLogin(): Promise<void> {
    if (process.server) {
      return;
    }

    await SsoAuthManagerService.startSsoAuth(window?.location.origin);
  }

  private static onErrorRedirect(message = 'Ссылка устарела.') {
    Notificator.showDetachedNotification(message);
    return RedirectManager.goToMainPage();
  }

  private static async onGodMode(payload?: Record<string, string>): Promise<void> {
    if (process.server) {
      return;
    }

    const encodedTokens = payload?.[ERedirectPayloadFields.TokenUuid];
    try {
      const result = await AuthHelper.fetch<IUserInfoResponse>('/api/v1/auth/set_cookie_god_mode', {
        method: 'POST',
        body: { encodedTokens },
      });

      const { userInfo } = await AuthHelper.fetch<{ userInfo: IUserInfoResponse }>('/api/v1/auth/user_info');
      useUserStore()?.setUserInfo(userInfo);
      useUserStore()?.setMpAdminInfoEmail(result?.email);

      const orderId = payload?.[ERedirectPayloadFields.OrderId];
      const orderStatus = payload?.[ERedirectPayloadFields.OrderStatus];

      new BasketManager().clear();

      if (UserHelper.isSupplier) {
        await navigateTo(orderId ? `/supplier/order/${orderId}/` : '/supplier/orders/');
        return;
      }

      if (UserHelper.isClient) {
        await navigateTo(
          orderId
            ? `${orderStatus === EClientOrderStatus.Draft ? `/client/orders/erp/${orderId}/` : `/client/orders/${orderId}/`}`
            : '/client/profile/',
        );
        return;
      }

      RedirectManager.goToMainPage();
    } catch (e) {
      console.error('onGodMode', e);
      await AuthHelper.fetch('/api/auth/deleteCookie', { method: 'DELETE' });
      CookieManager.setCookieObject(ECookieKeys.RedirectAction, null);
      RedirectManager.goToMainPage();
    }
  }

  private static async onReportCompleted(): Promise<string | void> {
    const reportsLink = '/client/reports/';
    if (process.server) {
      return reportsLink;
    }

    await navigateTo(reportsLink);
  }

  private static async onAuthCompleted(payload?: Record<string, string>): Promise<void | string> {
    const toLink = payload?.[ERedirectPayloadFields.To] ?? '/';

    if (process.server) {
      return toLink;
    }

    await navigateTo(toLink);
  }

  private static async onOfferPolicyChanged(payload?: Record<string, string>): Promise<string | void> {
    if (process.server) {
      return;
    }
    window.open(
      `/files/supplier/offer_policy/${payload?.[ERedirectPayloadFields.OfferPolicyId]}`,
      payload?.[ERedirectPayloadFields.NewTab] ? '_blank' : '_self',
    );
  }

  private static async onSubscriptionRequest(payload?: Record<string, string>): Promise<string | void> {
    const tokenId = payload?.[ERedirectPayloadFields.TokenId];

    if (!tokenId) {
      return process.server ? '/' : await RedirectManager.goToMainPage();
    }

    try {
      await NotificationApiService.verifySubscription(tokenId);
      if (process.server) {
        return '/subscription/success/';
      }

      await navigateTo('/subscription/success/');
    } catch (error) {
      clientSentry.captureServiceException(error, 'RedirectManager', undefined, {
        extra: {
          tokenId,
        },
      });

      if (process.server) {
        return '/';
      }

      Notificator.showDetachedNotification('Ошибка при подтверждении подписки');
      await navigateTo('/');
    }
  }

  private static async onSupplierShipments(payload?: Record<string, string>): Promise<string | void> {
    const shipmentId = payload?.[ERedirectPayloadFields.ShipmentId];
    const shipmentLink = `/supplier/shipments/${Number(shipmentId) || ''}`;

    if (process.server) {
      return shipmentLink;
    }

    await navigateTo(shipmentLink);
  }
}

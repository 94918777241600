<template>
  <div
    class="HeaderMobileMenu"
    :class="{ 'HeaderMobileMenu--opened': props.isOpened }"
  >
    <div
      class="HeaderMobileMenu__overlay"
      @click="onClose()"
    />
    <div class="HeaderMobileMenu__container">
      <div class="HeaderMobileMenu__header">
        <HeaderLogo
          no-prefetch
          :fixed-src="MM.EIconPath.ImagesLogoDescOff"
        />

        <div
          class="HeaderMobileMenu__close"
          @click="onClose()"
        >
          <MIcon :src="MM.EIconPath.NavigationClose24PxSvg" />
        </div>
      </div>

      <div class="HeaderMobileMenu__body">
        <MScroll />
        <div
          v-if="!userStore.isUserLoggedIn"
          class="HeaderMobileMenu__login"
        >
          <LoginButton in-mobile-menu />
        </div>

        <div
          v-if="!userStore.isUserLoggedIn"
          class="HeaderMobileMenu__separator"
        />

        <div
          v-if="userStore.isUserLoggedIn"
          class="HeaderMobileMenu__user"
        >
          <div class="user-profile">
            <div class="user-profile__content">
              {{ userName }}

              <MButton
                :theme="MM.EButtonThemes.Primary"
                :type="MM.EButtonTypes.Text"
                @click="onLogout"
              >
                Выйти из аккаунта
              </MButton>
            </div>

            <div class="user-profile__icon">
              <MIcon :src="MM.EIconPath.NavigationLkSvg" />
            </div>
          </div>
        </div>

        <nav class="HeaderMobileMenu__nav">
          <NuxtLink
            v-for="(link, index) in links"
            :key="index"
            :to="link.path"
            class="HeaderMobileMenu__nav-item"
            no-prefetch
            @click="emit('close')"
          >
            {{ link.label }}
          </NuxtLink>
        </nav>
      </div>

      <div class="HeaderMobileMenu__footer">
        <div class="HeaderMobileMenu__contacts">
          <a
            v-for="(item, key) in linksInfo"
            :key="key"
            :href="sanitizeUrl(item.link)"
            class="HeaderMobileMenu__contacts-item"
          >
            {{ item.label }}
          </a>
        </div>
        <a href="mailto:support@maksmart.ru">
          <MButton
            class="HeaderMobileMenu__callback"
            full-width
            :theme="MM.EButtonThemes.PrimaryMuted"
            :type="MM.EButtonTypes.Outline"
          >
            Свяжитесь с нами
          </MButton>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { sanitizeUrl } from '@braintree/sanitize-url';
import { IGroupMenuLinkItem } from 'shared/models/groupMenuLinkItem.model';
import IMenuLinkItem from 'shared/models/linkItem.model';
import { useUserStore } from 'store/user.store';
import LoginButton from 'components/LoginButton.vue';
import { getFullNameAbbreviation } from 'shared/utils/fullNameAbbreviation.util';
import { MM } from '~/types';
import { AuthManagerService } from 'services/auth/authManager.service';
import HeaderLogo from './HeaderLogo.vue';

const props = withDefaults(
  defineProps<{
    /** Список ссылок */
    links?: IGroupMenuLinkItem[] | IMenuLinkItem[];
    isOpened?: boolean;
  }>(),
  {
    isOpened: false,
  },
);

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const userStore = useUserStore();
const userName = computed(() =>
  getFullNameAbbreviation(
    userStore.userInfo?.lastName,
    userStore.userInfo?.name,
    userStore.userInfo?.patronymic,
    userStore.userInfo?.email,
  ),
);

function onClose() {
  emit('close');
}

function onLogout() {
  AuthManagerService.logout();
}

const linksInfo = ref<
  Array<{
    label: string;
    link: string;
  }>
>([
  {
    label: '+7 (800) 500-81-06',
    link: 'tel:+78005008106',
  },
  {
    label: 'support@maksmart.ru',
    link: 'mailto:support@maksmart.ru',
  },
]);
</script>

<style lang="scss" scoped>
.HeaderMobileMenu {
  $s: &;

  z-index: 99;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;

  @include minw(1280) {
    display: none;
  }

  &__overlay {
    background-color: $color-black;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s;
  }

  &__container {
    transform: translate(100%, 0);
    opacity: 0;
    transition: 0.3s;
    background-color: $color-white;
    height: 100dvh;
    width: 380px;
    max-width: 100vw;
    position: fixed;
    z-index: 99;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    border-left: 1px solid $color-gray-light;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-gray-light;
    padding: 20px;
  }

  &__body {
    flex: 1 1 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }

  &__footer {
    border-top: 1px solid $color-gray-light;
    padding: 20px;
  }

  &__separator {
    margin-left: -20px;
    margin-right: -20px;
    border-bottom: 1px solid $color-gray-light;
  }

  &__nav {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;

    &-item {
      @include text-m32;

      text-decoration: none;
      color: $color-text-main;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        border-width: 4px 6px;
        border-style: solid;
        border-color: transparent transparent transparent $color-text-main;
      }
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 20px;
    margin: 20px 0;

    &-item {
      @include text-m32;

      text-decoration: none;
      color: $color-text-main;
    }
  }

  &__callback {
    margin-top: 20px;
  }

  &--opened {
    #{$s}__overlay {
      opacity: 0.5;
      pointer-events: auto;
    }

    #{$s}__container {
      transform: translate(0, 0);
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.user-profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f2f5f4;
  border-radius: 8px;
  padding: 14px 16px;

  &__content {
    color: #214237;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__icon {
    background-color: #fff;
    border-radius: 50%;
    padding: 12px;
    flex: 0 0 auto;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 0;
  }
}
</style>

<template>
  <div class="CompanyAwards">
    <h2 class="CompanyAwards__title">
      Максмарт высоко оценивается <br>
      в профессиональном комьюнити
    </h2>
    <div class="CompanyAwards__list">
      <div
        v-for="(item, i) in list"
        :key="i"
        class="CompanyAwards__item"
        :class="[
          item.isWide ? 'CompanyAwards__item--wide' : 'CompanyAwards__item--default',
          item.theme === ECompanyAwardsTheme.Dark ? 'CompanyAwards__item--theme-dark' : 'CompanyAwards__item--theme-light',
        ]"
      >
        <div class="CompanyAwards__item-img">
          <MNuxtImg
            :src="item.imgSrc"
            :sources="item.imgSrcMobile ? [{ src: item.imgSrcMobile, media: '(max-width: 767px)' }] : []"
          />
        </div>
        <div
          class="CompanyAwards__item-title"
          v-html="item.title"
        />
        <div
          v-if="item.text"
          class="CompanyAwards__item-text text-r16"
          v-html="item.text"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import MNuxtImg from 'components/MNuxtImg/MNuxtImg.vue';

enum ECompanyAwardsTheme {
  Dark = 'dark',
}

const list: Array<{
  /** Заголовок */
  title: string;
  /** Тема карточки (по умолчанию светлая) */
  theme?: ECompanyAwardsTheme;
  /** Ссылка на картинку */
  imgSrc: string;
  imgSrcMobile?: string;
  /** Описание */
  text?: string;
  /** Широкая карточка */
  isWide?: boolean;
}> = [
  {
    theme: ECompanyAwardsTheme.Dark,
    title: 'Победитель премии <br>«Время инноваций»',
    text: `«Технологическая инновация года 2023» <br>в категории «Бизнес и сервис»
      <br>
      <br>
      «Импортозамещение: продукт года 2024» <br>в категории «Бизнес и сервис. Управление закупками»`,
    imgSrc: '/images/company-awards/CompanyAwards-1-glow.png',
    imgSrcMobile: '/images/company-awards/CompanyAwards-1-mobile.png',
    isWide: true,
  },
  {
    theme: ECompanyAwardsTheme.Dark,
    title: '«Лучший клиентский опыт в B2B» CX WORLD AWARDS 2024',
    imgSrc: '/images/company-awards/CompanyAwards-3.png',
  },
  {
    title: '«Лучший маркетплейс»<br> Tagline Awards 2023',
    imgSrc: '/images/company-awards/CompanyAwards-2.png',
  },
  {
    theme: ECompanyAwardsTheme.Dark,
    title: '«Лучший импортозамещающий сайт» Tagline Awards 2023',
    imgSrc: '/images/company-awards/CompanyAwards-4.png',
  },
  {
    title: '«Лучший маркетплейс»<br> Tagline Awards 2022',
    imgSrc: '/images/company-awards/CompanyAwards-5.png',
  },
  {
    theme: ECompanyAwardsTheme.Dark,
    title: 'Международная премия<br> в области создания сильных команд Team Awards',
    text: 'Победитель номинации «ЛИДЕР ГОДА»<br> в категории «Диджитал»',
    imgSrc: '/images/company-awards/CompanyAwards-6-glow.png',
    imgSrcMobile: '/images/company-awards/CompanyAwards-6-mobile.png',
    isWide: true,
  },
];
</script>

<style lang="scss" scoped>
.CompanyAwards {
  $s: &;
  background-color: $color-green-extra-dark;
  padding: 60px var(--container-offset-X);
  border-radius: 12px 12px 0 0;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -20px;
    height: 21px;
    left: 0;
    right: 0;
    background-color: $color-green-extra-dark;
    pointer-events: none;
  }

  &__title {
    @include text-m48;

    margin-bottom: 32px;
    color: $color-white;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 8px;
  }

  &__item {
    padding: 32px;
    border-radius: 8px;
    position: relative;
    height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    background-position: 50% 50%;
    background-repeat: repeat-y;

    &-title {
      @include text-m20;
    }

    &-img {
      :deep(img) {
        max-width: 100%;
        max-height: 100%;
      }
    }
    &-text {
      @include text-r16;

      opacity: 0.6;
    }

    &--wide {
      padding: 64px 40px;
      padding-right: 320px;
      grid-column: auto / span 2;
      z-index: 1;
      &:before {
        content: '';
        flex-grow: 1;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-image: url('/images/accordion-gradients.svg');

        opacity: 0.9;
        pointer-events: none;
      }

      #{$s}__item-title {
        @include text-m28;
      }
      #{$s}__item-text {
        margin-top: 20px;
      }

      #{$s}__item-img {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: -1;
        @include cover;
      }
    }

    &--theme-light {
      background-color: $color-gray-extra-light;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-image: url('/images/accordion-gradients.svg');
        opacity: 0.9;
      }
    }

    &--theme-dark {
      color: $color-white;
      background-color: $color-green-dark-muted;
    }
  }

  @include maxw(1599) {
    &__item {
      padding-left: 24px;
      padding-right: 24px;

      &--wide {
        padding-right: calc(100% - 400px);
      }
    }
  }
  @include maxw(1279) {
    &__list {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @include maxw(991) {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
    @include minw(768) {
      &__item {
        &--wide {
          #{$s}__item-img {
            :deep(img) {
              object-position: 50% 100%;
            }
          }
          &:after {
            background-size: cover;
          }
        }
      }
    }
  }
  @include maxw(767) {
    &__list {
      grid-template-columns: repeat(1, 1fr);
    }
    &__item {
      height: auto;
      border-radius: 8px;
      text-align: center;
      padding: 20px 12px;
      min-height: 320px;

      &-title {
        @include text-m24;
      }

      &--default {
        padding: 32px 48px;
        #{$s}__item-img {
          margin-bottom: 32px;
        }
      }

      &--wide {
        grid-column: 1 / 2;
        text-align: left;
        padding-bottom: 32px;
        padding-right: 120px;
        min-height: 320px;

        #{$s}__item-title {
          @include text-m24;
        }

        #{$s}__item-img {
          top: auto;
          left: auto;
          :deep(img) {
            object-fit: none;
          }
        }
      }
    }
  }

  // Скругления
  @include minw(768) {
    &__item:nth-child(1) {
      border-bottom-right-radius: 120px;
    }
    &__item:nth-child(2) {
      border-top-right-radius: 120px;
    }
    &__item:nth-child(3) {
      border-bottom-right-radius: 120px;
    }
    &__item:nth-child(4) {
      border-top-right-radius: 120px;
    }
    &__item:nth-child(5) {
      border-bottom-right-radius: 120px;
    }
    &__item:nth-child(6) {
      border-top-left-radius: 120px;
    }
  }

  @include maxw(767) {
    &__item {
      border-top-right-radius: 120px;
    }
  }
}
</style>

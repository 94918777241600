import { orderBy } from 'lodash-es';
import { IUseProductOffers, IUseProductOffersParams } from 'models/composables/useProductOffers.model';
import { IProductResultedOffer, IProductCurrentOverallPrice } from 'shared/models/product.model';
import { ESortState } from 'shared/enums/sortState.enum';
import { ITableSort } from 'shared/models/table.model';
import { EProductSortField } from 'enums/EProductSortField.enum';
import { EOfferCalcTypes } from 'shared/enums/offerCalcTypes.enum';

export function useProductOffers(params: IUseProductOffersParams): IUseProductOffers {
  const currentOverallPrice = computed<IProductCurrentOverallPrice>(() =>
    OfferMapper.getCurrentOverallPrice(
      undefined,
      params.selectedCalcType?.value?.value as Array<EOfferCalcTypes> || [],
    ),
  );

  const offersSortState = ref<ITableSort>({
    field: currentOverallPrice.value?.type,
    order: ESortState.Asc,
  });

  const cleanOffers = computed<Array<IProductResultedOffer>>(() =>
    ((params?.offersData?.value?.items as unknown as Array<IProductResultedOffer>) || [])
      .sort((a, b) =>
        !params.isShowPrice?.value
          ? 0
          : a?.bestPrice || (a?.bestDelivery && !b?.bestPrice)
            ? -1
            : 1,
      ),
  );

  const offersBySortState = computed<Array<IProductResultedOffer>>(() =>
    orderBy(
      cleanOffers.value,
      offersSortState.value?.field === EProductSortField.MaxDeliveryDays
        ? [EProductSortField.MaxDeliveryDays, EProductSortField.MinDeliveryDays]
        : [offersSortState.value?.field],
      offersSortState.value?.field === EProductSortField.MaxDeliveryDays
        ? [offersSortState.value?.order, offersSortState.value?.order]
        : [offersSortState.value?.order],
    ),
  );

  const sortedOffers = computed<Array<IProductResultedOffer>>(
    () => !offersSortState.value ? cleanOffers.value : offersBySortState.value,
  );

  const filteredOffers = computed<Array<IProductResultedOffer>>(() => {
    const preFiltered = sortedOffers.value.filter((offer: IProductResultedOffer) => offer.stock >= Number(params.productsCount.value) || !offer.available);

    /* Помещаем оффер с заданным айди каталога поставщика в начало списка всегда, потом в порядке сортировки */
    if (params.catalogId) {
      return [
        preFiltered.find(({ catalogId }) => catalogId == params.catalogId),
        ...preFiltered.filter(({ catalogId }) => catalogId != params.catalogId),
      ];
    }

    return preFiltered;
  });

  const offersCount = computed<number>(() => (filteredOffers.value || []).length);

  return {
    cleanOffers,
    offersBySortState,
    offersSortState,
    currentOverallPrice,
    filteredOffers,
    offersCount,
  };
}

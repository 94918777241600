<template>
  <div class="multi-category--wrapper">
    <div class="multi-category__label">
      {{ label }}
    </div>
    <div class="multi-category">
      <div
        :ref="(ref) => (multiCategoryRef = ref)"
        class="multi-category__list"
        :class="{
          closed: isShowArrow && !isOpen,
        }"
      >
        <MButton
          v-for="(category, index) in categories"
          :key="category.id"
          class="multi-category__item"
          :size="MM.EButtonSizes.Small"
          :theme="categoryButtonTheme(index, category.id)"
          @click="onClickCategory(category)"
        >
          {{ category.title }}
        </MButton>
      </div>
      <MButton
        v-if="isShowArrow"
        :theme="MM.EButtonThemes.Secondary"
        :icon="isOpen ? MM.EIconPath.NavigationArrowUpSvg : MM.EIconPath.NavigationArrowDownSvg"
        is-square
        @click="isOpen = !isOpen"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { MM } from 'types';
import { ICategory } from 'shared/models/category.model';
import { WatchSubscription } from 'shared/utils/watchSubscription';
import useSSRUnsubscribeWatch from 'shared/composables/useSSRUnsubscribeWatch';

const props = defineProps<{
  modelValue: ICategory | null;
  categories: Array<ICategory>;
  label?: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', selectedValue: ICategory | null): void;
}>();

const multiCategoryRef = ref<HTMLDivElement>();
const watchSubscription = new WatchSubscription();
const isShowArrow = ref(true);

const isOpen = ref(false);

/** устанавливает тему кнопки  */
function categoryButtonTheme(index: number, id: number): MM.EButtonThemes {
  return (!props.modelValue && index === 0) || id === props.modelValue?.id ? MM.EButtonThemes.PrimaryDarkMuted : MM.EButtonThemes.Secondary;
}
function onClickCategory(category: ICategory | null): void {
  emit('update:modelValue', category);
}

function getSumOfChildWidth(element: HTMLDivElement | undefined): number {
  return Array.from(element?.children ?? []).reduce((acc, el) => acc + el.clientWidth, 0) ?? 0;
}

function recalculateFunction() {
  const sumOfChildWidth = getSumOfChildWidth(multiCategoryRef.value);
  isShowArrow.value = (multiCategoryRef.value?.clientWidth ?? 0) < sumOfChildWidth;
}

function recalculate() {
  nextTick(() => recalculateFunction());
}

watchSubscription.add(
  watch(
    () => props.categories?.length,
    () => recalculate(),
    {
      flush: 'post',
    },
  ),
);

useSSRUnsubscribeWatch(watchSubscription);

onMounted(() => {
  recalculate();
});
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.multi-category {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: 20px;
  margin-bottom: -20px;
  border-bottom: 1px solid $gray-200;
  position: relative;
  z-index: 2;

  &__label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $multi-category-label-c;
    white-space: nowrap;
    margin-bottom: 16px;
  }

  &__list {
    display: flex;
    flex-flow: row wrap;
    overflow: hidden;
    gap: 4px;
    flex: 1;

    &.closed {
      height: 36px;
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;

    border: none;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 12px;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 1279px) {
  .multi-category {
    align-items: flex-start;
    padding-bottom: 12px;
    margin-bottom: -28px;

    &__list {
      &.closed {
        height: 36px;
      }
    }

    &__item {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      padding: 4px 6px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .multi-category {
    padding-bottom: 12px;
    &__label {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
</style>

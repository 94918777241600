/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SAMLLoginSchema = {
    redirectUrl: string;
    provider?: SAMLLoginSchema.provider;
    email?: string;
};
export namespace SAMLLoginSchema {
    export enum provider {
        POLYUS = 'POLYUS',
        AIM = 'AIM',
        NLMK = 'NLMK',
    }
}


import { useUserStore } from 'store/user.store';
import { UserHelper } from 'utils/userHelper.util';
import Loader from 'shared/utils/loaderHelper.util';
import Notificator from 'shared/services/notificator.service';
// import ModalManager from 'shared/services/modalManager.service';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import { DocumentsApiService } from 'services/api/documentsApi.service';
import { IUpdRegistryUnfilledUpd } from 'models/supplier/supplierUpdRegistry.model';
import { DateFormat } from 'shared/utils/dateFormat.util';
import { MModalsManager } from '@maksmart/maksify/dist/services';
import FillUpdLockModal from 'components/modals/FillUpdLockModal.vue';

export function useSupplierUnfilledUpdRegistry() {
    const userStore = useUserStore();
    const loadingUnfilledUpd = Loader.getReactiveInstance();
    // const modalManager = inject<ModalManager>(ModalManager.getServiceName());

    const dateNow = new Date();
    const unfilledUpd = ref<IUpdRegistryUnfilledUpd>();
    const deadlineFillUpdDate = ref<Date | undefined>();

    const monthFillingPeriod = computed(
      () => deadlineFillUpdDate.value
        ? DateFormat.month(new Date(dateNow.getFullYear(), deadlineFillUpdDate.value.getMonth() - 1)).toLowerCase()
        : undefined,
    );

    function isAllowUpdRegistry(): boolean {
        return UserHelper.isSupplier && SupplierHelper.isQualificationCompleted;
    }

  function isNeedWarningByDate(): boolean {
        return dateNow.getDate() >= 5 && dateNow.getDate() <= 20;
    }

    function isUpdLoadPage(): boolean {
      const route = useRoute();
      return route?.name === 'supplier-documents-index-upd-registry';
    }

    async function checkUnfilledUpd(isNeedWarningModal = false): Promise<void> {
        if (!isAllowUpdRegistry() || !isNeedWarningByDate() || userStore.supplierId == null) {
            return;
        }

        loadingUnfilledUpd.activate();
        try {
            unfilledUpd.value = await DocumentsApiService.getUpdRegistryUnfilledUpd(userStore.supplierId);
            // Временно в январе ставим 15, задача еще в работе, будет переделана логика показа под требования задачи. Сейчачс - костыль на неделю (15 января)
            if (unfilledUpd.value?.count) {
                deadlineFillUpdDate.value = new Date(dateNow.getFullYear(), dateNow.getMonth(), 15);
                isNeedWarningModal && (await showWarningLockModal());
            }
        } catch (error) {
            clientSentry.captureException(error);
            Notificator.showDetachedNotification('Произошла ошибка при запросе незаполненных УПД');
        } finally {
            loadingUnfilledUpd.deactivate();
        }
    }

    // Закомментил до окончания выполнения задачи в целом
    // async function showWarningModal(): Promise<void> {
    //     if (
    //       !deadlineFillUpdDate.value ||
    //       !await modalManager?.showConfirmModal(
    //         'Заполните реестр УПД',
    //         `Необходимо заполнить реестр УПД за ${monthFillingPeriod.value} до ${DateFormat.default(deadlineFillUpdDate.value)}`,
    //         'Заполнить',
    //         'Позже',
    //         { contentTextClass: 'mm-fs-14px' },
    //       )
    //     ) {
    //         return;
    //     }
    //
    //     await navigateTo('/supplier/documents/upd-registry/');
    // }

    async function showWarningLockModal() {
      // Будет разбито на несколько функций при окончательной решении этой задачи
      if (!isUpdLoadPage()) {
        const modalId = 'upd-fill-lock-modal';
        await MModalsManager.show(
          modalId,
          {},
          {
            closeOther: true,
            component: FillUpdLockModal,
            on: {
              fill() {
                MModalsManager.hide(modalId);
              },
              close() {
                MModalsManager.hide(modalId);
              },
            },
          },
        );
      }
    }

    return {
        unfilledUpd,
        checkUnfilledUpd,
        deadlineFillUpdDate,
        monthFillingPeriod,
    };
}

import { EErpBasketTableField } from 'mp-enums/basket/erpBasketTableField.enum';
import { IColumnHeader } from 'shared/models/table.model';

export const OCI_BASKET_CATEGORY_TABLE_ERP_POSITIONS_COLUMNS: Array<IColumnHeader> = [
  { title: 'Потребность', field: EErpBasketTableField.NeedName, width: '24%', cssClass: 'mm-oci-basket-category-table-need__head', cellCssClass: 'mm-oci-basket-category-table-need__cell' },
  { title: '', field: EErpBasketTableField.Image, width: '5%', isInner: true, cssClass: 'mm-oci-basket-category-table-img__head', cellCssClass: 'mm-oci-basket-category-table-img__cell' },
  { title: 'Наименование', field: EErpBasketTableField.Name, width: '28%', isInner: true, cellCssClass: 'mm-oci-basket-category-table-name__cell' },
  { title: 'Поставщик', field: EErpBasketTableField.Supplier, isInner: true, cellCssClass: 'mm-oci-basket-category-table-supplier__cell', sortable: true },
  { title: 'Поставка до', field: EErpBasketTableField.DeliveryTo, isInner: true, cellCssClass: 'mm-oci-basket-category-table-delivery__cell' },
  { title: 'Кол-во', field: EErpBasketTableField.Count, align: 'center', isInner: true, cellCssClass: 'mm-oci-basket-category-table-count__cell' },
  { title: 'Цена за ед.', field: EErpBasketTableField.Price, isInner: true, cellCssClass: 'mm-oci-basket-category-table-price__cell' },
  { title: 'Сумма', field: EErpBasketTableField.Cost, align: 'right', isInner: true, cellCssClass: 'mm-oci-basket-category-table-cost__cell' },
  { title: '', field: EErpBasketTableField.Action, isInner: true, cellCssClass: 'mm-oci-basket-category-table-action__cell' },
];

export const OCI_BASKET_CATEGORY_TABLE_PRE_ORDER_ITEMS_COLUMNS: Array<IColumnHeader> = [
  { title: 'Наименование', field: EErpBasketTableField.Name, width: '46%', cellCssClass: 'mm-oci-basket-category-table-name__cell' },
  { title: 'Код материала', field: EErpBasketTableField.MtrCode, cellCssClass: 'mm-oci-basket-category-table-mtr-code__cell' },
  { title: 'Поставщик', field: EErpBasketTableField.Supplier, cellCssClass: 'mm-oci-basket-category-table-supplier__cell', sortable: true },
  { title: 'Поставка до', field: EErpBasketTableField.DeliveryTo, cellCssClass: 'mm-oci-basket-category-table-delivery__cell' },
  { title: 'Кол-во', field: EErpBasketTableField.Count, align: 'center', cellCssClass: 'mm-oci-basket-category-table-count__cell' },
  { title: 'Цена за ед. ', field: EErpBasketTableField.Price, cellCssClass: 'mm-oci-basket-category-table-price__cell' },
  { title: 'Сумма', field: EErpBasketTableField.Cost, align: 'right', cellCssClass: 'mm-oci-basket-category-table-cost__cell' },
  { title: '', field: EErpBasketTableField.Action, cellCssClass: 'mm-oci-basket-category-table-action__cell' },
];

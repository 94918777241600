<template>
  <div
    class="filter"
    :class="{
      active: isOpenFilter,
      'not-active': !isOpenFilter && !activeFilterText,
      filled: isTextNotEmpty,
      [containerClass]: containerClass,
      disabled: disabled,
    }"
  >
    <span
      class="filter__text"
      :class="{
        'mr-10': !activeFilterText,
        'mr-4': isTextNotEmpty,
      }"
    >
      {{ name }}
      {{ isTextNotEmpty ? ':' : '' }}
    </span>
    <span
      v-if="isTextNotEmpty"
      class="filter__value mr-10"
    >
      {{ FilterUtils.isBooleanFilter(filter) ? booleanFilterName : activeFilterText }}
    </span>

    <SvgIcon
      v-if="!isTextNotEmpty || (isTextNotEmpty && clearable)"
      :src="activeIcon"
      class="filter-icon clear"
      @click="onFilterIconClick($event)"
    />
  </div>
</template>

<script lang="ts" setup>
import { IFilter } from '../models/filters.model.js';
import { FilterUtils } from '../utils/filterHelper.util.js';
import SvgIcon from './SvgIcon.vue';
import { WatchSubscription } from '../utils/watchSubscription';
import useSSRUnsubscribeWatch from '../composables/useSSRUnsubscribeWatch';

const props = withDefaults(
  defineProps<{
    name?: string;
    filter: IFilter;
    originalFilter: IFilter;
    containerClass?: string;
    isOpenFilter?: boolean;
    disabled?: boolean;
    getFilterActiveText?(filter: IFilter, originalFilter: IFilter): string;
    clearable?: boolean;
  }>(),
  {
    clearable: true,
  },
);

const emit = defineEmits<{
  (e: 'reset'): void;
}>();

enum EIcons {
  ArrowUp = 'navigation/arrow-up',
  ArrowDown = 'navigation/arrow-down',
  Close = 'navigation/close-20px',
}

const activeFilterText = ref<string>('');

const watchSubscription = new WatchSubscription();
const activeIcon = computed<string>(
  () => (isTextNotEmpty.value && EIcons.Close) || (props.isOpenFilter.value && EIcons.ArrowUp) || EIcons.ArrowDown,
);
const isTextNotEmpty = computed(
  () => !!activeFilterText.value || (props.filter && FilterUtils.isBooleanFilter(props.filter) && props.filter.value),
);

function onFilterIconClick(event: MouseEvent) {
  if (activeIcon.value !== EIcons.Close) {
    return;
  }
  event.stopPropagation();
  resetFilter();
}

function resetFilter() {
  activeFilterText.value = '';
  emit('reset');
}

function setActiveText(newValue: IFilter): void {
  activeFilterText.value = props.getFilterActiveText
    ? props.getFilterActiveText(newValue, props.originalFilter)
    : FilterUtils.getFilterActiveText(newValue, props.originalFilter);
}

watchSubscription.add(
  watch(
    () => props.filter,
    (newFilter) => setActiveText(newFilter),
    { immediate: true, deep: true },
  ),
);

useSSRUnsubscribeWatch(watchSubscription);

const booleanFilterName = computed(() => {
  if (FilterUtils.isBooleanFilter(props.filter)) {
    return props.filter.options?.find((item) => item.id === props.filter?.value).name;
  }

  return '';
});
</script>

<style scoped lang="scss">
@import 'styles/base/common/variables';

.filter {
  border: 1px solid $filter-br;
  border-radius: 6px;
  padding: 8px 16px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: max-content;

  &.disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  &.active {
    background: $filter-bc;
  }

  &.filled {
    background: $filter-selected-bc;
    cursor: default;
    padding: 8px 16px;

    .filter-icon {
      cursor: pointer;
    }

    :deep(.filter-icon) {
      & > path {
        fill: $filter-text-selected;
      }
    }
  }

  &.filled > &__text {
    color: $filter-text-selected;
    opacity: 0.8;
  }

  &.filled > &__value {
    color: $filter-text-selected;
  }

  &__text {
    color: $filter-text-c;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-right: 10px;
  }

  &__value {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  &.not-active {
    &:hover > &__text {
      color: $filter-text-hover-c;
    }

    &:hover {
      :deep(.filter-icon) {
        & > path {
          fill: $filter-text-hover-c;
        }
      }
    }
  }

  .mr-10 {
    margin-right: 10px;
  }

  .mr-4 {
    margin-right: 4px;
  }
}
</style>

<template>
  <div class="mm-main-page">
    <div class="mm-marketplace-wrapper">
      <div
        id="basis_location_label"
        class="header__location mm-main-page__location"
      >
        <SvgIcon :src="EIconPath.NavigationLocationSvg" />
        <span>
          {{ basesService?.base || '-' }}
        </span>
      </div>

      <Header
        hide-main
        search-placeholder="Введите код материала, название или артикул товара"
        show-location-in-search
        oci-mode
        disable-load-cookie
        :client-id="clientId || undefined"
        @click-go-to-basket="OciBasket.goToBasketPage()"
      />
      <div class="mm-main-page__content">
        <slot />
      </div>
    </div>
  </div>
  <ModalsContainer />
</template>

<script lang="ts" setup>
import Header from 'mp-components/layouts/default/Header.vue';
import { useMainTitle } from 'mp-composables/useMainTitle';
import { Categories } from 'mp-services/categories.service';
import { OciBasket } from 'oci-services/basket/ociBasket.service';
import SearchService from 'shared/services/search.service';
import { EIconPath } from 'shared/enums/iconPath.enum';
import { useOciManager } from 'oci-composables/useOciManager';
import BasesService from 'mp-services/basesManager.service';
import { OciBasketCounterService } from 'oci-services/basket/ociBasketCounter.service';

const event = useRequestEvent();

const ociManager = await useOciManager(event, true);
const { clientId } = ociManager;

const { t } = useI18n();

const ociBasketCounterService = new OciBasketCounterService();
const ociBasket = new OciBasket(ociManager, ociBasketCounterService, t);

provide(OciBasket.getServiceName(), ociBasket);
provide(OciBasketCounterService.getServiceName(), ociBasketCounterService);
provide(Categories.getServiceName(), new Categories());

const searchService = inject<SearchService>(SearchService.getServiceName());
searchService?.setOciMode(true);

const basesService = inject<BasesService>(BasesService.getServiceName());

useMainTitle();

onMounted(async () => await ociBasket.checkStatus());

onBeforeUnmount(() => {
  Categories.killInstance();
  searchService?.setOciMode(false);
  ociBasket.clearTimerCheckStatus();
});
</script>

<style lang="scss" scoped>
.mm-main-page {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  :deep(.mm-header) + div {
    flex: 0 0 640px;
  }

  &::after {
    content: '';
    width: 100%;
    height: 10px;
    flex: 0 0 1px;
  }

  &.overflow {
    overflow: hidden;
  }

  :deep(.mm-header) {
    position: relative;
    z-index: 30;
  }

  &__content {
    min-height: 700px;
  }

  &__location {
    display: none;
    top: 0;
    z-index: 31;
  }
}

@media screen and (max-width: 1279px) {
  .mm-marketplace-wrapper {
    padding-bottom: 56px;
  }

  .mm-main-page {
    &__location {
      display: flex;
    }
  }
}
</style>

<template>
  <tr class="mm-offers-expanded">
    <td
      v-if="isMobile && declinedItemsCount"
      :colspan="3"
    >
      <BasketWarningRow
        hide-select-button
        :warning-type="EWarningMessageType.Simple"
        :text="`Поставщик не подтвердил заказ данного товара в количестве <span>${declinedItemsCount} ${unitName}</span>`"
      />
    </td>

    <td
      :colspan="3"
      class="mm-offers-expanded__container"
    >
      <div class="mm-offers-expanded__left">
        <ProductCharacteristic
          class="mm-offers-expanded__characteristic"
          :characteristics="shipping"
          size="large"
          :skeleton-value-loading="isFullLoading && !offer?.catalogId"
        />
        <a
          class="mm-offers-expanded__link mm-body-medium-s visible-large-desktop visible-small-desktop visible-tablet"
          :class="{
            'mm-offers-expanded__link--disabled': isFullLoading
          }"
          @click="onOfferDetailsClick"
        >
          Подробнее о предложении
        </a>
      </div>
    </td>

    <td :colspan="2">
      <div class="mm-offers-expanded__right">
        <div class="mm-offers-expanded__action">
          <p class="mm-headline-1 price__main-info">
            <MSkeletonLoader
              :is-loading="isFullLoading && !offer?.unitPrice"
              :type="MM.ESkeletonLoaderTypes.Lines"
              :width="123"
            >
              {{ formatPrice(props.offer?.unitPrice) }}
              <span class="mm-caption-regular">за {{ unitName }}</span>
            </MSkeletonLoader>
          </p>

          <div
            class="mm-offers-expanded__action-breadcrumbs"
            :class="{
              'mm-offers-expanded__action-breadcrumbs--tipped': hasDeliveryDiscount,
            }"
          >
            <p class="mm-caption-regular">
              <MSkeletonLoader
                :is-loading="isFullLoading"
                :type="MM.ESkeletonLoaderTypes.Lines"
                :width="175"
              >
                {{ vatInfo?.label }}
                <span class="fw-bold price__info">
                  {{ formatPrice(vatInfo?.value) }}
                </span>
              </MSkeletonLoader>
            </p>

            <p class="mm-caption-regular">
              <MSkeletonLoader
                :is-loading="isFullLoading"
                :type="MM.ESkeletonLoaderTypes.Lines"
                :width="175"
              >
                {{ deliveryInfo?.label }}
                <span
                  class="fw-bold price__info"
                  :class="{
                    'price__info--accent': hasDeliveryDiscount,
                  }"
                >
                  {{ deliveryInfo.value === null ? '—' : formatPrice(deliveryInfo?.value, undefined, undefined, undefined, undefined, undefined, false) }}
                </span>
                <MTooltip
                  v-if="hasDeliveryDiscount && deliveryInfo.value !== null"
                  class="no-override-tooltip"
                  :text="deliveryDiscountTooltipText"
                  is-show-arrow
                  :offset="[0, 10]"
                  max-width="300"
                >
                  <MIcon :src="MM.EIconPath.ActionInfoSvg" />
                </MTooltip>
              </MSkeletonLoader>
            </p>
          </div>

          <div class="mm-product-action-buttons">
            <AddToCartButton
              :quantity="preparedQuantity"
              :offer-id="props.offerId"
              wrapper-class="mm-product-action-buttons__btn"
              :loading="loadingAddToCart"
              :disabled-add-button="disableAddButton"
              :is-erp="isErp"
              :is-conflict-bases-category="isConflictBasesCategory"
              :has-mapping="props.hasMapping"
              :unit-name="unitName"
              :is-disabled-by-client-category-code="props.isDisabledByClientCategoryCode"
              :disabled-by-offer-rules="OfferMapper.isOfferDisabledByRules(props.offer, preparedQuantity)"
              :precision="productDetails?.unit?.precision"
              :disabled-tooltip="isFullLoading"
              @on-add-to-cart="onAddToCart"
            />

            <a
              class="mm-offers-expanded__link mm-body-medium-s visible-mobile"
              @click="onOfferDetailsClick"
            > Подробнее о предложении </a>
          </div>
        </div>
      </div>
    </td>

    <OfferDrawer
      v-if="isMobile"
      :visible="isOfferModalOpened"
      :offer="offer"
      :offer-details="offerDetails"
      :vat-info="vatInfo"
      :delivery-info="deliveryInfo"
      :is-configured-terms="isConfiguredTerms"
      :item-title="offerDetails?.name"
      :quantity="props.quantity"
      :loading="loadingAddToCart"
      :disabled-add-to-cart="disableAddButton"
      :product-details="productDetails"
      :declined-items-count="declinedItemsCount"
      :is-conflict-bases-category="isConflictBasesCategory"
      :unit-name="unitName"
      :has-mapping="props.hasMapping"
      :is-disabled-by-client-category-code="props.isDisabledByClientCategoryCode"
      :is-erp="isErp"
      :oci-mode="ociMode"
      :show-offer-quantity="showOfferQuantity"
      @on-add-count="onAddCount"
      @on-add-to-cart="onAddToCart"
      @close="isOfferModalOpened = false"
    />
  </tr>
</template>

<script lang="ts" setup>
import OfferPopup from './OfferPopup.vue';
import OfferDrawer from './OfferDrawer.vue';
import AddToCartButton from './AddToCartButton.vue';
import ProductCharacteristic from 'shared/components/ProductCharacteristic.vue';
import IProductCharacteristic from 'shared/models/productCharacteristic.model';
import { IProductResultedOffer, IProductOfferDetails, ICartOfferInfo, IProductDetails } from 'shared/models/product.model';
import { OfferMapper } from 'shared/utils/offerMapper.util';
import WindowWidthManager from 'shared/services/windowWidth.service';
import { EOfferCalcTypes } from 'shared/enums/offerCalcTypes.enum';
import BasesService from 'services/basesManager.service';
import { AuthHelper } from 'utils/authHelper.util';
import { useUserStore } from 'store/user.store';
import { ICategoryPageResponse } from 'server/models/categoryPage';
import { EWarningMessageType } from 'shared/enums/warningMessageType.enum';
import BasketWarningRow from 'components/basket/BasketWarningRow.vue';
import { UnitOkeiHelper } from 'utils/unitOkeiHelper.util';
import ModalManager from 'shared/services/modalManager.service';
import { IModalManager } from 'shared/models/modalManager.model';
import { MM } from 'types';
import { MSkeletonLoader } from '@maksmart/maksify';

const deliveryDiscountTooltipText = `В корзине уже есть товары от данного поставщика. Выбирая это предложение, вы не
переплачиваете за дополнительную доставку другим поставщиком.`;

const props = defineProps<{
  productId: string;
  image: string;
  offerId: number;
  quantity: number;
  offer: IProductResultedOffer;
  itemChars?: Array<IProductCharacteristic>;
  currentCalcType?: Array<EOfferCalcTypes>;
  productData: IProductResultedOffer;
  loadingAddToCart?: boolean;
  focusedQuantityInput?: boolean;
  isConfiguredTerms?: boolean;
  hasMapping?: boolean;
  mtrCode?: string;
  isErp?: boolean;
  productDetails: IProductDetails;
  categoryData: ICategoryPageResponse;
  isFullLoading?: boolean;
  unitName: string;
  isDisabledByClientCategoryCode?: boolean;
  declinedItemsCount?: number;
  showOfferQuantity?: boolean;
  ociMode?: boolean;
  hasDeliveryDiscount?: boolean;
}>();

const emits = defineEmits<{
  (e: 'onAddToCart', value: ICartOfferInfo): void;
  (e: 'onAddCount', value: number): void;
}>();

const basesManager = inject<BasesService>(BasesService.getServiceName());
const modalManager = process.client ? inject<IModalManager>(ModalManager.getServiceName()) : undefined;

const { isMobile } = WindowWidthManager.getAdaptivaRefs();
const isOfferModalOpened = ref<boolean>(false);
const loadingOfferDetails = ref<boolean>(false);
const userStore = useUserStore();
const offerDetails = ref<IProductOfferDetails>();
const currentOverallPrice = computed(() => {
  return OfferMapper.getCurrentOverallPrice(props.offer, props.currentCalcType);
});
const isConflictBasesCategory = computed<boolean>(() => basesManager?.isConflictBasesCategory(props.categoryData?.category));

const internalQuantity = computed(() => props.quantity);

const disableAddButton = computed(
  () =>
    !!(
      props.loadingAddToCart ||
      (props.offer?.available && props.offer?.stock < props.quantity) ||
      (userStore.isUserLoggedIn && props.hasMapping === false && !props.isErp && !props.ociMode && !props.mtrCode) ||
      (userStore.isUserLoggedIn && isConflictBasesCategory.value) ||
      props.isDisabledByClientCategoryCode ||
      props.focusedQuantityInput ||
      props.isFullLoading
    ),
);

const vatInfo = computed(() => ({
  label: props.currentCalcType?.includes(EOfferCalcTypes.Vat) ? 'НДС' : 'НДС',
  value: currentVatPrice(),
}));
const deliveryInfo = computed(() => ({
  label: props.currentCalcType?.includes(EOfferCalcTypes.Delivery) ? 'Доставка' : 'Доставка',
  value: props.offer?.deliveryCost,
}));

const shipping = computed<Array<IProductCharacteristic>>(() => {
  return OfferMapper.mapShipping(
    props.offer,
    props.quantity,
    props.unitName,
    UnitOkeiHelper.getModelUnitPrecision(props.productDetails?.unit),
  );
});

const preparedQuantity = computed(() =>
  props.showOfferQuantity
    ? props.offer?.quantity
    : OfferMapper.mapOfferQuantity(
        props.offer?.stepQuantity,
        props.offer?.minQuantity,
        +props.quantity || 1,
        UnitOkeiHelper.getModelUnitPrecision(props.productDetails?.unit),
      ),
);

function currentVatPrice(): number {
  const priceType = props.currentCalcType;

  if (!priceType) {
    return props.offer.itemsCostVat;
  }

  if ([EOfferCalcTypes.Delivery, EOfferCalcTypes.Price, EOfferCalcTypes.Vat].every((item) => priceType.includes(item))) {
    return props.offer.costVat;
  }

  if ([EOfferCalcTypes.Delivery, EOfferCalcTypes.Price].every((item) => priceType.includes(item))) {
    return props.offer.costVat;
  }

  if ([EOfferCalcTypes.Vat, EOfferCalcTypes.Price].every((item) => priceType.includes(item))) {
    return props.offer.originUnitVatPrice;
  }

  return props.offer.originUnitVatPrice;
}

function onAddCount(count: number) {
  emits('onAddCount', count);
}

function onAddToCart() {
  emits('onAddToCart', { offerId: +props.offerId, quantity: props.quantity, offer: props.offer });
}

async function onOfferDetailsClick() {
  if (loadingOfferDetails.value || props.isFullLoading) {
    return;
  }

  offerDetails.value = await getOfferDetails();

  if (isMobile.value) {
    isOfferModalOpened.value = true;
    return;
  }

  const modalName = 'openCatalogNameConstructorModal';
  await modalManager?.show({
    component: OfferPopup,
    bind: {
      name: modalName,
      offer: props.offer,
      offerDetails: offerDetails.value,
      vatInfo: vatInfo,
      deliveryInfo: deliveryInfo,
      currentOverallPrice: currentOverallPrice,
      hasPimMapping: props.hasMapping,
      itemTitle: offerDetails.value?.name,
      declinedItemsCount: props.declinedItemsCount,
      isConfiguredTerms: props.isConfiguredTerms,
      quantity: internalQuantity,
      disabledAddToCart: disableAddButton,
      currentCalcType: props.currentCalcType,
      isMobile,
      productDetails: props.productDetails,
      isConflictBasesCategory: isConflictBasesCategory.value,
      isDisabledByClientCategoryCode: props.isDisabledByClientCategoryCode,
      unitName: props.unitName,
      ociMode: props.ociMode,
      hasDeliveryDiscount: props.hasDeliveryDiscount,
    },
    on: {
      close() {
        modalManager.hide(modalName);
        isOfferModalOpened.value = true;
      },
      onAddCount(event) {
        onAddCount(event);
      },
      onAddToCart() {
        onAddToCart();
        modalManager.hide(modalName);
      },
    },
  });
}

async function getOfferDetails(): Promise<IProductOfferDetails | undefined> {
  loadingOfferDetails.value = true;
  try {
    return await AuthHelper.fetch(`/api/v1/products/${props.productId}/offers/${props.offerId}`, {
      params: { fiasId: basesManager?.getCurrentRegionFiasId() },
    });
  } catch (error) {
    console.error(error);
  } finally {
    loadingOfferDetails.value = false;
  }
}
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

:deep(.mm-tooltip.mm-tooltip--base) {
  width: 100%;

  .popper {
    max-width: 243px;
    font-size: 14px;
  }
}

.mm-offers-expanded {
  padding-top: 20px;
  position: relative;
  background-color: $app-table-elements-color;

  .mm-basket-warning-row.mm-basket-warning-row--simple {
    :deep(.mm-basket-warning-row__text) > span > span {
      font-weight: 500;
    }
  }

  &__container {
    position: relative;
  }

  &__characteristic {
    :deep(.mm-product-characteristic-label) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  & > td {
    padding: 16px 24px 24px;

    &:first-of-type {
      border-bottom-left-radius: 8px;
    }

    &:last-of-type {
      border-bottom-right-radius: 8px;
    }
  }

  .offers-table-popup {
    display: inline-block;
    margin-left: 6px;
    position: sticky;
    z-index: 4;

    .mm-headline-5 {
      color: $white;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      margin-bottom: 8px;
    }

    :deep(.product-characteristic) {
      .mm-product-characteristic-label {
        color: $light-green;
        background-color: $text-black;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }

      .mm-product-characteristic-value {
        color: $white;
        background-color: $text-black;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
    }

    :deep(.mm-tooltip.mm-tooltip--base) {
      .popper {
        width: 237px;
        font-size: 14px;
        border-radius: 6px;
        padding: 12px;
      }
    }
  }

  &__link {
    display: block;
    margin-bottom: 34px;
    position: absolute;
    bottom: 0;
    text-decoration: none;
    color: $link;
    cursor: pointer;
    z-index: 4;

    &--disabled {
      color: $color-green-muted;
    }
  }

  &__action-breadcrumbs {
    margin-top: 13px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;

    p {
      margin: 0;

      &:first-child {
        margin-bottom: 4px;
      }
    }

    &--tipped > p {
      display: flex;
      align-items: center;
      gap: 5px;

      & > .price__info--accent {
        color: $accent-orange-color;
      }
    }
  }

  :deep(.mm-add-cart__btn) {
    .btn-primary {
      width: 100%;
      justify-content: center;

      .btn__quantity-info {
        margin-left: 4px;
        opacity: 0.6;
      }
    }

    .mm-offers-expanded__added-cart {
      display: flex;
      align-items: center;

      &-text {
        font-size: 14px;
        line-height: 20px;
        margin-left: 12px;
      }
    }
  }

  .price__main-info {
    font-weight: 500;
    line-height: 36px;
    margin: 0;
  }

  .price__info {
    font-weight: 500 !important;
    color: $text-black;
  }
}

@media only screen and (max-width: 767px) {
  .mm-offers-expanded {
    flex-direction: column;
    display: flex;
    padding-top: 0;

    td {
      padding: 0 12px !important;

      &:last-child {
        border-radius: 8px;
      }
    }

    & > td {
      &:first-of-type {
        border-bottom-left-radius: 0;
      }

      &:last-of-type {
        border-bottom-right-radius: 0;
      }

      &:has(.mm-offers-expanded__right) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    &__left {
      padding-top: 12px;
      padding-bottom: 12px;
      margin-bottom: 20px;
      border-bottom: 1px solid #e6e6e6;

      :deep(.mm-product-characteristic-wrapper.size--large) {
        margin-bottom: 4px;

        .mm-product-characteristic-label {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    &__right {
      padding-left: 0 !important;
    }

    .mm-offers-expanded__link {
      margin-bottom: 24px;
      position: static;
      align-self: center;
      margin-top: 16px;
    }

    &__right {
      margin-left: unset;

      .btn {
        width: 100%;
        text-align: center;
        justify-content: center;
      }
    }

    .mm-product-action-buttons {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>

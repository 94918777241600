import { H3Event, setCookie } from 'h3';
import { EOciManagerCookieKey } from '../enums/ociManagerCookieKey.enum';
import BasesService from 'mp-services/basesManager.service';
import { ISapParams } from 'oci-models/sapParams.model';
import { OciAuthService } from 'oci-services/ociAuth.service';
import { ECookieKeys } from 'shared/enums/cookieKeys';
import { useUserStore } from 'mp-store/user.store';
import { IDeliveryBase } from 'shared/models/bases.model';
import { IUseOciManager } from 'oci-models/composables/useOciManager.model';
import { EOciStateKey } from 'oci-enums/ociStateKey.enum';
import { IOciAuthResponse } from 'oci-models/services/api/ociAuthResponse.model';
import Notificator from 'shared/services/notificator.service';
import { ESapServiceParamKey } from 'oci-enums/services/sapServiceParamKey.enum';

export async function useOciManager(event?: H3Event, needAuth = false): Promise<IUseOciManager> {
  const ociAuthService = new OciAuthService();
  const basesService = inject<BasesService>(BasesService.getServiceName());
  const userStore = useUserStore();

  const sessionId = useCookie<string | undefined>(EOciManagerCookieKey.SessionId);
  const sessionTransactionId = useCookie<string | undefined>(EOciManagerCookieKey.SessionTransactionId);
  const deliveryBase = useCookie<string | undefined>(ECookieKeys.DeliveryBases);
  const clientId = useCookie<number | undefined>(EOciManagerCookieKey.ClientId);
  const hookUrl = useCookie<string | undefined>(EOciManagerCookieKey.HookUrl);
  const errorMessage = useState<string | undefined>(EOciStateKey.OciAuthErrorMessage);

  async function startAuth(event: H3Event): Promise<void> {
    if (!process.server || !event) {
      return;
    }

    let sapParams: ISapParams | undefined;

    try {
      sapParams = await ociAuthService.parseSapParams(event);
    } catch (error) {
      return handleError(error, 'Ошибка парсинга параметров');
    }

    if (ociAuthService.isSapParamsEmpty(sapParams)) {
      return;
    }

    try {
      const bases = await ociAuthService.initBase(sapParams as ISapParams);

      if (!bases) {
        throw 'Базис не найден';
      }

      clientId.value = bases.clientId;
    } catch (error) {
      return handleError(error);
    }

    hookUrl.value = sapParams?.[ESapServiceParamKey.HOOK_URL]?.[0];

    let authResponse: IOciAuthResponse | undefined;

    try {
      authResponse = await ociAuthService.getSession();
      if (!authResponse?.sessionId) {
        throw 'Не удалось получить сессию';
      }
    } catch (error) {
      return handleError(error);
    }

    sessionId.value = authResponse.sessionId;
    sessionTransactionId.value = authResponse.sessionTransactionId;

    try {
      await ociAuthService.updateBasketByReceivedNeeds(
        event,
        sapParams as ISapParams,
        clientId.value,
      );
    } catch (error) {
      return handleError(error);
    }

    setBase();
    /*
     * Сохраняем cookie перед редиректом,
     * т.к. useCookie не устанавливает значения в cookie event.
     * */
    setCookieToEvent(event);
    await ociAuthService.clearUrl(event);
  }

  function setCookieToEvent(event: H3Event): void {
    setCookie(event, EOciManagerCookieKey.SessionId, sessionId.value || '');
    setCookie(event, EOciManagerCookieKey.SessionTransactionId, sessionTransactionId.value || '');
    setCookie(event, EOciManagerCookieKey.HookUrl, hookUrl.value || '');
    setCookie(event, EOciManagerCookieKey.ClientId, clientId.value?.toString() || '');
    setCookie(event, ECookieKeys.DeliveryBases, deliveryBase.value || '');
  }

  function setBase(): void {
    const base = ociAuthService.getBase();

    if (base && basesService) {
      basesService.currentUserBase = base;
      deliveryBase.value = doStringBase(base);
    }
  }

  function handleError(error: unknown, defaultMessage = 'Ошибка OCI авторизации'): void {
    if (typeof error === 'string') {
      errorMessage.value = error;
    } else if (error instanceof Error) {
      errorMessage.value = error.message;
    } else {
      errorMessage.value = defaultMessage;
    }
  }

  function initOciAuth(): void {
    if (!sessionId.value) {
      return;
    }

    userStore.setOciAuth(sessionId.value || null, sessionTransactionId.value || null);
  }

  function initBase(): void {
    if (!deliveryBase.value || !basesService) {
      return;
    }

    basesService.currentUserBase = deliveryBase.value as Partial<IDeliveryBase>;
  }

  function showErrorMessage(): void {
    if (!errorMessage.value) {
      return;
    }

    Notificator.showDetachedNotification(errorMessage.value);
    errorMessage.value = '';
  }

  function clearState(): void {
    [
      sessionId,
      sessionTransactionId,
      hookUrl,
      deliveryBase,
      clientId,
      errorMessage,
    ].forEach((item) => item.value = undefined);
  }

  function doStringBase(base: IDeliveryBase): string {
    return JSON.stringify({
      id: base?.id,
      fiasId: base?.regionId,
      name: base?.name,
    });
  }

  onBeforeMount(() => {
    if (needAuth) {
      initOciAuth();
      initBase();
    }
  });

  onMounted(() => {
    showErrorMessage();
  });

  if (process.server && event && needAuth) {
    await startAuth(event);
  }

  return {
    sessionId,
    sessionTransactionId,
    deliveryBase,
    clientId,
    hookUrl,
    clearState,
  };
}

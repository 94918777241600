<template>
  <div class="MainAccordion">
    <div
      v-for="(accordionItem, index) in accordionList"
      :key="index"
      class="MainAccordionCard"
      :class="{ 'MainAccordionCard--active': index === activeAccordionItem }"
      :style="{
        '--cover-img': `url(${accordionItem.coverImg})`,
      }"
      @mouseenter="onHover(index)"
    >
      <div
        class="MainAccordionCard__title"
        v-html="accordionItem.title"
      />
      <div
        class="MainAccordionCard__text"
        v-html="accordionItem.text"
      />
      <div
        class="MainAccordionCard__mobile-toggler"
        @click="onClickToggler(index)"
      >
        <MIcon
          :src="MM.EIcon.ArrowTriangle"
          :fill="MM.EColors.White"
          width="8px"
          height="12px"
          rotate="90deg"
        />
      </div>
      <div class="MainAccordionCard__button">
        <MButton
          :icon-right="MM.EIconPath.NavigationMainArrowSvg"
          :size="MM.EButtonSizes.Large"
          @click="accordionItem.button.action()"
        >
          {{ accordionItem.button.label }}
        </MButton>
      </div>
      <div class="MainAccordionCard__bg-circle" />
      <div class="MainAccordionCard__img">
        <img
          :src="accordionItem.img"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { MM } from 'types';
import { EClientApprovalType } from 'enums/client/clientApprovalType.enum';
import WindowWidthManager from 'shared/services/windowWidth.service';

const { onCLickClientApprovalModal } = useClientApproval();
const { onSupplierRegistration } = useSupplierRegistration();

const { isDesktop, isLargeDesktop } = WindowWidthManager.getAdaptivaRefs();

/** Индекс активного слайда */
const activeAccordionItem = ref(0);

/** Список слайдов */
const accordionList = ref([
  {
    title: 'Снижаем <br class="visible-large-desktop visible-small-desktop">издержки <br>на закупку',
    text: 'Максимальная цифровизация процесса значительно снижает операционные <br>и транзакционные издержки',
    img: 'images/MainFirstScreen/1.png',
    coverImg: '/images/MainFirstScreen/slide-cover-1.svg',
    button: {
      label: 'Стать покупателем',
      action: () => onCLickClientApprovalModal(EClientApprovalType.Client),
    },
  },
  {
    title: 'Обеспечиваем <br class="visible-tablet visible-mobile"> высокую конкурсность',
    text: 'Улучшение цен за счет расширение базы квалифицированных поставщиков и увеличения конкурсности',
    img: 'images/MainFirstScreen/2.png',
    coverImg: '/images/MainFirstScreen/slide-cover-2.svg',
    button: {
      label: 'Стать покупателем',
      action: () => onCLickClientApprovalModal(EClientApprovalType.Client),
    },
  },
  {
    title: 'Снижаем затраты <br> на логистику',
    text: 'Обеспечиваем быструю и прогнозируемую доставку за счет консолидации грузов через собственные распределительные центры, а также обеспечиваем возможность доставки напрямую с локального склада Поставщика',
    img: 'images/MainFirstScreen/3.png',
    coverImg: '/images/MainFirstScreen/slide-cover-3.svg',
    button: {
      label: 'Стать покупателем',
      action: () => onCLickClientApprovalModal(EClientApprovalType.Client),
    },
  },
  {
    title:
      'Повышаем <br class="visible-tablet visible-mobile"> рентабельность<br class="visible-large-desktop visible-small-desktop"> продаж',
    text: 'Для Поставщиков выступаем единым контрагентом и максимально снижаем трудоемкость за счет автоматизации',
    img: 'images/MainFirstScreen/4.png',
    coverImg: '/images/MainFirstScreen/slide-cover-4.svg',
    button: {
      label: 'Стать поставщиком',
      action: onSupplierRegistration,
    },
  },
]);

function onHover(index) {
  if (isDesktop.value || isLargeDesktop.value) {
    activeAccordionItem.value = index;
  }
}

function onClickToggler(index) {
  if (activeAccordionItem.value !== index) {
    activeAccordionItem.value = index;
  } else {
    activeAccordionItem.value = -1;
  }
}
</script>

<style lang="scss" scoped>
$accordionSpeed: 0.24s;
$s: '.MainAccordionCard';

.MainAccordion {
  display: flex;
  align-items: stretch;
  grid-gap: 8px;
  margin-left: -32px;
  margin-right: -32px;
  user-select: none;

  @include maxw(1799) {
    margin: 0;
  }

  @include maxw(1279) {
    flex-direction: column;
  }
}

.MainAccordionCard {
  $s: &;

  width: 260px;
  height: 450px;
  border-radius: 12px;
  padding: 32px;
  transition: 0.4s ease;
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: $accordionSpeed;
  background-image: linear-gradient(140deg, #031912 5%, #053b3f 95%);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1;
    background-color: #f2f5f4;
    background-repeat: no-repeat;
    background-image: var(--cover-img);
    transition: $accordionSpeed $accordionSpeed * 3 / 5;
    z-index: -3;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: -2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position: 50% 50%;
    background-repeat: repeat-y;
    background-image: url('/images/accordion-gradients.svg');
    opacity: 0;
    transition: $accordionSpeed;
  }

  &__title {
    @include text-m24;

    max-width: 200px;
  }

  &__text {
    flex-grow: 1;
    opacity: 0;
    width: 270px;
    transition: 0.4s;
    color: #ffffff99;
  }

  &__button {
    opacity: 0;
    transition: 0.4s;
    white-space: nowrap;
  }

  &__bg-circle {
    z-index: -3;
    position: absolute;
    width: 450px;
    height: 450px;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    background-image: $color-green-gradient;
    filter: blur(200px);
    opacity: 0;
    transition: 0.2s;
  }

  &__img {
    position: absolute;
    right: 0;
    bottom: 0;
    line-height: 0;
    z-index: -1;
    width: 475px;

    :deep(img) {
      max-width: 100%;
      transition: 0.2s;
      opacity: 0;
    }
  }

  &__mobile-toggler {
    display: none;
  }

  &--active {
    flex-grow: 100;
    color: $color-white;
    border-bottom-right-radius: 120px;
    padding-right: 400px;

    &:before {
      opacity: 0;
      transition: $accordionSpeed;
    }

    &:after {
      opacity: 1;
    }

    #{$s}__bg-circle {
      opacity: 1;
    }
    #{$s}__img {
      :deep(img) {
        opacity: 1;
        transition: 0.4s 0.1s;
      }
    }
    #{$s}__text,
    #{$s}__button {
      transition-delay: 0.2s;
      opacity: 1;
    }
  }

  @include maxw(1599) {
    width: 220px;
    padding: 32px 16px;

    margin-left: 0;
    margin-right: 0;

    &__bg-circle {
      left: 280px;
      right: auto;
    }

    &__img {
      right: -35px;
      width: 400px;
    }

    &--active {
      padding-right: 280px;
    }
  }

  @include maxw(1279) {
    width: 100%;
    height: 128px;
    color: $color-white;
    grid-gap: 56px;

    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }

    &__title {
      max-width: 300px;

      @include text-m36;
    }

    &__text {
      width: 340px;
    }

    &__bg-circle {
      opacity: 1;
      left: 320px;
    }

    &__img {
      right: 0;
      width: 475px;
    }

    &__mobile-toggler {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 24px;
      top: 40px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: rgba(#fff, 0.2);
    }

    &--active {
      height: 450px;
      padding-right: 380px;

      #{$s}__mobile-toggler {
        transform: rotate(180deg);
      }
    }
  }

  @include maxw(767) {
    padding: 24px 16px;
    height: 96px;
    grid-gap: 12px;

    &__title {
      width: 100%;
      padding-right: 10px;
      @include text-r24;
    }

    &__text {
      width: 100%;
      margin-bottom: 12px;

      @include text-r20;
    }

    &__img {
      height: 290px;
      text-align: right;
      &:deep(img) {
        height: 100%;
      }
    }

    &__mobile-toggler {
      top: 24px;
    }

    &--active {
      height: auto;
      padding-bottom: 306px;
    }
  }
}
</style>

<template>
  <NuxtLink
    to="/"
    :no-prefetch="props.noPrefetch"
    class="HeaderLogo"
  >
    <MIcon
      :src="logoSrc"
      class="HeaderLogo__icon"
    />
    <div
      v-if="props.withDescription"
      class="HeaderLogo__description"
      :class="{ 'HeaderLogo__description--inverse': useHeaderStore().isInverseMode }"
    >
      маркетплейс быстрых закупок
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import { useHeaderStore } from '~/store/Header.store';
import { MM } from '~/types';

const props = defineProps<{
  withDescription?: boolean;
  fixedSrc?: MM.EIconPath;
  noPrefetch?: boolean;
}>();

const logoSrc = computed(() => {
  if (props.fixedSrc) {
    return props.fixedSrc;
  }
  if (useHeaderStore().isInverseMode) {
    // лого БЕЗ описания, текст белый
    return MM.EIconPath.ImagesLogoInvertedDescOff;
  } else {
    // лого БЕЗ описания, текст черный
    return MM.EIconPath.ImagesLogoDescOff;
  }
});
</script>

<style lang="scss">
.HeaderLogo {
  text-decoration: none !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &__icon {
    @include minw(1280) {
      height: 30px;
      width: 168px;
    }
  }

  &__description {
    margin-top: 4px;
    font-size: 11px;
    color: $color-text-main;

    &--inverse {
      color: $color-white;
      opacity: 0.4;
    }

    @include maxw(1279) {
      display: none;
    }
  }
}
</style>

// библиотеки
import { defineStore } from 'pinia';

// TODO: сделать напрямую проверку открытия попапа и отключать прозрачность и перекраску шапки (возможно надо, возможно нет)
// import type { IPopupManager } from 'shared/models/popupManager.model';
// import PopupManager from 'shared/services/popupManager.service';
// const popupManager = process.client ? inject<IPopupManager>(PopupManager.getServiceName()) : undefined;

export const useHeaderStore = defineStore('HeaderStore', () => {
  // Состояния шапки и элементов, которые на неё влияют
  const state = reactive<{
    hasSliderTopOnPage: boolean;
    isCatalogOpened: boolean;
    isUserBarActive: boolean;
    isInverseModeDefault: boolean;
    userBarSearchTypeOpened: boolean;
    offsetTop: number | null;
    height: number | null;
  }>({
    hasSliderTopOnPage: false,
    isCatalogOpened: false,
    isUserBarActive: false,
    isInverseModeDefault: false,
    userBarSearchTypeOpened: false,
    offsetTop: null,
    height: null,
  });

  const isInverseMode = computed(() => {
    // Если открыт попап каталог
    if (state.isCatalogOpened) {
      return false;
    }

    // Если по умолчанию инверсия включена
    if (state.isInverseModeDefault) {
      return true;
    }

    return false;
  });

  return { state, isInverseMode };
});

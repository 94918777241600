<template>
  <div class="search__col search__wrap search__wrap_zindex">
    <SearchButton
      v-if="!isMobile"
      v-model="internalSelectedCategory"
      :options="options"
      class="search__search-button"
    />

    <input
      id="search_input"
      v-model="internalSearchText"
      type="text"
      class="search__field"
      :placeholder="placeholder"
      @focus="isFocus = true"
      @blur="isFocus = false"
      @keyup.enter="onSearch"
    >
    <div class="search-buttons__container">
      <button
        v-if="internalSearchText"
        class="close__btn"
        @click="onClear"
      >
        <SvgIcon src="navigation/close-24px" />
      </button>
      <button
        class="search__btn"
        @click="onSearch"
      >
        <SvgIcon src="navigation/search" />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SvgIcon from 'shared/components/SvgIcon.vue';
import { ICategory } from 'shared/models/category.model';
import SearchButton from './SearchButton.vue';
import SearchService from 'shared/services/search.service';
import { SelectValue, ISelectOption } from 'shared/models/select.model';
import { EVERYWHERE_SEARCH_OPTION } from 'constants/search.const';
import { WatchSubscription } from 'shared/utils/watchSubscription';
import useSSRUnsubscribeWatch from 'shared/composables/useSSRUnsubscribeWatch';
import WindowWidthManager from 'shared/services/windowWidth.service';

const { isMobile } = WindowWidthManager.getAdaptivaRefs();
const route = useRoute();

const props = withDefaults(
  defineProps<{
    categories: Array<ICategory>;
    placeholder?: string;
  }>(),
  {
    placeholder: 'Введите код материала, название или артикул товара',
  },
);

const searchService = inject<SearchService>(SearchService.getServiceName());

const watchSubscription = new WatchSubscription();
const internalSearchText = ref((route?.query?.search || searchService?.searchText?.value) ?? '');
const isFocus = ref(false);
const internalSelectedCategory = ref<SelectValue>(EVERYWHERE_SEARCH_OPTION);
const options = computed<Array<ISelectOption>>(() => {
  const internalOptions = props.categories?.map((category) => ({ label: category.title, value: category.id?.toString() }));

  const categoryId = route.params?.categoryId || route.query?.categoryId;

  if (
    (categoryId && !internalOptions?.find((option) => option.value === categoryId)) ||
    (!categoryId &&
      searchService?.categoryId.value &&
      !internalOptions.find((option) => option.value === searchService.categoryId.value) &&
      searchService.categoryId.value !== EVERYWHERE_SEARCH_OPTION.value)
  ) {
    internalOptions.unshift({ label: 'В текущей категории', value: categoryId || searchService?.categoryId.value });
  }

  return [EVERYWHERE_SEARCH_OPTION, ...(internalOptions || [])];
});

function onSearch(): void {
  searchService?.search(internalSearchText.value, internalSelectedCategory.value?.value.toString() || '');
}

function init(): void {
  internalSearchText.value = searchService?.searchText.value || route.query?.search;
  if (searchService?.categoryId.value) {
    internalSelectedCategory.value = options.value?.find((option) => option.value === searchService.categoryId.value) || null;
  }
}

function onClear(): void {
  internalSearchText.value = '';
}

watchSubscription.add(
  watch(
    () => [searchService?.searchText.value, searchService?.categoryId.value],
    () => init(),
  ),

  watch(
    () => route.params.categoryId,
    () => (internalSelectedCategory.value = EVERYWHERE_SEARCH_OPTION),
  ),
);

useSSRUnsubscribeWatch(watchSubscription);

onMounted(() => {
  init();
});
</script>

<style lang="scss" scoped>
// замена браузерного фона инпута при автозаполнении
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px $white inset !important;
}
.search__wrap_zindex {
  z-index: 2 !important;
}

.search__wrap {
  :deep(.vs__clear) {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .search__wrap {
    :deep(.select-wrapper) {
      margin: 0;
    }
  }
}
</style>

<template>
  <NuxtLayout>
    <template v-if="Number(error?.statusCode) === NOT_FOUND_ERROR_CODE">
      <ErrorWrapper
        title="К сожалению, <br> страница не найдена"
        message="Возможно, вы ввели неправильный адрес или страница была удалена. <br> Воспользуйтесь навигацией или перейдите на главную страницу."
        :redirect-button="{ text: 'На главную', path: '/' }"
      >
        <BaseImg
          class="not-found-img"
          src="/images/404.png"
        />
      </ErrorWrapper>
    </template>

    <template v-else>
      <ErrorWrapper
        title="К сожалению, <br> произошла ошибка"
        message="Наши специалисты уже занимаются решением проблемы. <br> Попробуйте обновить страницу или зайти позже."
      >
        <BaseImg
          class="server-error-img"
          src="/images/5xx.png"
        />
      </ErrorWrapper>
    </template>
  </NuxtLayout>
</template>

<script setup lang="ts">
import ErrorWrapper from 'shared/components/errorWrapper.vue';
import { NOT_FOUND_ERROR_CODE } from 'shared/constants/error.const';
import BaseImg from 'shared/components/BaseImg.vue';

const props = defineProps({
  error: Object,
});

console.error(props.error);
</script>

<style scoped lang="scss">
.not-found-img,
.server-error-img {
  width: 502px;
  height: auto;
}

@media screen and (max-width: 1280px) {
  .not-found-img {
    width: 400px;
    height: auto;
  }

  .server-error-img {
    width: 406px;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .not-found-img {
    width: 319px;
    height: auto;
  }

  .server-error-img {
    width: 324px;
    height: auto;
  }
}
</style>

<template>
  <div
    itemscope
    itemtype="https://schema.org/Product"
    class="mm-product-card"
    :class="{ mobile }"
    @click="onProductClick(id, $event)"
  >
    <div class="mm-product-card-inner">
      <div
        v-if="tipText && !mobile"
        class="mm-product-card__tip"
      >
        {{ tipText }}
      </div>
      <div
        class="mm-product-mobile-image"
        :class="{ 'mm-mt-20px': tipText && !mobile }"
      >
        <ImageView
          v-if="pictures"
          class="mm-product-card-image"
          :disabled-carousel="disabledCarousel"
          :pictures="pictures"
        />
      </div>

      <div
        class="mm-product-card-wrapper"
        :class="{ 'mm-mt-20px': tipText && !mobile }"
      >
        <CompareButton
          v-if="visibleCompare"
          class="mm-product-card-compare"
          :active="isComparison"
          :disabled="disabledCompare"
          @add="$emit('compare:add')"
          @open="$emit('compare:open')"
        />

        <ImageView
          v-if="pictures"
          class="mm-product-card-image"
          :disabled-carousel="disabledCarousel"
          :pictures="pictures"
        />

        <div class="mm-product-info">
          <div class="mm-product-info__meta">
            <div
              v-if="vendorCode"
              class="mm-caption-regular-m article"
            >
              {{ vendorCode ? `арт. ${vendorCode}` : '' }}
            </div>

            <div
              v-if="mtrCode"
              class="mm-caption-regular-m article"
            >
              Код: {{ mtrCode }}
            </div>
          </div>

          <p
            class="mm-headline-5"
            itemprop="name"
          >
            {{ name }}
          </p>
        </div>
      </div>

      <div class="mm-product-info-footer">
        <div class="mm-product-mobile-info">
          <span
            v-if="tipText"
            class="mm-product-card__tip mm-product-card__tip--mobile"
          >
            {{ tipText }}
          </span>
          <p class="mm-product-name">
            {{ name }}
          </p>

          <div class="mm-product-info__meta">
            <div
              v-if="vendorCode"
              class="mm-caption-regular-m article"
            >
              {{ vendorCode ? `арт. ${vendorCode}` : '' }}
            </div>

            <div
              v-if="mtrCode"
              class="mm-caption-regular-m article"
            >
              Код: {{ mtrCode }}
            </div>
          </div>

          <div class="mm-product-mobile-info-tags">
            <span
              v-for="(specification, key) in specifications"
              :key="key"
              class="mm-value"
            >
              <span>{{ specification.name }}: </span> {{ specification.value }}
            </span>
          </div>
        </div>
        <div />

        <div class="mm-product-info-wrapper">
          <div
            v-if="specifications.length"
            class="mm-product-info-tags"
          >
            <template
              v-for="(specification, key) in specifications"
              :key="key"
            >
              <span class="mm-breadcrumb">
                <BaseTooltip
                  arrow
                  hover
                  offset-distance="4px"
                >
                  {{ specification.value }}
                  <span class="mm-breadcrumb__delimeter" />
                  <template #content>
                    {{ specification.name }}
                  </template>
                </BaseTooltip>
              </span>
            </template>
          </div>

          <div class="mm-product-wrapper__inner">
            <template v-if="props.isCatalogAvailable">
              <div
                v-if="isShowPrice"
                class="mm-product-info-price"
              >
                <div
                  class="mm-product-info-price-info"
                  itemprop="offers"
                  itemscope
                  itemtype="https://schema.org/AggregateOffer"
                >
                  <p class="mm-product-price-number">
                    <template v-if="!showSingleGoodDetails">
                      от
                    </template>
                    <span
                      class="mm-headline-4 mm-font-500"
                      itemprop="lowPrice"
                    >
                      {{ StringsHelper.toPriceString(price) }}
                    </span>

                    <span
                      itemprop="priceCurrency"
                      content="RUB"
                    >₽</span>
                  </p>

                  <BaseTooltip
                    arrow
                    hover
                    offset-distance="4px"
                  >
                    <SvgIcon
                      class="mm-product-more-info"
                      src="navigation/info-outline"
                    />
                    <template #content>
                      Цена без НДС
                    </template>
                  </BaseTooltip>
                </div>

                <p
                  v-if="!showSingleGoodDetails"
                  class="mm-body-regular-xs"
                >
                  {{ t(ERuLocales.MarketplaceQuantity, marketplaceQuantity) }}
                </p>
              </div>

              <div
                v-else
                class="mm-product-info-offer"
              >
                {{ t(ERuLocales.MarketplaceQuantity, marketplaceQuantity) }} <br>
                от {{ marketplaceQuantity === 1 ? 'поставщика' : 'поставщиков' }}
              </div>

              <div class="mm-circle mm-product-link">
                <SvgIcon
                  :wrapper-classes="['mm-product-button']"
                  src="navigation/main-arrow"
                />
              </div>
            </template>

            <MButton
              v-else
              class="mm-product-btn"
              :theme="MM.EButtonThemes.Secondary"
              :size="MM.EButtonSizes.Medium"
              full-width
            >
              Подробнее
            </MButton>
          </div>
        </div>
      </div>

      <div
        v-if="props.isCatalogAvailable"
        class="mm-product-actions"
      >
        <CompareButton
          v-if="visibleCompare"
          class="mm-product-card-compare"
          :active="isComparison"
          :disabled="disabledCompare"
          @add="$emit('compare:add')"
          @open="$emit('compare:open')"
        />

        <button class="btn btn-round btn-primary">
          <SvgIcon src="navigation/main-arrow" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ERuLocales } from '../enums/ruLocales.enum';
import { IProductClickEvent, IProductSpecification } from '../models/product.model';
import { StringsHelper } from '../utils/strings.util';
import CompareButton from './CompareButton.vue';
import ImageView from 'shared/components/ImageView.vue';
import { MM } from 'types';

const props = withDefaults(
  defineProps<{
    // id продукта
    id: string;
    // Артикул продукта
    vendorCode?: string;
    // Код продукта
    mtrCode?: string;
    // Название продукта
    name: string;
    // Цена продукта
    price: number;
    // Количество продуктов в майркетплейсе
    marketplaceQuantity: number;
    // Массив картинок
    pictures: Array<string>;
    // Массив спецификаций
    specifications: Array<IProductSpecification>;
    // Мобильная версия
    mobile?: boolean;
    // Текст подсказки
    tipText?: string;
    // Нужно ли отображать цену
    isShowPrice?: boolean;
    visibleCompare?: boolean;
    isComparison?: boolean;
    disabledCompare?: boolean;
    disabledCarousel?: boolean;
    /** Доступен ли каталог для просмотра */
    isCatalogAvailable?: boolean;
    /** Показываем ли сведения о предложениях всего маркетплейса или только одного поставщика */
    showSingleGoodDetails?: boolean;
  }>(),
  {
    isShowPrice: true,
  },
);

const emit = defineEmits<{
  (e: 'product:click', value: IProductClickEvent): void;
  (e: 'compare:add'): void;
  (e: 'compare:open'): void;
}>();

const { t } = useI18n();

function onProductClick(id: string, event: MouseEvent): void {
  emit('product:click', { id, event });
}
</script>

<style lang="scss" scoped>
@import 'styles/base/common/_variables';

.mm-product-card {
  padding: 25px 20px 24px;
  background: $text-white;
  border-radius: 8px;
  transition: box-shadow 0.3s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  &:hover {
    box-shadow: 0 2px 18px $shadow;
  }

  .mm-product-card-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    .mm-product-info-footer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .mm-product-info-wrapper {
        margin-top: 12px;

        .mm-product-info-tags {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 12px;
          min-height: 64px;

          .mm-breadcrumb {
            text-decoration: none;
            color: $text-light-green;
            font-size: 14px;
            line-height: 20px;
            cursor: pointer;
            height: fit-content;
            overflow-wrap: anywhere;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 0;
            margin-bottom: 0;
            margin-right: 9px;

            :deep(.mm-tooltip > div) {
              word-break: break-word;
            }

            &__delimeter {
              height: 20px;
              width: 1px;
              display: inline-flex;
              background-color: $dark-gray;
              vertical-align: middle;
              margin-left: 8px;
            }

            &:last-child {
              margin-right: 0;

              .mm-breadcrumb__delimeter {
                display: none;
              }
            }
          }
        }

        .mm-product-wrapper__inner {
          display: flex;
          justify-content: space-between;

          .mm-product-info-price {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            p {
              margin-bottom: 0;
            }

            .mm-product-info-price-info {
              display: flex;
              align-items: center;

              &:only-child {
                margin-bottom: auto;
              }

              :deep(.popper) {
                max-width: 115px !important;
              }

              .mm-product-price-number {
                margin-right: 8px;
                font-size: 12px;
                line-height: 16px;
                color: $text-black;

                span {
                  line-height: 28px;
                  font-size: 20px;
                  font-weight: 500;

                  & + span {
                    margin-left: 5px;
                  }
                }
              }
            }

            .mm-body-regular-xs {
              line-height: 16px;
            }
          }

          .mm-product-link {
            width: 48px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $light-gray;

            &:hover {
              background: $main-green;
            }
          }

          .mm-product-btn {
            :deep(.m-button__text) {
              text-align: center;
            }
          }
        }
      }

      .mm-product-info__meta {
        display: flex;
        flex-direction: column;
        margin-top: 4px;
        gap: 4px;

        .article {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }

    .mm-product-card-wrapper {
      position: relative;

      .mm-product-card-compare {
        position: absolute;
        right: 0;
        top: 0;
      }

      .mm-product-img {
        max-width: 180px;
        max-height: 180px;

        :deep(.carousel) {
          .carousel__viewport,
          .carousel__track {
            height: 100%;
          }
        }

        :deep(img) {
          height: 100%;
          width: 100%;
        }
      }

      .mm-product-info {
        margin-top: 24px;

        &__meta {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-bottom: 8px;

          .mm-caption-regular-m {
            &.article {
              min-height: 21px;

              &:not(&:last-child) {
                margin-right: 8px;
                padding-right: 8px;
                border-right: 1px solid $dark-gray;
              }
            }
          }
        }

        .mm-headline-5 {
          line-height: 20px;
          font-weight: 400;
          margin: 0;
          word-break: break-word;
        }
      }
    }

    .mm-product-actions {
      display: none;
    }

    .mm-product-mobile-image {
      display: none;
    }
  }

  &.mobile {
    padding: 20px 16px;

    .mm-product-card-inner {
      display: flex;
      flex-direction: row;

      .mm-product-actions {
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
          margin-top: auto;
        }

        & > svg {
          margin-bottom: 18px;

          :deep(path) {
            fill: $text-disabled;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .mm-product-mobile-image {
        display: flex;
      }

      .mm-product-card-wrapper {
        display: none;
      }

      .mm-product-info-footer {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex: 1;
        margin-left: 12px;
        margin-right: 20px;

        .mm-product-mobile-info {
          display: flex;
          flex-direction: column;

          .mm-product-name {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $text-dark-green;
            margin-bottom: 0;
          }

          .mm-product-id {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $text-light-green;
            margin-top: 4px;
            margin-bottom: 20px;
          }

          .mm-product-mobile-info-tags {
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;
            margin-top: 20px;

            .mm-value {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: $text-dark-green;
              text-decoration: none;
              margin-bottom: 4px;

              &:last-child {
                margin-bottom: 0;
              }

              span {
                color: $text-light-green;
              }
            }
          }
        }

        .mm-product-link {
          display: none;
        }
      }

      .mm-product-mobile-image {
        width: 71px;

        & > div {
          margin: 0;
          width: 100%;
          height: 100%;

          :deep(img) {
            height: 71px;
            width: 71px;
            object-fit: contain;
          }
        }
      }
    }
  }

  .mm-product-actions {
    display: none;
  }

  .mm-product-mobile-info {
    display: none;
  }

  .mm-product-info-offer {
    font-size: 14px;
    line-height: 20px;
    color: $text-black;
  }

  &__tip {
    position: absolute;
    z-index: 10;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: $text-white;
    background: $link;
    padding: 3px 8px;
    border-radius: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--mobile {
      position: relative;
      width: max-content;
      margin-bottom: 6px;
    }
  }
}

@media only screen and (min-width: 340px) {
  .mm-product-card {
    .mm-product-card-inner {
      .mm-product-info {
        overflow-wrap: break-word;
      }

      .mm-product-info-footer {
        .mm-product-info-wrapper {
          height: 100%;
          display: flex;
          flex-direction: column;

          .mm-product-wrapper__inner {
            margin-top: auto;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1599px) {
  .mm-product-card {
    padding: 24px 14px;

    .mm-product-card-inner {
      width: 100%;

      .mm-product-info {
        overflow-wrap: break-word;
      }
    }
  }
}

@media only screen and (min-width: 1281px) {
  .mm-product-card {
    .mm-product-card-inner {
      .mm-product-card-wrapper {
        flex: 0 0 330px;
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .mm-product-card {
    padding: 16px;

    .mm-product-card-inner {
      width: 100%;

      .mm-product-info {
        overflow-wrap: break-word;
      }

      .mm-product-card-wrapper {
        .mm-product-img {
          max-width: 146px;
          max-height: 148px;
        }
      }
    }

    .mm-product-more-info {
      display: none;
    }

    .mm-product-interactive-buttons {
      display: flex;

      & > *:first-child {
        margin-bottom: 14px;
      }

      :deep(path) {
        fill: $text-disabled;
      }
    }

    &__tip {
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      padding: 2px 4px;
    }

    .mm-product-info-offer {
      font-size: 12px;
      line-height: 16px;
      color: $text-dark-green;
    }
  }

  .mm-product-card.mobile {
    .mm-product-info-footer {
      .mm-product-info-wrapper {
        .mm-product-info-tags {
          display: none !important;
        }

        .mm-product-wrapper__inner {
          .mm-product-link {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .mm-product-card {
    padding: 15px;

    .mm-product-card-inner {
      .mm-product-info {
        overflow-wrap: break-word;
      }

      .mm-product-card-wrapper {
        .mm-product-img {
          width: 146px;
          height: 148px;
        }

        .mm-product-info {
          height: 100% !important;

          .mm-headline-5 {
            margin-bottom: 16px;
          }

          .mm-caption-regular-m {
            margin-top: 4px;
          }

          &__meta {
            display: none;
          }
        }
      }

      .mm-product-info-footer {
        height: fit-content;

        .mm-product-info-wrapper {
          margin: 0;

          .mm-product-info-tags {
            .mm-breadcrumb {
              margin-right: 5px;
              font-size: 12px;
            }
          }
        }
      }
    }

    .mm-product-info-tags {
      display: none !important;
    }

    .mm-product-info-footer {
      .mm-product-info-wrapper {
        .mm-product-wrapper__inner {
          .mm-product-link {
            display: none !important;
          }
        }
      }

      :deep(.mm-img-buttons) {
        transform: translateY(0);
      }
    }

    &__tip {
      font-size: 9px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      padding: 2px 4px;
    }
  }
}

@media screen and (max-width: 360px) {
  .mm-product-card {
    .mm-product-card-inner {
      .mm-product-card-wrapper {
        .mm-product-img {
          max-width: 96px;
          max-height: 96px;
        }

        .mm-product-info {
          .article {
            display: none;
          }

          .mm-caption-regular-m {
            margin-top: 12px !important;
          }

          .mm-headline-5 {
            margin-bottom: 0;
            margin-top: 22px;
          }
        }
      }

      .mm-product-info-footer {
        .mm-product-info-wrapper {
          .mm-product-link {
            display: none;
          }

          .mm-product-info-tags {
            display: none;
          }

          .mm-product-info-price {
            .mm-body-regular-xs {
              color: $text-disabled;
            }
          }
        }
      }
    }
  }
}

:deep(.mm-tooltip) {
  .popper {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}
</style>
